const routeConfig = [
  {
    path: '/content-center',
    name: 'contentCenter',
    children: [
      {
        path: 'list',
        name: 'ContentCenterList',
        component: () => import('../views/content-center/list/index'),
      },
      {
        path: 'detail/:id',
        name: 'ContentCenterDetail',
        component: () => import('../views/content-center/detail/index'),
        props: true,
        meta: {
          needGio: true,
        },
      }
    ]
  },
]
export default routeConfig
