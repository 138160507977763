import { GetContactUsReq } from '@/models'
import { ContactUsQueryParams } from '@/views/contact_us/model'

/**
 * 留资参数
 */
export interface LeaveContactParams {
  /**
   * 留资的key，用于确认是否留资的唯一标识
   */
  key: QueryUserContactInfoParams
  /**
   * 重定向登录时的gio埋点标识
   */
  loginTypeForGio: string
  /**
   * 跳转的目标地址
   */
  targetUrl: string
  /**
   * 跳转的目标地址的参数
   */
  params: LeaveContactUrlParams
  /**
   * 是否需要认证，该信息会写入到缓存中的用户信息中
   */
  needAuth?: boolean
  /**
   * 方案id，会用于作为留资唯一标识
   */
  solutionId?: string
  /**
   * 描述信息，在留资页面需要用到
   */
  description?: string
}

/**
 * 跳转目标地址的参数
 */
export interface LeaveContactUrlParams extends ContactUsQueryParams {
  /**
   * sso的Appid方式，需要参考原有逻辑
   */
  app_id?: string
  /**
   * 是否展示账号信息
   */
  showAccount?: boolean
  /**
   * 扩展透传的参数
   */
  [key: string]: any
}

/**
 * 联系我们留资表单对象类型
 */
export const enum ContentObjectTypeEnum {
  /**
   * 生态伙伴
   */
  partner = 'partner',
  /**
   * 开发者
   */
  api = 'api',
  /**
   * 产品，Offering
   */
  product = 'product',
  /**
   * 主题与行业市场
   */
  topic = 'topic',
  /**
   * 学习中心
   */
  studyCenter = 'studyCenter',
  /**
   * 活动中心
   */
  eventCenter = 'eventCenter',
  /**
   * 关于Xcelerator
   */
  aboutXcelerator = 'aboutXcelerator',
  /**
   * 线上商城
   */
  store = 'store',
  /**
   * 购物车
   */
  cart = 'cart',
  /**
   * 订单结算
   */
  orderConfirm = 'orderConfirm',
  /**
   * 个人中心-订单详情
   */
  orderDetail = 'orderDetail'
}

/**
 * 留资类型
 */
export const enum ContactTypeEnum {
  /**
   * 产品demo留资
   */
  demo = 'demo',
  /**
   * 内容中心帖子留资
   */
  content = 'content',
  /**
   * banner留资
   */
  banner = 'banner',
}
/**
 * 留资查询参数
 */
export type QueryUserContactInfoParams = Omit<GetContactUsReq, 'contactType'> & { contactType: ContactTypeEnum }