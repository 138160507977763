import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable } from 'rxjs'

/**
 * 同步是否完成留资标识
 */
export class SyncCompleteFlagMiddleware extends Middleware<LeaveContactContext> {
  where(context: LeaveContactContext): boolean {
    return !!context.params.needAuth
  }
  handle(context: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    const userInfoStr = window.sessionStorage.getItem('userInfo')
    if (userInfoStr) {
      const userInfoObj = JSON.parse(userInfoStr)
      userInfoObj.completeFlag = !!context.userContactInfo?.uuid
      window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoObj))
    }
    return next()
  }
}