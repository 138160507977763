import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable, of } from 'rxjs'

/**
 * 去留资页面
 */
export class JumpToContactUsMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return !ctx.userContactInfo?.uuid
  }
  handle(ctx: LeaveContactContext, _next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    ctx.router.push({
      path: '/contact-us',
      query: {
        ...ctx.params.params,
        returnUrl: ctx.params.targetUrl,
      } as any,
    })
    return of(ctx)
  }
}
