
import { mergeAsyncFn } from '@/utils/decorator'
import request from '@/utils/request.js'


import type {
  BISOrderDeliveryVO,
  CallbackResponseConfirm,
  ChaXunDingDanTongJiDeQingQiuTi,
  ChaXunJiaoYiGuiZeRuCan,
  ChaXunZuHuXinXiRuCan,
  CheckDamexSpsVO,
  ComplianceInspectionQueryVO,
  ComplianceInspectionResultByIdVO,
  ComplianceInspectionResultVO,
  ConfirmInvoicingInfoResp,
  ConfirmInvoicingInfoVO,
  ConfirmInvoicingSelfOperationVO,
  ConfirmInvoicingThreePartyVo,
  DamexPBizParamVO,
  DamexPBizRespSimpleVO,
  DamexSpsResultDTO,
  DeleteTenantInfoSkuVO,
  DingDanHeGuiXingJianChaHuiTiaoCanShu,
  DingDanTiJiaoJianChaJieGuo,
  DingDanZuHeTiJiaoJieGuo,
  DingYueXinXiDuiXiang,
  DingYueXinXiXuDingZhunBeiDuiXiang,
  EntUserInfoVO,
  EnterpriseAuthenticationApplication,
  EnterpriseAuthenticationApplicationSearchVO,
  EnterpriseAuthenticationApplicationUpdateVO,
  EnterpriseAuthenticationApplicationVO,
  EnterpriseAuthenticationCallbackVO,
  EnterpriseAuthenticationUserApplicationVO,
  EnterpriseGetByUserRequestDTO,
  EnterpriseGetByUserResponseDTO,
  EnterpriseSearchVO,
  EnterpriseThirdPartyWhiteListVO,
  EnterpriseVO,
  GetBasicFullDetailResp,
  GetInvoiceResultResp,
  GetInvoiceResultVO,
  IEHUBZuHuXinXi,
  InvoiceBillDto,
  InvoiceBillingListQueryDto,
  InvoiceBillingVo,
  InvoicePageResultVo,
  JiaoYiGuiZeDuiXiang,
  NXPowerMatchResultVO,
  NXPowerMatchVO,
  NetPayOrderUrlVO,
  OfferingOfProductIdSearchRequest,
  Page,
  PageResult,
  Product,
  ProductSKUStatusVO,
  QiChachaResponse,
  QueryConditions,
  ReUploadInvoiceThreePartyVo,
  Result,
  ShangPinXinXi,
  SortTenantInfoSkuVO,
  TenantInfoOnProductDetailShowVO,
  TenantInfoProductSkuSearchRequestVO,
  TenantInfoProductSkuVO,
  TenantInfoVO,
  TenantInfoVoSearchRequestVO,
  TenantVO,
  TianWeiGetBankListResponse,
  TransactionBisFeeBatchReqVO,
  TransactionBisFeeReqVO,
  TransactionBisFeeRespVO,
  TransactionBisProductReqVO,
  TransactionBisProductRespVO,
  TransactionBisSapArtificialSoReqVO,
  TransactionBisSapRePushReqVO,
  TransactionBisSapUpdateReqVO,
  TransactionBisSettlementQueryReqVO,
  TransactionBisSettlementReqVO,
  TransactionBisSettlementRespVO,
  TransactionDictVO,
  TransactionOrderAttachmentCommitVO,
  TransactionOrderAttachmentVO,
  TransactionOrderBlackList,
  TransactionOrderBlackListAddByOrderNoRequestDTO,
  TransactionOrderBlackListAddByOrderNoResponseDTO,
  TransactionOrderCancelVO,
  TransactionOrderCartComponentSkusVO,
  TransactionOrderCartVO,
  TransactionOrderCheckListModifyVO,
  TransactionOrderCombineCommitVO,
  TransactionOrderComplianceCheckVO,
  TransactionOrderCountVO,
  TransactionOrderCustomerInfo,
  TransactionOrderCustomerInfoVO,
  TransactionOrderDetailStatisticsDTO,
  TransactionOrderInvoiceVO,
  TransactionOrderItemCartVO,
  TransactionOrderLifeCycleVO,
  TransactionOrderOfflinePaySingleVO,
  TransactionOrderOfflinePayVO,
  TransactionOrderOnlinePaySingleVO,
  TransactionOrderOnlinePayVO,
  TransactionOrderOperationLogVO,
  TransactionOrderPaidAmountStatisticsDTO,
  TransactionOrderPreInfoVO,
  TransactionOrderPrePayVO,
  TransactionOrderProcessingTimeStatisticsDTO,
  TransactionOrderShippedVO,
  TransactionOrderSkuPaidAmountStatisticsDTO,
  TransactionOrderStageStatisticsDTO,
  TransactionOrderStatisticsDTO,
  TransactionOrderStatusStatisticsDTO,
  TransactionOrderStatusVO,
  TransactionOrderUnconfirmedVO,
  TransactionOrderUserCancelVO,
  TransactionOrderVO,
  TransactionRuleConfigTenantVO,
  TransactionRuleKeyVO,
  TransactionSellerOrderExportDataVO,
  TransactionShippingAddressVO,
  TransactionSkuCategoryDefineDictAddOrUpdateReqVO,
  TransactionSkuCategoryDefineDictDeleteVO,
  TransactionSkuCategoryDefineDictDetailVO,
  TransactionSkuCategoryDefineDictEnumSearchReqVO,
  TransactionSkuCategoryDefineDictPageReqVO,
  TransactionSkuCategoryDefineDictPageResVO,
  TransactionSkuCategoryDefineDictPageVO,
  TransactionSkuCategoryDefineDictSearchVO,
  TransactionSkuCategoryDefineDictUpdateStatusVO,
  TransactionSkuCategoryDefineDictV2,
  TransactionSkuCategoryDefineDictVO,
  TransactionSkuVO,
  TransactionSubscriptionEffectiveDateVO,
  TransactionSubscriptionMainVO,
  TransactionTenantInfoCreateRequestVO,
  TransactionTenantInfoSearchVO,
  TransactionTenantInfoVO,
  TransactionTenantVO,
  UpdateBillingNoVo,
  UserEntInfoVO,
  UserProfileResultVO,
  XiuGaiDingDanDanJiaShuLiang,
  XiuGaiJiaoYiGuiZeRuCanDuiXiang
} from '@/models/product'


/**
 * saveAuthenticationUserApplication
 *
 */
export const adminEnterpriseAuthAddUserV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationUserApplicationVO) => {
  return request.post<Result<EnterpriseAuthenticationApplication>>('/transaction/transaction-mall-service/admin/enterpriseAuth/add/user/v1', data) as unknown as Promise<Result<EnterpriseAuthenticationApplication>>
})


/**
 * approveInternalApplicationCallback
 *
 */
export const adminEnterpriseAuthApproveCallbackV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationCallbackVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseAuth/approve/callback/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * cancelApplication
 *
 */
export const adminEnterpriseAuthCancelV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseAuth/cancel/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * getApplicationDetail
 *
 */
export const adminEnterpriseAuthDetailV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseAuthenticationApplicationVO>>('/transaction/transaction-mall-service/admin/enterpriseAuth/detail/v1', data) as unknown as Promise<Result<EnterpriseAuthenticationApplicationVO>>
})


/**
 * updateAuthenticationApplicationById
 *
 */
export const adminEnterpriseAuthEditUserV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationUpdateVO) => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/admin/enterpriseAuth/edit/user/v1', data) as unknown as Promise<Result<Void>>
})


/**
 * exportApplication
 *
 */
export const adminEnterpriseAuthExportV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/admin/enterpriseAuth/export/v1', data) as unknown as Promise<void>
})


/**
 * approveInternalApplication
 *
 */
export const adminEnterpriseAuthInternalApproveV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseAuth/internal/approve/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * rejectInternalApplication
 *
 */
export const adminEnterpriseAuthInternalRejectV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseAuth/internal/reject/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * listApplication
 *
 */
export const adminEnterpriseAuthListV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<EnterpriseAuthenticationApplicationVO>>('/transaction/transaction-mall-service/admin/enterpriseAuth/list/v1', data) as unknown as Promise<PageResult<EnterpriseAuthenticationApplicationVO>>
})


/**
 * getApplicationDetail
 *
 */
export const adminEnterpriseAuthNoAuthDetailV1Post = mergeAsyncFn()((params: {

  /**
   * applicationId
   */
  applicationId: string

}) => {
  return request.post<Result<EnterpriseAuthenticationApplicationVO>>('/transaction/transaction-mall-service/admin/enterpriseAuth/noAuth/detail/v1',) as unknown as Promise<Result<EnterpriseAuthenticationApplicationVO>>
})


/**
 * submitApplication
 *
 */
export const adminEnterpriseAuthSubmitV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseAuth/submit/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * listEnterprise
 *
 */
export const adminEnterpriseListV1Post = mergeAsyncFn()((data?: EnterpriseSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<EnterpriseVO>>('/transaction/transaction-mall-service/admin/enterprise/list/v1', data) as unknown as Promise<PageResult<EnterpriseVO>>
})


/**
 * addUserToEnterprise
 *
 */
export const adminEnterpriseUserAddUserV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseUser/addUser/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * queryComplianceInspectionListById
 *
 */
export const adminEnterpriseUserComplianceListByIdV1Post = mergeAsyncFn()((params: {

  /**
   * unifiedCreditCode
   */
  unifiedCreditCode: string

}) => {
  return request.post<Result<ComplianceInspectionResultByIdVO>>('/transaction/transaction-mall-service/admin/enterpriseUser/compliance/listById/v1',) as unknown as Promise<Result<ComplianceInspectionResultByIdVO>>
})


/**
 * queryComplianceInspectionList
 *
 */
export const adminEnterpriseUserComplianceListV1Post = mergeAsyncFn()((data?: ComplianceInspectionQueryVO) => {
  return request.post<Result<ComplianceInspectionResultVO[]>>('/transaction/transaction-mall-service/admin/enterpriseUser/compliance/list/v1', data) as unknown as Promise<Result<ComplianceInspectionResultVO[]>>
})


/**
 * getUserProfileById
 *
 */
export const adminEnterpriseUserGetUserDetailV1Post = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}) => {
  return request.post<Result<UserProfileResultVO>>('/transaction/transaction-mall-service/admin/enterpriseUser/get/user/detail/v1',) as unknown as Promise<Result<UserProfileResultVO>>
})


/**
 * listUser
 *
 */
export const adminEnterpriseUserListV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<EntUserInfoVO>>('/transaction/transaction-mall-service/admin/enterpriseUser/list/v1', data) as unknown as Promise<PageResult<EntUserInfoVO>>
})


/**
 * removeUserFromEnterprise
 *
 */
export const adminEnterpriseUserRemoveUserV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/enterpriseUser/removeUser/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * delete
 *
 */
export const adminProductSkuCategoryDefineDictFiledDeletePost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictDeleteVO) => {
  return request.post<Result<any>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/delete', data) as unknown as Promise<Result<any>>
})


/**
 * detail
 *
 */
export const adminProductSkuCategoryDefineDictFiledDetailGet = mergeAsyncFn()((params: {

  /**
   * code
   */
  code: string

}) => {
  return request.get<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/detail', { params }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * detailV2
 *
 */
export const adminProductSkuCategoryDefineDictFiledDetailV2Get = mergeAsyncFn()((params: {

  /**
   * code
   */
  code: string

}) => {
  return request.get<Result<TransactionSkuCategoryDefineDictDetailVO>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/detail/v2', { params }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictDetailVO>>
})


/**
 * page
 *
 */
export const adminProductSkuCategoryDefineDictFiledPagePost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.post<Result<TransactionSkuCategoryDefineDictPageVO>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/page', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictPageVO>>
})


/**
 * pageV2
 *
 */
export const adminProductSkuCategoryDefineDictFiledPageV2Post = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictPageReqVO) => {
  return request.post<Result<TransactionSkuCategoryDefineDictPageResVO>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/page/v2', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictPageResVO>>
})


/**
 * addOrUpdate
 *
 */
export const adminProductSkuCategoryDefineDictFiledPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.post<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * updateStatus
 *
 */
export const adminProductSkuCategoryDefineDictFiledStatusPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictUpdateStatusVO) => {
  return request.post<Result<any>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/status', data) as unknown as Promise<Result<any>>
})


/**
 * addOrUpdateV2
 *
 */
export const adminProductSkuCategoryDefineDictFiledV2Post = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictAddOrUpdateReqVO) => {
  return request.post<Result<any>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/dict/filed/v2', data) as unknown as Promise<Result<any>>
})


/**
 * searchEnumCode
 *
 */
export const adminProductSkuCategoryDefineSearchPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictEnumSearchReqVO) => {
  return request.post<Page<TransactionSkuCategoryDefineDictV2>>('/transaction/transaction-mall-service/admin/product/skuCategory/define/search', data) as unknown as Promise<Page<TransactionSkuCategoryDefineDictV2>>
})


/**
 * chooseList
 *
 */
export const adminProductSpecDefineChooseListGet = mergeAsyncFn()((params: {

  /**
   * productId
   */
  productId: string

}) => {
  return request.get<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/choose/list', { params }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertDelete = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.delete<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', { data }) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertGet = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.get<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', { data }) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertHead = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.head<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', data) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertOptions = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.options<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', data) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertPatch = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.patch<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', data) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', data) as unknown as Promise<Result<boolean>>
})


/**
 * insertSpec
 *
 */
export const adminProductSpecDefineInsertPut = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictVO) => {
  return request.put<Result<boolean>>('/transaction/transaction-mall-service/admin/product/spec/define/insert', data) as unknown as Promise<Result<boolean>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchDelete = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.delete<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', { data }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchGet = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.get<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', { data }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchHead = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.head<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchOptions = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.options<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchPatch = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.patch<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.post<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecList
 *
 */
export const adminProductSpecDefineSearchPut = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.put<Result<TransactionSkuCategoryDefineDictVO[]>>('/transaction/transaction-mall-service/admin/product/spec/define/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO[]>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchDelete = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.delete<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', { data }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchGet = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.get<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', { data }) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchHead = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.head<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchOptions = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.options<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchPatch = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.patch<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchPost = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.post<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * searchSpecDetail
 *
 */
export const adminProductSpecDetailSearchPut = mergeAsyncFn()((data?: TransactionSkuCategoryDefineDictSearchVO) => {
  return request.put<Result<TransactionSkuCategoryDefineDictVO>>('/transaction/transaction-mall-service/admin/product/spec/detail/search', data) as unknown as Promise<Result<TransactionSkuCategoryDefineDictVO>>
})


/**
 * 私有文件-授权访问
 *
 */
export const baseAdminFileGeneratePrivateDownloadUrlGet = mergeAsyncFn()((params: {

  /**
   * key
   */
  key: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<Result<string>>('/transaction/transaction-mall-service/base/admin/file/generate/private/download/url', { params, headers }) as unknown as Promise<Result<string>>
})


/**
 * 私有文件-服务端签名直传
 *
 */
export const baseAdminFileGeneratePrivatePostPolicyGet = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<any>('/transaction/transaction-mall-service/base/admin/file/generate/private/post/policy', { headers }) as unknown as Promise<any>
})


/**
 * 私有文件-授权访问
 *
 */
export const baseAdminFilePrivateDownloadGet = mergeAsyncFn()((params: {

  /**
   * key
   */
  key: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<string>('/transaction/transaction-mall-service/base/admin/file/private/download', { params, headers }) as unknown as Promise<string>
})


/**
 * 私有文件-服务端签名直传
 *
 */
export const baseFileGeneratePrivatePostPolicyGet = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<any>('/transaction/transaction-mall-service/base/file/generate/private/post/policy', { headers }) as unknown as Promise<any>
})


/**
 * 公有文件-服务端签名直传
 *
 */
export const baseFileGeneratePublicPostPolicyGet = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<any>('/transaction/transaction-mall-service/base/file/generate/public/post/policy', { headers }) as unknown as Promise<any>
})


/**
 * 买家/卖家/平台查看开票详情
 *
 */
export const buyerQueryInvoiceBillDetailPost = mergeAsyncFn()((params: {

  /**
   * 开票单类型: SALES_ORDER-订单类型 SETTLE_ORDER-结算单类型
   */
  type: string


  /**
   * 订单id
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<InvoiceBillingVo>>('/transaction/transaction-mall-service/buyer/query/invoice/bill/detail',) as unknown as Promise<Result<InvoiceBillingVo>>
})


/**
 * 买家根据订单id查询是否申请开过票/自营订单是否可以查看开票信息
 *
 */
export const buyerQueryOrderInvoiceBillStatusPost = mergeAsyncFn()((params: {

  /**
   * 订单id
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<number>>('/transaction/transaction-mall-service/buyer/query/order/invoice/bill/status',) as unknown as Promise<Result<number>>
})


/**
 * error
 *
 */
export const errorDelete = mergeAsyncFn()(() => {
  return request.delete<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorGet = mergeAsyncFn()(() => {
  return request.get<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorHead = mergeAsyncFn()(() => {
  return request.head<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorOptions = mergeAsyncFn()(() => {
  return request.options<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorPatch = mergeAsyncFn()(() => {
  return request.patch<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorPost = mergeAsyncFn()(() => {
  return request.post<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * error
 *
 */
export const errorPut = mergeAsyncFn()(() => {
  return request.put<any>('/transaction/transaction-mall-service/error',) as unknown as Promise<any>
})


/**
 * 获取私有文件
 *
 */
export const filePrivateGetGet = mergeAsyncFn()((params: {

  /**
   * 文件key
   */
  ossKey: string

}) => {
  return request.get<string>('/transaction/transaction-mall-service/file/private/get', { params }) as unknown as Promise<string>
})


/**
 * getByUser
 *
 */
export const internalEnterpriseGetByUserPost = mergeAsyncFn()((data?: EnterpriseGetByUserRequestDTO, headers?: {

  /**
   * x-transaction-enterprise-app-key
   */
  ['x-transaction-enterprise-app-key']: string

}) => {
  return request.post<Result<EnterpriseGetByUserResponseDTO>>('/transaction/transaction-mall-service/internal/enterprise/get/by/user', data) as unknown as Promise<Result<EnterpriseGetByUserResponseDTO>>
})


/**
 * 获取内部租户的编码
 *
 */
export const internalGetInternalTenantV1Get = mergeAsyncFn()(() => {
  return request.get<Result<TransactionTenantInfoVO[]>>('/transaction/transaction-mall-service/internal/getInternalTenant/v1',) as unknown as Promise<Result<TransactionTenantInfoVO[]>>
})


/**
 * 获取外部租户的编码
 *
 */
export const internalGetOutTenantV1Get = mergeAsyncFn()(() => {
  return request.get<Result<TransactionTenantInfoVO[]>>('/transaction/transaction-mall-service/internal/getOutTenant/v1',) as unknown as Promise<Result<TransactionTenantInfoVO[]>>
})


/**
 * 用订单号批量插入黑名单
 *
 */
export const internalOrderBlackListAddByOrderNoPost = mergeAsyncFn()((data?: TransactionOrderBlackListAddByOrderNoRequestDTO, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderBlackListAddByOrderNoResponseDTO>>('/transaction/transaction-mall-service/internal/order/black/list/addByOrderNo', data) as unknown as Promise<Result<TransactionOrderBlackListAddByOrderNoResponseDTO>>
})


/**
 * 新增黑名单
 *
 */
export const internalOrderBlackListAddPost = mergeAsyncFn()((data?: TransactionOrderBlackList, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<number>>('/transaction/transaction-mall-service/internal/order/black/list/add', data) as unknown as Promise<Result<number>>
})


/**
 * 删除黑名单
 *
 */
export const internalOrderBlackListDeletePost = mergeAsyncFn()((data?: TransactionOrderBlackList, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<number>>('/transaction/transaction-mall-service/internal/order/black/list/delete', data) as unknown as Promise<Result<number>>
})


/**
 * 查询黑名单
 *
 */
export const internalOrderBlackListListGet = mergeAsyncFn()((params?: {

  /**
   * orderId
   */
  orderId?: number


  /**
   * orderNo
   */
  orderNo?: string


  /**
   * page
   */
  page?: number


  /**
   * pageSize
   */
  pageSize?: number

}, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.get<Result<TransactionOrderBlackList[]>>('/transaction/transaction-mall-service/internal/order/black/list/list', { params, headers }) as unknown as Promise<Result<TransactionOrderBlackList[]>>
})


/**
 * 查询订单详情列表
 *
 */
export const internalOrderDetailListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderDetailStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/detail/list', data) as unknown as Promise<Result<TransactionOrderDetailStatisticsDTO[]>>
})


/**
 * 查询订单清单
 *
 */
export const internalOrderListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/list', data) as unknown as Promise<Result<TransactionOrderStatisticsDTO[]>>
})


/**
 * 查询已支付的金额
 *
 */
export const internalOrderPaidAmountListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderPaidAmountStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/paid/amount/list', data) as unknown as Promise<Result<TransactionOrderPaidAmountStatisticsDTO[]>>
})


/**
 * 查询订单的状态变化时间点
 *
 */
export const internalOrderProcessingTimeListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderProcessingTimeStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/processing/time/list', data) as unknown as Promise<Result<TransactionOrderProcessingTimeStatisticsDTO[]>>
})


/**
 * 查询sku的已支付金额
 *
 */
export const internalOrderSkuPaidListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderSkuPaidAmountStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/sku/paid/list', data) as unknown as Promise<Result<TransactionOrderSkuPaidAmountStatisticsDTO[]>>
})


/**
 * 查询多阶段付款的订单当前阶段分布
 *
 */
export const internalOrderStageListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderStageStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/stage/list', data) as unknown as Promise<Result<TransactionOrderStageStatisticsDTO[]>>
})


/**
 * 查询多阶段付款的订单当前阶段分布
 *
 */
export const internalOrderStatusListPost = mergeAsyncFn()((data?: ChaXunDingDanTongJiDeQingQiuTi, headers?: {

  /**
   * x-transaction-statistics-app-key
   */
  ['x-transaction-statistics-app-key']: string

}) => {
  return request.post<Result<TransactionOrderStatusStatisticsDTO[]>>('/transaction/transaction-mall-service/internal/order/status/list', data) as unknown as Promise<Result<TransactionOrderStatusStatisticsDTO[]>>
})


/**
 * 商品信息获取
 *
 */
export const internalProductGetGet = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: string

}) => {
  return request.get<Result<TenantInfoProductSkuVO>>('/transaction/transaction-mall-service/internal/product/get', { params }) as unknown as Promise<Result<TenantInfoProductSkuVO>>
})


/**
 * 商品新增
 *
 */
export const internalProductSkuCreatePost = mergeAsyncFn()((data?: TenantInfoProductSkuVO) => {
  return request.post<Result<TenantInfoProductSkuVO>>('/transaction/transaction-mall-service/internal/product/sku/create', data) as unknown as Promise<Result<TenantInfoProductSkuVO>>
})


/**
 * 查询当前商品对应的SKU
 *
 */
export const internalProductSkuListByProductIdGet = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: string

}) => {
  return request.get<Result<TenantInfoProductSkuVO>>('/transaction/transaction-mall-service/internal/product/sku/list/by/productId', { params }) as unknown as Promise<Result<TenantInfoProductSkuVO>>
})


/**
 * 查询当前sku是否有未完成订单
 *
 */
export const internalProductSkuListBySkuUnfinishedOrderGet = mergeAsyncFn()((params: {

  /**
   * skuId
   */
  skuId: number

}) => {
  return request.get<Result<string[]>>('/transaction/transaction-mall-service/internal/product/sku/listBySku/unfinished/order', { params }) as unknown as Promise<Result<string[]>>
})


/**
 * 商品列表获取
 *
 */
export const internalProductSkuListPost = mergeAsyncFn()((data?: TenantInfoProductSkuSearchRequestVO) => {
  return request.post<Result<PageResult<TenantInfoProductSkuVO>>>('/transaction/transaction-mall-service/internal/product/sku/list', data) as unknown as Promise<Result<PageResult<TenantInfoProductSkuVO>>>
})


/**
 * 查询当前商品是否有未完成订单
 *
 */
export const internalProductSkuListUnfinishedOrderGet = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: string

}) => {
  return request.get<Result<string[]>>('/transaction/transaction-mall-service/internal/product/sku/list/unfinished/order', { params }) as unknown as Promise<Result<string[]>>
})


/**
 * 商品编辑
 *
 */
export const internalProductSkuModifyPost = mergeAsyncFn()((data?: TenantInfoProductSkuVO) => {
  return request.post<Result<TenantInfoProductSkuVO>>('/transaction/transaction-mall-service/internal/product/sku/modify', data) as unknown as Promise<Result<TenantInfoProductSkuVO>>
})


/**
 * 删除指定商品的SKU
 *
 */
export const internalProductSkuRemovePost = mergeAsyncFn()((data?: DeleteTenantInfoSkuVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/internal/product/sku/remove', data) as unknown as Promise<Result<boolean>>
})


/**
 * sku排序
 *
 */
export const internalProductSkuSortSkuPost = mergeAsyncFn()((data?: SortTenantInfoSkuVO) => {
  return request.post<Result<TenantInfoProductSkuVO>>('/transaction/transaction-mall-service/internal/product/sku/sortSku', data) as unknown as Promise<Result<TenantInfoProductSkuVO>>
})


/**
 * 商品对应单个SKU上下架
 *
 */
export const internalProductSkuStatusModifyPost = mergeAsyncFn()((data?: ProductSKUStatusVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/internal/product/sku/status/modify', data) as unknown as Promise<Result<boolean>>
})


/**
 * 查询ProductSKU相关信息
 *
 */
export const internalProductStatusQueryPost = mergeAsyncFn()((data?: OfferingOfProductIdSearchRequest) => {
  return request.post<Result<Product[]>>('/transaction/transaction-mall-service/internal/product/status/query', data) as unknown as Promise<Result<Product[]>>
})


/**
 * 获取sku集合
 *
 */
export const internalSkuListPost = mergeAsyncFn()((data?: TenantInfoProductSkuSearchRequestVO) => {
  return request.post<Result<TransactionSkuVO[]>>('/transaction/transaction-mall-service/internal/sku/list', data) as unknown as Promise<Result<TransactionSkuVO[]>>
})


/**
 * 商户新增
 *
 */
export const internalTenantCreatePost = mergeAsyncFn()((data?: TransactionTenantInfoCreateRequestVO) => {
  return request.post<Result<TenantInfoVO>>('/transaction/transaction-mall-service/internal/tenant/create', data) as unknown as Promise<Result<TenantInfoVO>>
})


/**
 * 商户查询
 *
 */
export const internalTenantGetGet = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: number

}) => {
  return request.get<Result<TenantInfoVO>>('/transaction/transaction-mall-service/internal/tenant/get', { params }) as unknown as Promise<Result<TenantInfoVO>>
})


/**
 * syncTenantInfoHistory
 *
 */
export const internalTenantHistoryAllGet = mergeAsyncFn()(() => {
  return request.get<Result<boolean>>('/transaction/transaction-mall-service/internal/tenant/history/all',) as unknown as Promise<Result<boolean>>
})


/**
 * syncTenantContactInfo
 *
 */
export const internalTenantHistoryContactPost = mergeAsyncFn()((data?: TransactionTenantInfoCreateRequestVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/internal/tenant/history/contact', data) as unknown as Promise<Result<boolean>>
})


/**
 * 商户列表查询
 *
 */
export const internalTenantListPost = mergeAsyncFn()((data?: TenantInfoVoSearchRequestVO) => {
  return request.post<Result<PageResult<TenantInfoVO>>>('/transaction/transaction-mall-service/internal/tenant/list', data) as unknown as Promise<Result<PageResult<TenantInfoVO>>>
})


/**
 * 商户修改
 *
 */
export const internalTenantModifyPost = mergeAsyncFn()((data?: TransactionTenantInfoCreateRequestVO) => {
  return request.post<Result<TenantInfoVO>>('/transaction/transaction-mall-service/internal/tenant/modify', data) as unknown as Promise<Result<TenantInfoVO>>
})


/**
 * 商户同步更新
 *
 */
export const internalTenantSyncPost = mergeAsyncFn()((data?: TransactionTenantVO) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/internal/tenant/sync', data) as unknown as Promise<Result<string>>
})


/**
 * 三方卖家订单买家申请开票
 *
 */
export const invoiceBillingAppliyPost = mergeAsyncFn()((data?: TransactionOrderCustomerInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoice/billing/appliy', data) as unknown as Promise<Result<boolean>>
})


/**
 * 三方查看开票申请预填信息
 *
 */
export const invoiceBillingQueryPreInfoPost = mergeAsyncFn()((params: {

  /**
   * 订单id
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionOrderCustomerInfo>>('/transaction/transaction-mall-service/invoice/billing/query/pre/info',) as unknown as Promise<Result<TransactionOrderCustomerInfo>>
})


/**
 * 开票单保存
 *
 */
export const invoiceBillingSavePost = mergeAsyncFn()((data?: InvoiceBillDto) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoice/billing/save', data) as unknown as Promise<Result<boolean>>
})


/**
 * 三方卖家订单卖家申请开票
 *
 */
export const invoiceBillingSellerApplyPost = mergeAsyncFn()((params: {

  /**
   * 订单id
   */
  orderId: string


  /**
   * userName
   */
  userName: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoice/billing/seller/apply',) as unknown as Promise<Result<boolean>>
})


/**
 * 自营/三方-确认开票页面信息
 *
 */
export const invoiceManagementConfirmInvoicingInfoPost = mergeAsyncFn()((data?: ConfirmInvoicingInfoVO) => {
  return request.post<Result<ConfirmInvoicingInfoResp>>('/transaction/transaction-mall-service/invoiceManagement/confirmInvoicingInfo', data) as unknown as Promise<Result<ConfirmInvoicingInfoResp>>
})


/**
 * 确认开票自营
 *
 */
export const invoiceManagementConfirmInvoicingSelfOperationPost = mergeAsyncFn()((data?: ConfirmInvoicingSelfOperationVO, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoiceManagement/confirmInvoicingSelfOperation', data) as unknown as Promise<Result<boolean>>
})


/**
 * 三方-确认开票
 *
 */
export const invoiceManagementConfirmInvoicingThreePartyPost = mergeAsyncFn()((data?: ConfirmInvoicingThreePartyVo) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoiceManagement/confirmInvoicingThreeParty', data) as unknown as Promise<Result<boolean>>
})


/**
 * 下载发票
 *
 */
export const invoiceManagementGetInvoiceResultPost = mergeAsyncFn()((data?: GetInvoiceResultVO) => {
  return request.post<GetInvoiceResultResp>('/transaction/transaction-mall-service/invoiceManagement/getInvoiceResult', data) as unknown as Promise<GetInvoiceResultResp>
})


/**
 * 三方-重新上传发票
 *
 */
export const invoiceManagementReUploadInvoiceThreePartyPost = mergeAsyncFn()((data?: ReUploadInvoiceThreePartyVo) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoiceManagement/reUploadInvoiceThreeParty', data) as unknown as Promise<Result<boolean>>
})


/**
 * 自营-修改billing号
 *
 */
export const invoiceManagementUpdateBillingNoPost = mergeAsyncFn()((data?: UpdateBillingNoVo) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/invoiceManagement/updateBillingNo', data) as unknown as Promise<Result<boolean>>
})


/**
 * checkVerifyCodeMessage
 *
 */
export const memberEnterpriseAuthCheckVerifyCodeMessageV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Record<string, any>>>('/transaction/transaction-mall-service/member/enterpriseAuth/checkVerifyCodeMessage/v1', data) as unknown as Promise<Result<Record<string, any>>>
})


/**
 * checkVerifyPayment
 *
 */
export const memberEnterpriseAuthCheckVerifyPaymentV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Record<string, any>>>('/transaction/transaction-mall-service/member/enterpriseAuth/checkVerifyPayment/v1', data) as unknown as Promise<Result<Record<string, any>>>
})


/**
 * sendFullVerifyCode
 *
 */
export const memberEnterpriseAuthFullSendVerifyCodeV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterpriseAuth/full/sendVerifyCode/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * getBankList
 *
 */
export const memberEnterpriseAuthGetBankListV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TianWeiGetBankListResponse>>('/transaction/transaction-mall-service/member/enterpriseAuth/getBankList/v1', data) as unknown as Promise<Result<TianWeiGetBankListResponse>>
})


/**
 * getUserPendingApplication
 *
 */
export const memberEnterpriseAuthGetPendingApplicationV1Post = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseAuthenticationApplicationVO>>('/transaction/transaction-mall-service/member/enterpriseAuth/getPendingApplication/v1',) as unknown as Promise<Result<EnterpriseAuthenticationApplicationVO>>
})


/**
 * getTianWeiWhiteList
 *
 */
export const memberEnterpriseAuthGetTianWeiWhiteListV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseThirdPartyWhiteListVO[]>>('/transaction/transaction-mall-service/member/enterpriseAuth/getTianWeiWhiteList/v1', data) as unknown as Promise<Result<EnterpriseThirdPartyWhiteListVO[]>>
})


/**
 * getUserPendingApplicationByUser
 *
 */
export const memberEnterpriseAuthGetUserPendingApplicationV1Get = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}, headers?: {

  /**
   * system language
   */
  language?: string

}) => {
  return request.get<Result<EnterpriseAuthenticationApplicationVO>>('/transaction/transaction-mall-service/member/enterpriseAuth/getUserPendingApplication/v1', { params, headers }) as unknown as Promise<Result<EnterpriseAuthenticationApplicationVO>>
})


/**
 * sendVerifyCodeMessage
 *
 */
export const memberEnterpriseAuthSendVerifyCodeMessageV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Record<string, any>>>('/transaction/transaction-mall-service/member/enterpriseAuth/sendVerifyCodeMessage/v1', data) as unknown as Promise<Result<Record<string, any>>>
})


/**
 * sendVerifyCode
 *
 */
export const memberEnterpriseAuthSendVerifyCodeV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterpriseAuth/sendVerifyCode/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * submitApplication
 *
 */
export const memberEnterpriseAuthSubmitV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationApplicationVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterpriseAuth/submit/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * isEnterpriseExistByUnifiedCreditCode
 *
 */
export const memberEnterpriseExistByUnifiedCreditCodeV1Post = mergeAsyncFn()((data?: string, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterprise/existByUnifiedCreditCode/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * listEnterprise
 *
 */
export const memberEnterpriseListV1Post = mergeAsyncFn()((data?: EnterpriseSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<EnterpriseVO>>('/transaction/transaction-mall-service/member/enterprise/list/v1', data) as unknown as Promise<PageResult<EnterpriseVO>>
})


/**
 * queryEntByName
 *
 */
export const memberEnterpriseQueryEntByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseVO>>('/transaction/transaction-mall-service/member/enterprise/queryEntByName/v1', data) as unknown as Promise<Result<EnterpriseVO>>
})


/**
 * queryEntListByName
 *
 */
export const memberEnterpriseQueryEntListByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<QiChachaResponse>>('/transaction/transaction-mall-service/member/enterprise/queryEntListByName/v1', data) as unknown as Promise<Result<QiChachaResponse>>
})


/**
 * addUserToEnterprise
 *
 */
export const memberEnterpriseUserAddUserV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterpriseUser/addUser/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * getUserEnterpriseInfo
 *
 */
export const memberEnterpriseUserGetInfoV1Post = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<UserEntInfoVO>>('/transaction/transaction-mall-service/member/enterpriseUser/getInfo/v1',) as unknown as Promise<Result<UserEntInfoVO>>
})


/**
 * listUser
 *
 */
export const memberEnterpriseUserListV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<EntUserInfoVO>>('/transaction/transaction-mall-service/member/enterpriseUser/list/v1', data) as unknown as Promise<PageResult<EntUserInfoVO>>
})


/**
 * removeUserFromEnterprise
 *
 */
export const memberEnterpriseUserRemoveUserV1Post = mergeAsyncFn()((data?: EntUserInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/enterpriseUser/removeUser/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * saveEnterpriseAuth
 *
 */
export const memberSaveEnterpriseAuthWeiWhiteListV1Post = mergeAsyncFn()((data?: EnterpriseThirdPartyWhiteListVO) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/member/save/enterpriseAuth/WeiWhiteList/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * nx订单提单接口
 *
 */
export const nxOrderCommitV1Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/nx/order/commit/v1', data) as unknown as Promise<void>
})


/**
 * 私有文件-服务端签名直传
 *
 */
export const openapiBaseFileGeneratePrivatePostPolicyPost = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<any>('/transaction/transaction-mall-service/openapi/base/file/generate/private/post/policy',) as unknown as Promise<any>
})


/**
 * 公有文件-服务端签名直传
 *
 */
export const openapiBaseFileGeneratePublicPostPolicyGet = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<any>('/transaction/transaction-mall-service/openapi/base/file/generate/public/post/policy', { headers }) as unknown as Promise<any>
})


/**
 * 获取私有文件
 *
 */
export const openapiFilePrivateGetGet = mergeAsyncFn()((params: {

  /**
   * 文件key
   */
  ossKey: string

}) => {
  return request.get<string>('/transaction/transaction-mall-service/openapi/file/private/get', { params }) as unknown as Promise<string>
})


/**
 * queryEntByName
 *
 */
export const openapiMemberEnterpriseQueryEntByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseVO>>('/transaction/transaction-mall-service/openapi/member/enterprise/queryEntByName/v1', data) as unknown as Promise<Result<EnterpriseVO>>
})


/**
 * queryEntByNameWithNoLogIn
 *
 */
export const openapiMemberEnterpriseQueryEntByNameV2Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<GetBasicFullDetailResp>>('/transaction/transaction-mall-service/openapi/member/enterprise/queryEntByName/v2', data) as unknown as Promise<Result<GetBasicFullDetailResp>>
})


/**
 * queryEntByNameWithNoLogInV3
 *
 */
export const openapiMemberEnterpriseQueryEntByNameV3Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<any>>('/transaction/transaction-mall-service/openapi/member/enterprise/queryEntByName/v3', data) as unknown as Promise<Result<any>>
})


/**
 * queryEntListByName
 *
 */
export const openapiMemberEnterpriseQueryEntListByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<QiChachaResponse>>('/transaction/transaction-mall-service/openapi/member/enterprise/queryEntListByName/v1', data) as unknown as Promise<Result<QiChachaResponse>>
})


/**
 * queryEntListByNameWithNoLogIn
 *
 */
export const openapiMemberEnterpriseQueryEntListByNameV2Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<QiChachaResponse>>('/transaction/transaction-mall-service/openapi/member/enterprise/queryEntListByName/v2', data) as unknown as Promise<Result<QiChachaResponse>>
})


/**
 * getUserEnterpriseInfo
 *
 */
export const openapiMemberEnterpriseUserGetInfoV1Post = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<UserEntInfoVO>>('/transaction/transaction-mall-service/openapi/member/enterpriseUser/getInfo/v1',) as unknown as Promise<Result<UserEntInfoVO>>
})


/**
 * 取消订单
 *
 */
export const openapiOrderCancelV1ByIdPost = mergeAsyncFn()((id: string | number, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>(`/transaction/transaction-mall-service/openapi/order/cancel/v1/${id}`,) as unknown as Promise<void>
})


/**
 * 添加购物车
 *
 */
export const openapiOrderCartAddV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/cart/add/v1', data) as unknown as Promise<void>
})


/**
 * 查询用户购物车商品数量
 *
 */
export const openapiOrderCartCountV1Post = mergeAsyncFn()((data?: TransactionOrderCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/openapi/order/cart/count/v1', data) as unknown as Promise<number>
})


/**
 * 删除购物车商品行
 *
 */
export const openapiOrderCartDeleteV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/cart/delete/v1', data) as unknown as Promise<void>
})


/**
 * 查询购物车列表信息
 *
 */
export const openapiOrderCartListV1Post = mergeAsyncFn()((data?: TransactionOrderCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderCartVO>>('/transaction/transaction-mall-service/openapi/order/cart/list/v1', data) as unknown as Promise<PageResult<TransactionOrderCartVO>>
})


/**
 * 修改购物车数量
 *
 */
export const openapiOrderCartUpdateNumV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/cart/updateNum/v1', data) as unknown as Promise<void>
})


/**
 * 修改购物车数量品类
 *
 */
export const openapiOrderCartUpdateSkuV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/cart/updateSku/v1', data) as unknown as Promise<void>
})


/**
 * 提交订单V2(多租户同时、相同租户按税率拆单)
 *
 */
export const openapiOrderCommitV2Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/commit/v2', data) as unknown as Promise<void>
})


/**
 * 查询我的订单数量
 *
 */
export const openapiOrderCountUsersV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderCountVO>('/transaction/transaction-mall-service/openapi/order/count/users/v1', data) as unknown as Promise<TransactionOrderCountVO>
})


/**
 * 查询订单关联客户信息
 *
 */
export const openapiOrderCustomerDetailListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderCustomerInfoVO>('/transaction/transaction-mall-service/openapi/order/customer/detail/list/v1', data) as unknown as Promise<TransactionOrderCustomerInfoVO>
})


/**
 * 写入/更新订单关联客户信息
 *
 */
export const openapiOrderCustomerDetailPsotV1Post = mergeAsyncFn()((data?: TransactionOrderCustomerInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/customer/detail/psot/v1', data) as unknown as Promise<void>
})


/**
 * 小程序修改订单
 *
 */
export const openapiOrderCustomerModifyV1Post = mergeAsyncFn()((data?: XiuGaiDingDanDanJiaShuLiang, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/customer/modify/v1', data) as unknown as Promise<void>
})


/**
 * 查询订单明细
 *
 */
export const openapiOrderDetailV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/openapi/order/detail/v1', data) as unknown as Promise<TransactionOrderVO>
})


/**
 * 更新订单行金额数量
 *
 */
export const openapiOrderItemUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/openapi/order/item/update/v1', data) as unknown as Promise<TransactionOrderVO>
})


/**
 * 更新订单状态
 *
 */
export const openapiOrderLifeCycleV1Post = mergeAsyncFn()((data?: TransactionOrderLifeCycleVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/lifeCycle/v1', data) as unknown as Promise<void>
})


/**
 * 查询我的订单清单
 *
 */
export const openapiOrderListUsersV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/openapi/order/list/users/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 查询订单清单
 *
 */
export const openapiOrderListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/openapi/order/list/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 订单支付(线下)
 *
 */
export const openapiOrderPayOfflineV1Post = mergeAsyncFn()((data?: TransactionOrderOfflinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/pay/offline/v1', data) as unknown as Promise<void>
})


/**
 * 0元支付(线下)
 *
 */
export const openapiOrderPayOfflineV2Post = mergeAsyncFn()((data?: TransactionOrderOnlinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/pay/offline/v2', data) as unknown as Promise<void>
})


/**
 * 订单支付(线上个人支付)
 *
 */
export const openapiOrderPayOnlineUserV1Post = mergeAsyncFn()((data?: TransactionOrderOnlinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/openapi/order/pay/online/user/v1', data) as unknown as Promise<Result<string>>
})


/**
 * 查询订单前置数据
 *
 */
export const openapiOrderQueryPreInfoV1Post = mergeAsyncFn()((data?: TransactionOrderPreInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderPreInfoVO>('/transaction/transaction-mall-service/openapi/order/query/preInfo/v1', data) as unknown as Promise<TransactionOrderPreInfoVO>
})


/**
 * 重新上传交易凭证
 *
 */
export const openapiOrderReuploadPaymentCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderOfflinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/reupload/paymentCredential/v1', data) as unknown as Promise<void>
})


/**
 * 保存订单前置数据
 *
 */
export const openapiOrderSavePreInfoV1Post = mergeAsyncFn()((data?: TransactionOrderPreInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/save/preInfo/v1', data) as unknown as Promise<void>
})


/**
 * 上传POA凭证
 *
 */
export const openapiOrderUploadPoaCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/openapi/order/upload/poaCredential/v1', data) as unknown as Promise<void>
})


/**
 * 查询登陆用户在途订单
 *
 */
export const openapiOrderUserUnfinishedV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO[]>('/transaction/transaction-mall-service/openapi/order/user/unfinished/v1', data) as unknown as Promise<TransactionOrderVO[]>
})


/**
 * 查询买过的店铺清单
 *
 */
export const openapiOrderUsersBuyMerchantByUserIdGet = mergeAsyncFn()((userId: string | number) => {
  return request.get<any[]>(`/transaction/transaction-mall-service/openapi/order/users/buy-merchant/${userId}`,) as unknown as Promise<any[]>
})


/**
 * getProductInfoByCondition
 *
 */
export const openapiProductProductInfoV1Post = mergeAsyncFn()((data?: ShangPinXinXi, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<ShangPinXinXi>('/transaction/transaction-mall-service/openapi/product/productInfo/v1', data) as unknown as Promise<ShangPinXinXi>
})


/**
 * 查询展示商品信息关联的租户清单v2
 *
 */
export const openapiTenantShowListV2Post = mergeAsyncFn()((data?: TransactionTenantInfoSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TenantInfoOnProductDetailShowVO>>('/transaction/transaction-mall-service/openapi/tenant/show/list/v2', data) as unknown as Promise<Result<TenantInfoOnProductDetailShowVO>>
})


/**
 * 检查BIS物料推送情况
 *
 */
export const orderAdminBisOrderMaterialListV1Post = mergeAsyncFn()((data?: BISOrderDeliveryVO) => {
  return request.post<BISOrderDeliveryVO>('/transaction/transaction-mall-service/order/admin/bisOrderMaterial/list/v1', data) as unknown as Promise<BISOrderDeliveryVO>
})


/**
 * 取消超时单据
 *
 */
export const orderAdminCancelExpiredV1Post = mergeAsyncFn()((data?: TransactionOrderCancelVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/cancel/expired/v1', data) as unknown as Promise<void>
})


/**
 * 取消订单打标接口
 *
 */
export const orderAdminCancelTagV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/cancel/tag/v1', data) as unknown as Promise<void>
})


/**
 * 查询订单合规检查项
 *
 */
export const orderAdminCheckListV1Get = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<TransactionOrderComplianceCheckVO>('/transaction/transaction-mall-service/order/admin/check/list/v1', { params, headers }) as unknown as Promise<TransactionOrderComplianceCheckVO>
})


/**
 * 合规检查项上传附件
 *
 */
export const orderAdminCheckUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderCheckListModifyVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/check/update/v1', data) as unknown as Promise<void>
})


/**
 * 定时任务触发订单合规检测
 *
 */
export const orderAdminComplianceCheckV1Post = mergeAsyncFn()(() => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/compliance/check/v1',) as unknown as Promise<void>
})


/**
 * 确认订单附件
 *
 */
export const orderAdminConfirmAttachmentV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentCommitVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/confirm/attachment/v1', data) as unknown as Promise<void>
})


/**
 * 客户未付款提醒
 *
 */
export const orderAdminConsumerPaymentReminderV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/consumer/payment/reminder/v1',) as unknown as Promise<void>
})


/**
 * 获取当前订单状态
 *
 */
export const orderAdminCurrentOrderStatusGetV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<TransactionOrderStatusVO>('/transaction/transaction-mall-service/order/admin/currentOrderStatus/get/v1', data) as unknown as Promise<TransactionOrderStatusVO>
})


/**
 * 查询订单关联客户信息
 *
 */
export const orderAdminCustomerDetailListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderCustomerInfoVO>('/transaction/transaction-mall-service/order/admin/customer/detail/list/v1', data) as unknown as Promise<TransactionOrderCustomerInfoVO>
})


/**
 * 删除订单附件
 *
 */
export const orderAdminDeleteAttachmentV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/delete/attachment/v1', data) as unknown as Promise<void>
})


/**
 * 提交订单发票
 *
 */
export const orderAdminInvoiceCommitV1Post = mergeAsyncFn()((data?: TransactionOrderInvoiceVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/invoice/commit/v1', data) as unknown as Promise<void>
})


/**
 * 管理员取消订单
 *
 */
export const orderAdminLifeCycleCancelV1Post = mergeAsyncFn()((data?: TransactionOrderCancelVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/lifeCycle/cancel/v1', data) as unknown as Promise<void>
})


/**
 * 管理员确认发货
 *
 */
export const orderAdminLifeCycleShippedV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/lifeCycle/shipped/v1', data) as unknown as Promise<void>
})


/**
 * 发货并完成
 *
 */
export const orderAdminLifeCycleShippedV2Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<boolean>('/transaction/transaction-mall-service/order/admin/lifeCycle/shipped/v2', data) as unknown as Promise<boolean>
})


/**
 * 发货中到完成
 *
 */
export const orderAdminLifeCycleShipping2shippedV2Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<boolean>('/transaction/transaction-mall-service/order/admin/lifeCycle/shipping2shipped/v2', data) as unknown as Promise<boolean>
})


/**
 * 发货中
 *
 */
export const orderAdminLifeCycleShippingV2Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<boolean>('/transaction/transaction-mall-service/order/admin/lifeCycle/shipping/v2', data) as unknown as Promise<boolean>
})


/**
 * 管理员订单未确认
 *
 */
export const orderAdminLifeCycleUnconfirmedV1Post = mergeAsyncFn()((data?: TransactionOrderUnconfirmedVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/lifeCycle/unconfirmed/v1', data) as unknown as Promise<void>
})


/**
 * 管理员订单未确认
 *
 */
export const orderAdminLifeCycleUnconfirmedV2Post = mergeAsyncFn()((data?: TransactionOrderUnconfirmedVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/lifeCycle/unconfirmed/v2', data) as unknown as Promise<void>
})


/**
 * 更新订单状态
 *
 */
export const orderAdminLifeCycleV1Post = mergeAsyncFn()((data?: TransactionOrderLifeCycleVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/lifeCycle/v1', data) as unknown as Promise<void>
})


/**
 * 查询关联订单清单
 *
 */
export const orderAdminListAssiociatedOrderV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/admin/listAssiociatedOrder/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 查询组合商品订单
 *
 */
export const orderAdminListComponentV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO[]>('/transaction/transaction-mall-service/order/admin/list/component/v1',) as unknown as Promise<TransactionOrderVO[]>
})


/**
 * 导出订单清单
 *
 */
export const orderAdminListExportV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/list/export/v1', data) as unknown as Promise<void>
})


/**
 * 查询订阅记录
 *
 */
export const orderAdminListSubscriptionV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang[]>('/transaction/transaction-mall-service/order/admin/listSubscription/v1',) as unknown as Promise<DingYueXinXiDuiXiang[]>
})


/**
 * 查询订单清单
 *
 */
export const orderAdminListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/admin/list/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 修改订单
 *
 */
export const orderAdminModifyV1Post = mergeAsyncFn()((data?: XiuGaiDingDanDanJiaShuLiang, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/modify/v1', data) as unknown as Promise<void>
})


/**
 * 查询Nexus Instance状态
 *
 */
export const orderAdminNexusQueryV1Post = mergeAsyncFn()(() => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/nexus/query/v1',) as unknown as Promise<void>
})


/**
 * 查询订单操作记录
 *
 */
export const orderAdminOrderOperationListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderOperationLogVO>>('/transaction/transaction-mall-service/order/admin/order/operation/list/v1', data) as unknown as Promise<PageResult<TransactionOrderOperationLogVO>>
})


/**
 * 计算订单收货过期时间
 *
 */
export const orderAdminReceiveExpiredTimeGetV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<string>('/transaction/transaction-mall-service/order/admin/receiveExpiredTime/get/v1', data) as unknown as Promise<string>
})


/**
 * 超时自动确认收货
 *
 */
export const orderAdminReceiveExpiredV1Post = mergeAsyncFn()((data?: TransactionOrderLifeCycleVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/receive/expired/v1', data) as unknown as Promise<void>
})


/**
 * 新增交易规则
 *
 */
export const orderAdminRuleAddV1Post = mergeAsyncFn()((data?: XiuGaiJiaoYiGuiZeRuCanDuiXiang, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/rule/add/v1', data) as unknown as Promise<void>
})


/**
 * 查询交易规则详情
 *
 */
export const orderAdminRuleDetailV1Post = mergeAsyncFn()((data?: ChaXunJiaoYiGuiZeRuCan, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<JiaoYiGuiZeDuiXiang>('/transaction/transaction-mall-service/order/admin/rule/detail/v1', data) as unknown as Promise<JiaoYiGuiZeDuiXiang>
})


/**
 * 启用/禁用交易规则
 *
 */
export const orderAdminRuleEnableV1Post = mergeAsyncFn()((data?: XiuGaiJiaoYiGuiZeRuCanDuiXiang, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/rule/enable/v1', data) as unknown as Promise<void>
})


/**
 * 查询交易规则编码
 *
 */
export const orderAdminRuleKeysV1Get = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<TransactionRuleKeyVO[]>('/transaction/transaction-mall-service/order/admin/rule/keys/v1', { headers }) as unknown as Promise<TransactionRuleKeyVO[]>
})


/**
 * 分页查询交易规则列表
 *
 */
export const orderAdminRulePageV1Post = mergeAsyncFn()((data?: ChaXunJiaoYiGuiZeRuCan, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<JiaoYiGuiZeDuiXiang>>('/transaction/transaction-mall-service/order/admin/rule/page/v1', data) as unknown as Promise<PageResult<JiaoYiGuiZeDuiXiang>>
})


/**
 * 根据租户和交易规则Key查询详情
 *
 */
export const orderAdminRuleReceiveOrderV1Post = mergeAsyncFn()((data?: ChaXunJiaoYiGuiZeRuCan, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionRuleConfigTenantVO[]>('/transaction/transaction-mall-service/order/admin/rule/receive/order/v1', data) as unknown as Promise<TransactionRuleConfigTenantVO[]>
})


/**
 * 修改交易规则
 *
 */
export const orderAdminRuleUpdateV1Post = mergeAsyncFn()((data?: XiuGaiJiaoYiGuiZeRuCanDuiXiang, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/rule/update/v1', data) as unknown as Promise<void>
})


/**
 * 保存IEHUB租户信息
 *
 */
export const orderAdminSaveTenantInfoV1Post = mergeAsyncFn()((data?: TenantVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/saveTenantInfo/v1', data) as unknown as Promise<void>
})


/**
 * 组合订单查看是否可发货
 *
 */
export const orderAdminShippingThresholdCheckV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<boolean>('/transaction/transaction-mall-service/order/admin/shippingThreshold/check/v1', data) as unknown as Promise<boolean>
})


/**
 * 查询订阅详情
 *
 */
export const orderAdminSubscriptionDetailV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang>('/transaction/transaction-mall-service/order/admin/subscription/detail/v1', data) as unknown as Promise<DingYueXinXiDuiXiang>
})


/**
 * 导出订阅列表
 *
 */
export const orderAdminSubscriptionExportV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/subscription/export/v1', data) as unknown as Promise<void>
})


/**
 * 订单发货生成订阅记录
 *
 */
export const orderAdminSubscriptionGenerateV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<boolean>('/transaction/transaction-mall-service/order/admin/subscription/generate/v1', data) as unknown as Promise<boolean>
})


/**
 * 根据订单号查询商品的有效期
 *
 */
export const orderAdminSubscriptionListEffectiveDateV1Post = mergeAsyncFn()((params?: {

  /**
   * orderId
   */
  orderId?: number

}) => {
  return request.post<TransactionSubscriptionEffectiveDateVO[]>('/transaction/transaction-mall-service/order/admin/subscription/list/effective/date/v1',) as unknown as Promise<TransactionSubscriptionEffectiveDateVO[]>
})


/**
 * 查询订阅记录
 *
 */
export const orderAdminSubscriptionListV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang[]>('/transaction/transaction-mall-service/order/admin/subscription/list/v1',) as unknown as Promise<DingYueXinXiDuiXiang[]>
})


/**
 * 分页查询订阅列表
 *
 */
export const orderAdminSubscriptionPageV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionSubscriptionMainVO>>('/transaction/transaction-mall-service/order/admin/subscription/page/v1', data) as unknown as Promise<PageResult<TransactionSubscriptionMainVO>>
})


/**
 * 查询即将过期订阅数量
 *
 */
export const orderAdminSubscriptionToExpireCountV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/order/admin/subscription/toExpire/count/v1', data) as unknown as Promise<number>
})


/**
 * 更新订阅记录中的Tenant
 *
 */
export const orderAdminSubscriptionUpdateTenantNameV1Post = mergeAsyncFn()(() => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/subscription/updateTenantName/v1',) as unknown as Promise<void>
})


/**
 * 订单打标接口
 *
 */
export const orderAdminTagV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/tag/v1', data) as unknown as Promise<void>
})


/**
 * 更新订单
 *
 */
export const orderAdminUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/update/v1', data) as unknown as Promise<void>
})


/**
 * admin上传验收凭证
 *
 */
export const orderAdminUploadCompletionCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/admin/upload/completionCredential/v1', data) as unknown as Promise<void>
})


/**
 * 取消订单
 *
 */
export const orderCancelV1ByIdPost = mergeAsyncFn()((id: string | number, data?: TransactionOrderUserCancelVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>(`/transaction/transaction-mall-service/order/cancel/v1/${id}`, data) as unknown as Promise<void>
})


/**
 * 取消订单
 *
 */
export const orderCancelV2ByIdPost = mergeAsyncFn()((id: string | number, data?: TransactionOrderUserCancelVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>(`/transaction/transaction-mall-service/order/cancel/v2/${id}`, data) as unknown as Promise<void>
})


/**
 * 添加购物车
 *
 */
export const orderCartAddV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/cart/add/v1', data) as unknown as Promise<void>
})


/**
 * 查询用户购物车商品数量
 *
 */
export const orderCartCountV1Post = mergeAsyncFn()((data?: TransactionOrderCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/order/cart/count/v1', data) as unknown as Promise<number>
})


/**
 * 删除购物车商品行
 *
 */
export const orderCartDeleteV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/cart/delete/v1', data) as unknown as Promise<void>
})


/**
 * 查询购物车列表信息
 *
 */
export const orderCartListV1Post = mergeAsyncFn()((data?: TransactionOrderCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderCartVO>>('/transaction/transaction-mall-service/order/cart/list/v1', data) as unknown as Promise<PageResult<TransactionOrderCartVO>>
})


/**
 * 修改购物车组合商品数量
 *
 */
export const orderCartUpdateComponentSkuNumV1Post = mergeAsyncFn()((data?: TransactionOrderCartComponentSkusVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/cart/updateComponentSkuNum/v1', data) as unknown as Promise<void>
})


/**
 * 修改购物车数量
 *
 */
export const orderCartUpdateNumV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/cart/updateNum/v1', data) as unknown as Promise<void>
})


/**
 * 修改购物车数量品类
 *
 */
export const orderCartUpdateSkuV1Post = mergeAsyncFn()((data?: TransactionOrderItemCartVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/cart/updateSku/v1', data) as unknown as Promise<void>
})


/**
 * 提交订单Tenant检查V1
 *
 */
export const orderCommitIehubTenantCheckV1Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingDanTiJiaoJianChaJieGuo>('/transaction/transaction-mall-service/order/commit/iehubTenant/check/v1', data) as unknown as Promise<DingDanTiJiaoJianChaJieGuo>
})


/**
 * 提交订单先决条件检查V1
 *
 */
export const orderCommitPreconditionCheckV1Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingDanTiJiaoJianChaJieGuo>('/transaction/transaction-mall-service/order/commit/precondition/check/v1', data) as unknown as Promise<DingDanTiJiaoJianChaJieGuo>
})


/**
 * 提交订单限购检查V1
 *
 */
export const orderCommitPurchaseLimitCheckV1Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingDanTiJiaoJianChaJieGuo>('/transaction/transaction-mall-service/order/commit/purchaseLimit/check/v1', data) as unknown as Promise<DingDanTiJiaoJianChaJieGuo>
})


/**
 * 提交订订单代下单检查V1
 *
 */
export const orderCommitSubstituteCheckV1Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingDanTiJiaoJianChaJieGuo>('/transaction/transaction-mall-service/order/commit/substitute/check/v1', data) as unknown as Promise<DingDanTiJiaoJianChaJieGuo>
})


/**
 * 提交订单V2(多租户同时、相同租户按税率拆单)
 *
 */
export const orderCommitV2Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/commit/v2', data) as unknown as Promise<void>
})


/**
 * 提交订单V3(按卖家、相同租户按税率拆单、付款方式拆单)
 *
 */
export const orderCommitV3Post = mergeAsyncFn()((data?: TransactionOrderCombineCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingDanZuHeTiJiaoJieGuo>('/transaction/transaction-mall-service/order/commit/v3', data) as unknown as Promise<DingDanZuHeTiJiaoJieGuo>
})


/**
 * 订单合规检查审批流回调
 *
 */
export const orderComplianceCheckCallbackV1Post = mergeAsyncFn()((data?: DingDanHeGuiXingJianChaHuiTiaoCanShu) => {
  return request.post<void>('/transaction/transaction-mall-service/order/compliance/check/callback/v1', data) as unknown as Promise<void>
})


/**
 * 查询我的订单数量
 *
 */
export const orderCountUsersV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderCountVO>('/transaction/transaction-mall-service/order/count/users/v1', data) as unknown as Promise<TransactionOrderCountVO>
})


/**
 * 查询订单关联客户信息
 *
 */
export const orderCustomerDetailListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderCustomerInfoVO>('/transaction/transaction-mall-service/order/customer/detail/list/v1', data) as unknown as Promise<TransactionOrderCustomerInfoVO>
})


/**
 * 写入/更新订单关联客户信息
 *
 */
export const orderCustomerDetailPsotV1Post = mergeAsyncFn()((data?: TransactionOrderCustomerInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/customer/detail/psot/v1', data) as unknown as Promise<void>
})


/**
 * Damex-P 接口调用
 *
 */
export const orderDamexpCheckTestV1Post = mergeAsyncFn()((data?: DamexPBizParamVO[], headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DamexPBizRespSimpleVO[]>('/transaction/transaction-mall-service/order/damexp/check/test/v1', data) as unknown as Promise<DamexPBizRespSimpleVO[]>
})


/**
 * 删除订单附件
 *
 */
export const orderDeleteAttachmentV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/delete/attachment/v1', data) as unknown as Promise<void>
})


/**
 * 查询订单明细
 *
 */
export const orderDetailV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/order/detail/v1', data) as unknown as Promise<TransactionOrderVO>
})


/**
 * 查询字典清单
 *
 */
export const orderDictListV1Post = mergeAsyncFn()((data?: TransactionDictVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionDictVO[]>('/transaction/transaction-mall-service/order/dict/list/v1', data) as unknown as Promise<TransactionDictVO[]>
})


/**
 * 查询字典清单
 *
 */
export const orderDictListV2Post = mergeAsyncFn()((data?: TransactionDictVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionDictVO[]>>('/transaction/transaction-mall-service/order/dict/list/v2', data) as unknown as Promise<Result<TransactionDictVO[]>>
})


/**
 * 查询字典精简清单
 *
 */
export const orderDictMapV1Post = mergeAsyncFn()((data?: TransactionDictVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<any>('/transaction/transaction-mall-service/order/dict/map/v1', data) as unknown as Promise<any>
})


/**
 * 查询IEHUB Tenant列表
 *
 */
export const orderIehubTenantListV1Post = mergeAsyncFn()((data?: ChaXunZuHuXinXiRuCan, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<IEHUBZuHuXinXi[]>('/transaction/transaction-mall-service/order/iehub/tenant/list/v1', data) as unknown as Promise<IEHUBZuHuXinXi[]>
})


/**
 * 验证Tenant
 *
 */
export const orderIehubTenantValidateV1Post = mergeAsyncFn()((data?: ChaXunZuHuXinXiRuCan, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<IEHUBZuHuXinXi>('/transaction/transaction-mall-service/order/iehub/tenant/validate/v1', data) as unknown as Promise<IEHUBZuHuXinXi>
})


/**
 * 删除订单行
 *
 */
export const orderItemAdminDeleteV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/item/admin/delete/v1', data) as unknown as Promise<void>
})


/**
 * 更新订单行金额数量
 *
 */
export const orderItemAdminUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/item/admin/update/v1', data) as unknown as Promise<void>
})


/**
 * 删除订单行
 *
 */
export const orderItemDeleteV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/order/item/delete/v1', data) as unknown as Promise<TransactionOrderVO>
})


/**
 * 删除订单行
 *
 */
export const orderItemSellerDeleteV1Post = mergeAsyncFn()((data?: TransactionOrderVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/item/seller/delete/v1', data) as unknown as Promise<void>
})


/**
 * 更新订单行金额数量
 *
 */
export const orderItemSellerUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/item/seller/update/v1', data) as unknown as Promise<void>
})


/**
 * 更新订单行金额数量
 *
 */
export const orderItemUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/order/item/update/v1', data) as unknown as Promise<TransactionOrderVO>
})


/**
 * 更新订单状态
 *
 */
export const orderLifeCycleV1Post = mergeAsyncFn()((data?: TransactionOrderLifeCycleVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/lifeCycle/v1', data) as unknown as Promise<void>
})


/**
 * 查询组合商品订单
 *
 */
export const orderListComponentV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO[]>('/transaction/transaction-mall-service/order/list/component/v1',) as unknown as Promise<TransactionOrderVO[]>
})


/**
 * 查询金额最大的订单
 *
 */
export const orderListMostAmountV1Post = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}) => {
  return request.post<TransactionOrderVO>('/transaction/transaction-mall-service/order/list/mostAmount/v1',) as unknown as Promise<TransactionOrderVO>
})


/**
 * 查询我的订单清单
 *
 */
export const orderListUsersV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/list/users/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 查询我的订单清单
 *
 */
export const orderListUsersV2Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/list/users/v2', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 查询订单清单
 *
 */
export const orderListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/list/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 订单支付结果查询
 *
 */
export const orderOnlinePaymentQueryByPaymentCodeGet = mergeAsyncFn()((paymentCode: string | number, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<Result<boolean>>(`/transaction/transaction-mall-service/order/online/payment/query/${paymentCode}`, { headers }) as unknown as Promise<Result<boolean>>
})


/**
 * 订单支付(线下)
 *
 */
export const orderPayOfflineV1Post = mergeAsyncFn()((data?: TransactionOrderOfflinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/pay/offline/v1', data) as unknown as Promise<void>
})


/**
 * 0元支付(线下)
 *
 */
export const orderPayOfflineV2Post = mergeAsyncFn()((data?: TransactionOrderOnlinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/pay/offline/v2', data) as unknown as Promise<void>
})


/**
 * 订单支付(线下)
 *
 */
export const orderPayOfflineV3Post = mergeAsyncFn()((data?: TransactionOrderOfflinePaySingleVO[], headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/pay/offline/v3', data) as unknown as Promise<void>
})


/**
 * 订单支付(线上个人支付)
 *
 */
export const orderPayOnlineUserV1Post = mergeAsyncFn()((data?: TransactionOrderOnlinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/order/pay/online/user/v1', data) as unknown as Promise<Result<string>>
})


/**
 * 订单支付(线上个人支付)
 *
 */
export const orderPayOnlineUserV2Post = mergeAsyncFn()((data?: TransactionOrderOnlinePaySingleVO[], headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/order/pay/online/user/v2', data) as unknown as Promise<Result<string>>
})


/**
 * 订单支付(线上)
 *
 */
export const orderPayOnlineV1Post = mergeAsyncFn()((data?: TransactionOrderOnlinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/order/pay/online/v1', data) as unknown as Promise<Result<string>>
})


/**
 * 订单支付(线上)
 *
 */
export const orderPayOnlineV2Post = mergeAsyncFn()((data?: TransactionOrderOnlinePaySingleVO[], headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/order/pay/online/v2', data) as unknown as Promise<Result<string>>
})


/**
 * 立即付款
 *
 */
export const orderPrepayV1ByIdGet = mergeAsyncFn()((id: string | number, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<TransactionOrderPrePayVO>(`/transaction/transaction-mall-service/order/prepay/v1/${id}`, { headers }) as unknown as Promise<TransactionOrderPrePayVO>
})


/**
 * 重新上传交易凭证
 *
 */
export const orderReuploadPaymentCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderOfflinePayVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/reupload/paymentCredential/v1', data) as unknown as Promise<void>
})


/**
 * 取消超时单据
 *
 */
export const orderSellerCancelExpiredV1Post = mergeAsyncFn()((data?: TransactionOrderCancelVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/cancel/expired/v1', data) as unknown as Promise<void>
})


/**
 * seller确认订单附件
 *
 */
export const orderSellerConfirmAttachmentV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentCommitVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/confirm/attachment/v1', data) as unknown as Promise<void>
})


/**
 * 客户未付款提醒
 *
 */
export const orderSellerConsumerPaymentReminderV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/consumer/payment/reminder/v1',) as unknown as Promise<void>
})


/**
 * 查询订单关联客户信息
 *
 */
export const orderSellerCustomerDetailListV1Post = mergeAsyncFn()((data?: QueryConditions) => {
  return request.post<TransactionOrderCustomerInfoVO>('/transaction/transaction-mall-service/order/seller/customer/detail/list/v1', data) as unknown as Promise<TransactionOrderCustomerInfoVO>
})


/**
 * seller删除订单附件
 *
 */
export const orderSellerDeleteAttachmentV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentCommitVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/delete/attachment/v1', data) as unknown as Promise<void>
})


/**
 * seller订单导出数据
 *
 */
export const orderSellerExportDataV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionSellerOrderExportDataVO>('/transaction/transaction-mall-service/order/seller/export/data/v1', data) as unknown as Promise<TransactionSellerOrderExportDataVO>
})


/**
 * 提交订单发票
 *
 */
export const orderSellerInvoiceCommitV1Post = mergeAsyncFn()((data?: TransactionOrderInvoiceVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/invoice/commit/v1', data) as unknown as Promise<void>
})


/**
 * 卖家取消订单
 *
 */
export const orderSellerLifeCycleCancelV1Post = mergeAsyncFn()((data?: TransactionOrderCancelVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/cancel/v1', data) as unknown as Promise<void>
})


/**
 * 确认发货
 *
 */
export const orderSellerLifeCycleShippedV1Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/shipped/v1', data) as unknown as Promise<void>
})


/**
 * 确认发货
 *
 */
export const orderSellerLifeCycleShippedV2Post = mergeAsyncFn()((data?: TransactionOrderShippedVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/shipped/v2', data) as unknown as Promise<void>
})


/**
 * 订单未确认
 *
 */
export const orderSellerLifeCycleUnconfirmedV1Post = mergeAsyncFn()((data?: TransactionOrderUnconfirmedVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/unconfirmed/v1', data) as unknown as Promise<void>
})


/**
 * 订单未确认
 *
 */
export const orderSellerLifeCycleUnconfirmedV2Post = mergeAsyncFn()((data?: TransactionOrderUnconfirmedVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/unconfirmed/v2', data) as unknown as Promise<void>
})


/**
 * 更新订单状态
 *
 */
export const orderSellerLifeCycleV1Post = mergeAsyncFn()((data?: TransactionOrderLifeCycleVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/lifeCycle/v1', data) as unknown as Promise<void>
})


/**
 * 查询组合商品订单
 *
 */
export const orderSellerListComponentV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: number

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO[]>('/transaction/transaction-mall-service/order/seller/list/component/v1',) as unknown as Promise<TransactionOrderVO[]>
})


/**
 * 查询订单清单
 *
 */
export const orderSellerListExportV1Post = mergeAsyncFn()((data?: QueryConditions) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/list/export/v1', data) as unknown as Promise<void>
})


/**
 * 查询订单清单
 *
 */
export const orderSellerListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderVO>>('/transaction/transaction-mall-service/order/seller/list/v1', data) as unknown as Promise<PageResult<TransactionOrderVO>>
})


/**
 * 修改订单
 *
 */
export const orderSellerModifyV1Post = mergeAsyncFn()((data?: XiuGaiDingDanDanJiaShuLiang) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/modify/v1', data) as unknown as Promise<void>
})


/**
 * 查询订单操作记录
 *
 */
export const orderSellerOrderOperationListV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionOrderOperationLogVO>>('/transaction/transaction-mall-service/order/seller/order/operation/list/v1', data) as unknown as Promise<PageResult<TransactionOrderOperationLogVO>>
})


/**
 * 查询订阅详情
 *
 */
export const orderSellerSubscriptionDetailV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang>('/transaction/transaction-mall-service/order/seller/subscription/detail/v1', data) as unknown as Promise<DingYueXinXiDuiXiang>
})


/**
 * 导出订阅列表
 *
 */
export const orderSellerSubscriptionExportV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<string>('/transaction/transaction-mall-service/order/seller/subscription/export/v1', data) as unknown as Promise<string>
})


/**
 * 查询订阅列表
 *
 */
export const orderSellerSubscriptionListByOrderIdV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang[]>('/transaction/transaction-mall-service/order/seller/subscription/listByOrderId/v1',) as unknown as Promise<DingYueXinXiDuiXiang[]>
})


/**
 * 分页查询订阅列表
 *
 */
export const orderSellerSubscriptionPageV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionSubscriptionMainVO>>('/transaction/transaction-mall-service/order/seller/subscription/page/v1', data) as unknown as Promise<PageResult<TransactionSubscriptionMainVO>>
})


/**
 * 查询即将过期订阅数量
 *
 */
export const orderSellerSubscriptionToExpireCountV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/order/seller/subscription/toExpire/count/v1', data) as unknown as Promise<number>
})


/**
 * 更新订单
 *
 */
export const orderSellerUpdateV1Post = mergeAsyncFn()((data?: TransactionOrderVO) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/update/v1', data) as unknown as Promise<void>
})


/**
 * seller验收凭证
 *
 */
export const orderSellerUploadCompletionCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/seller/upload/completionCredential/v1', data) as unknown as Promise<void>
})


/**
 * 添加收货地址
 *
 */
export const orderShippingAddressAddV1Post = mergeAsyncFn()((data?: TransactionShippingAddressVO, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/shippingAddress/add/v1', data) as unknown as Promise<void>
})


/**
 * 删除收货地址
 *
 */
export const orderShippingAddressDeleteV1Post = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: string

}, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/shippingAddress/delete/v1',) as unknown as Promise<void>
})


/**
 * 收货地址详情
 *
 */
export const orderShippingAddressDetailV1Post = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: string

}, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<TransactionShippingAddressVO>('/transaction/transaction-mall-service/order/shippingAddress/detail/v1',) as unknown as Promise<TransactionShippingAddressVO>
})


/**
 * 编辑收货地址
 *
 */
export const orderShippingAddressEditV1Post = mergeAsyncFn()((data?: TransactionShippingAddressVO, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/shippingAddress/edit/v1', data) as unknown as Promise<void>
})


/**
 * 查询收货地址列表
 *
 */
export const orderShippingAddressListV1Post = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<TransactionShippingAddressVO[]>('/transaction/transaction-mall-service/order/shippingAddress/list/v1',) as unknown as Promise<TransactionShippingAddressVO[]>
})


/**
 * 分页查询收货地址列表
 *
 */
export const orderShippingAddressPageV1Post = mergeAsyncFn()((data?: TransactionShippingAddressVO, headers?: {

  /**
   * token
   */
  token: string

}) => {
  return request.post<PageResult<TransactionShippingAddressVO>>('/transaction/transaction-mall-service/order/shippingAddress/page/v1', data) as unknown as Promise<PageResult<TransactionShippingAddressVO>>
})


/**
 * SPS 接口调用
 *
 */
export const orderSpsCheckTestV1Post = mergeAsyncFn()((data?: CheckDamexSpsVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DamexSpsResultDTO>('/transaction/transaction-mall-service/order/sps/check/test/v1', data) as unknown as Promise<DamexSpsResultDTO>
})


/**
 * 续订检查
 *
 */
export const orderSubscriptionCheckV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiXuDingZhunBeiDuiXiang>('/transaction/transaction-mall-service/order/subscription/check/v1', data) as unknown as Promise<DingYueXinXiXuDingZhunBeiDuiXiang>
})


/**
 * 查询订阅详情
 *
 */
export const orderSubscriptionDetailV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang[]>('/transaction/transaction-mall-service/order/subscription/detail/v1', data) as unknown as Promise<DingYueXinXiDuiXiang[]>
})


/**
 * 查询订阅列表
 *
 */
export const orderSubscriptionListByOrderIdV1Post = mergeAsyncFn()((params: {

  /**
   * orderId
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiDuiXiang[]>('/transaction/transaction-mall-service/order/subscription/listByOrderId/v1',) as unknown as Promise<DingYueXinXiDuiXiang[]>
})


/**
 * 分页查询订阅列表
 *
 */
export const orderSubscriptionPageV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<PageResult<TransactionSubscriptionMainVO>>('/transaction/transaction-mall-service/order/subscription/page/v1', data) as unknown as Promise<PageResult<TransactionSubscriptionMainVO>>
})


/**
 * 查询续订准备信息
 *
 */
export const orderSubscriptionPrepareV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<DingYueXinXiXuDingZhunBeiDuiXiang>('/transaction/transaction-mall-service/order/subscription/prepare/v1', data) as unknown as Promise<DingYueXinXiXuDingZhunBeiDuiXiang>
})


/**
 * 查询即将过期订阅数量
 *
 */
export const orderSubscriptionToExpireCountV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/order/subscription/toExpire/count/v1', data) as unknown as Promise<number>
})


/**
 * bis推送失败数据重发
 *
 */
export const orderTransactionBisFailedResendPost = mergeAsyncFn()(() => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/failed/resend',) as unknown as Promise<Result<Void>>
})


/**
 * 手动设置soNo
 *
 */
export const orderTransactionBisSapArtificialSoPost = mergeAsyncFn()((data?: TransactionBisSapArtificialSoReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/sap/artificialSo', data) as unknown as Promise<Result<Void>>
})


/**
 * 测试SAP接口
 *
 */
export const orderTransactionBisSapCreateCustomerPost = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/sap/createCustomer',) as unknown as Promise<Result<Void>>
})


/**
 * 批量服务单Sap信息
 *
 */
export const orderTransactionBisSapFeeBatchPost = mergeAsyncFn()((data?: TransactionBisFeeBatchReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionBisFeeRespVO>>('/transaction/transaction-mall-service/order/transaction/bis/sap/fee/batch', data) as unknown as Promise<Result<TransactionBisFeeRespVO>>
})


/**
 * 查询服务单Sap信息
 *
 */
export const orderTransactionBisSapFeePost = mergeAsyncFn()((data?: TransactionBisFeeReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionBisFeeRespVO>>('/transaction/transaction-mall-service/order/transaction/bis/sap/fee', data) as unknown as Promise<Result<TransactionBisFeeRespVO>>
})


/**
 * 查询商品单Sap信息
 *
 */
export const orderTransactionBisSapProductPost = mergeAsyncFn()((data?: TransactionBisProductReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionBisProductRespVO>>('/transaction/transaction-mall-service/order/transaction/bis/sap/product', data) as unknown as Promise<Result<TransactionBisProductRespVO>>
})


/**
 * 重新推送bis数据
 *
 */
export const orderTransactionBisSapRePushPost = mergeAsyncFn()((data?: TransactionBisSapRePushReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/sap/rePush', data) as unknown as Promise<Result<Void>>
})


/**
 * 查询结算服务单Sap信息
 *
 */
export const orderTransactionBisSapSettlementPost = mergeAsyncFn()((data?: TransactionBisSettlementQueryReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TransactionBisFeeRespVO>>('/transaction/transaction-mall-service/order/transaction/bis/sap/settlement', data) as unknown as Promise<Result<TransactionBisFeeRespVO>>
})


/**
 * 修改sap数据
 *
 */
export const orderTransactionBisSapUpdatePost = mergeAsyncFn()((data?: TransactionBisSapUpdateReqVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/sap/update', data) as unknown as Promise<Result<Void>>
})


/**
 * bis推送结算单数据
 *
 */
export const orderTransactionBisSettlementPushPost = mergeAsyncFn()((data?: TransactionBisSettlementReqVO) => {
  return request.post<Result<TransactionBisSettlementRespVO>>('/transaction/transaction-mall-service/order/transaction/bis/settlement/push', data) as unknown as Promise<Result<TransactionBisSettlementRespVO>>
})


/**
 * bis获取soNO
 *
 */
export const orderTransactionBisSoNoPost = mergeAsyncFn()(() => {
  return request.post<Result<Void>>('/transaction/transaction-mall-service/order/transaction/bis/soNo',) as unknown as Promise<Result<Void>>
})


/**
 * 上传验收凭证
 *
 */
export const orderUploadCompletionCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/upload/completionCredential/v1', data) as unknown as Promise<void>
})


/**
 * 上传POA凭证
 *
 */
export const orderUploadPoaCredentialV1Post = mergeAsyncFn()((data?: TransactionOrderAttachmentVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<void>('/transaction/transaction-mall-service/order/upload/poaCredential/v1', data) as unknown as Promise<void>
})


/**
 * 企业订单买家信息检测
 *
 */
export const orderUserCustomerCheckV1Get = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<number>('/transaction/transaction-mall-service/order/user/customer/check/v1', { headers }) as unknown as Promise<number>
})


/**
 * 企业订单买家信息更新
 *
 */
export const orderUserCustomerUpdateV1Post = mergeAsyncFn()((headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<number>('/transaction/transaction-mall-service/order/user/customer/update/v1',) as unknown as Promise<number>
})


/**
 * 查询登陆用户在途订单
 *
 */
export const orderUserUnfinishedV1Post = mergeAsyncFn()((data?: QueryConditions, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionOrderVO[]>('/transaction/transaction-mall-service/order/user/unfinished/v1', data) as unknown as Promise<TransactionOrderVO[]>
})


/**
 * netPayOrderQuery
 *
 */
export const paymentCombineNetPayOrderOrderQueryV1Post = mergeAsyncFn()((data?: any) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/payment/combine/netPayOrderOrderQuery/v1', data) as unknown as Promise<Result<string>>
})


/**
 * netPayOrderCallback
 *
 */
export const paymentNetPayOrderCallbackV1Post = mergeAsyncFn()((data?: any) => {
  return request.post<CallbackResponseConfirm>('/transaction/transaction-mall-service/payment/netPayOrderCallback/v1', data) as unknown as Promise<CallbackResponseConfirm>
})


/**
 * netPayOrderQuery
 *
 */
export const paymentNetPayOrderOrderQueryV1Post = mergeAsyncFn()((data?: any) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/payment/netPayOrderOrderQuery/v1', data) as unknown as Promise<Result<string>>
})


/**
 * netPayOrderUrl
 *
 */
export const paymentNetPayOrderV1Post = mergeAsyncFn()((data?: NetPayOrderUrlVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/payment/netPayOrder/v1', data) as unknown as Promise<Result<string>>
})


/**
 * getProductById
 *
 */
export const productByProductIdV1Get = mergeAsyncFn()((productId: string | number, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.get<ShangPinXinXi>(`/transaction/transaction-mall-service/product/${productId}/v1`, { headers }) as unknown as Promise<ShangPinXinXi>
})


/**
 * matchSku
 *
 */
export const productMatchSkuV1Post = mergeAsyncFn()((data?: NXPowerMatchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<NXPowerMatchResultVO>('/transaction/transaction-mall-service/product/match/sku/v1', data) as unknown as Promise<NXPowerMatchResultVO>
})


/**
 * getProductInfoByCondition
 *
 */
export const productProductInfoV1Post = mergeAsyncFn()((data?: ShangPinXinXi, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<ShangPinXinXi>('/transaction/transaction-mall-service/product/productInfo/v1', data) as unknown as Promise<ShangPinXinXi>
})


/**
 * 卖家/平台查看开票申请列表
 *
 */
export const queryInvoiceBillListPost = mergeAsyncFn()((data?: InvoiceBillingListQueryDto, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<InvoicePageResultVo>>('/transaction/transaction-mall-service/query/invoice/bill/list', data) as unknown as Promise<Result<InvoicePageResultVo>>
})


/**
 * 根据订单id查询订单买家身份
 *
 */
export const queryOrderSellerIdentityPost = mergeAsyncFn()((params: {

  /**
   * 订单id
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/query/order/seller/identity',) as unknown as Promise<Result<string>>
})


/**
 * 根据订单id查询订单类型
 *
 */
export const queryOrderTypePost = mergeAsyncFn()((params: {

  /**
   * 订单id
   */
  orderId: string

}, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<string>>('/transaction/transaction-mall-service/query/order/type',) as unknown as Promise<Result<string>>
})


/**
 * isEnterpriseExistByUnifiedCreditCode
 *
 */
export const sellerMemberEnterpriseExistByUnifiedCreditCodeV1Post = mergeAsyncFn()((data?: string, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<boolean>>('/transaction/transaction-mall-service/seller/member/enterprise/existByUnifiedCreditCode/v1', data) as unknown as Promise<Result<boolean>>
})


/**
 * queryEntByName
 *
 */
export const sellerMemberEnterpriseQueryEntByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<EnterpriseVO>>('/transaction/transaction-mall-service/seller/member/enterprise/queryEntByName/v1', data) as unknown as Promise<Result<EnterpriseVO>>
})


/**
 * queryEntListByName
 *
 */
export const sellerMemberEnterpriseQueryEntListByNameV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<QiChachaResponse>>('/transaction/transaction-mall-service/seller/member/enterprise/queryEntListByName/v1', data) as unknown as Promise<Result<QiChachaResponse>>
})


/**
 * 查询卖方信息通过租户id
 *
 */
export const tenantGetBySellerGet = mergeAsyncFn()((params: {

  /**
   * sellerTenantId
   */
  sellerTenantId: string

}) => {
  return request.get<Result<TenantInfoVO>>('/transaction/transaction-mall-service/tenant/get/by/seller', { params }) as unknown as Promise<Result<TenantInfoVO>>
})


/**
 * 查询租户信息清单
 *
 */
export const tenantPayListV1Post = mergeAsyncFn()((data?: TransactionTenantInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionTenantInfoVO[]>('/transaction/transaction-mall-service/tenant/pay/list/v1', data) as unknown as Promise<TransactionTenantInfoVO[]>
})


/**
 * getTenantInfoByTenantCode
 *
 */
export const tenantQueryByCodePost = mergeAsyncFn()((params?: {

  /**
   * tenantCode
   */
  tenantCode?: string

}) => {
  return request.post<Result<TransactionTenantInfoVO>>('/transaction/transaction-mall-service/tenant/queryByCode',) as unknown as Promise<Result<TransactionTenantInfoVO>>
})


/**
 * 查询展示租户清单
 *
 */
export const tenantShowListV1Post = mergeAsyncFn()((data?: TransactionTenantInfoVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<TransactionTenantInfoVO[]>('/transaction/transaction-mall-service/tenant/show/list/v1', data) as unknown as Promise<TransactionTenantInfoVO[]>
})


/**
 * 查询展示商品信息关联的租户清单v2
 *
 */
export const tenantShowListV2Post = mergeAsyncFn()((data?: TransactionTenantInfoSearchVO, headers?: {

  /**
   * token
   */
  token: string


  /**
   * system language
   */
  language?: string

}) => {
  return request.post<Result<TenantInfoOnProductDetailShowVO>>('/transaction/transaction-mall-service/tenant/show/list/v2', data) as unknown as Promise<Result<TenantInfoOnProductDetailShowVO>>
})


/**
 * netPayOrderCallback
 *
 */
export const thirdpartyPaymentCombineNetPayOrderCallbackV1Post = mergeAsyncFn()((data?: any) => {
  return request.post<CallbackResponseConfirm>('/transaction/transaction-mall-service/thirdparty/payment/combine/netPayOrderCallback/v1', data) as unknown as Promise<CallbackResponseConfirm>
})


/**
 * netUserPayOrderCallback
 *
 */
export const thirdpartyPaymentCombineUserNetPayOrderCallbackV1Post = mergeAsyncFn()(() => {
  return request.post<CallbackResponseConfirm>('/transaction/transaction-mall-service/thirdparty/payment/combine/user/netPayOrderCallback/v1',) as unknown as Promise<CallbackResponseConfirm>
})
