/**
 * 判断富文本字符串是否为空
 * @param value
 * @returns
 */
export function isEmptyForRichText(value?: string) {
  if (!value) {
    return true
  }
  return value.replace(/<\/?(p|br|div)\s*\/?>/g, '').replace(/\s/g, '') === ''
}