import HttpRequest from '@/plugins/http'

const request = new HttpRequest({
  baseURL: '/marketplace-user'
})

const axios = request.request()

/**
 * 以code获得UIM的用户信息
 * @export
 * @param {Object} params -
 * @param {String} [params.code] -
 * @returns
 */
export async function UIMinfo (params) {
  return axios.post('/user/uiminfo/v1', params)
}

/**
 * 获取UIM AccessToken
 * @export
 * @param {Object} params -
 * @returns
 */
export async function getUimAccessToken (params) {
  return axios.post('/user/getUimAccessToken/v1', params)
}

/**
 * 获取UIM AccessToken2
 * @export
 * @param {Object} params -
 * @returns
 */
export async function getUimAccessTokenV2 (params) {
  return axios.post('/user/getUimAccessToken/v2', params)
}

/**
 * 以AccessToken获得UIM的用户信息
 * @export
 * @param {Object} params -
 * @param {String} [params.code] -
 * @returns
 */
export async function UIMinfoByAccessToken (params) {
  return axios.post('/user/loginByUimAccessToken/v1', params)
}

/**
 * 访问用户留资
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userContact (params) {
  return axios.post('/user/contact/v1', params)
}

/**
 * 用户Profile保存
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userProfile (params) {
  return axios.post('/user/profile/v1', params)
}

/**
 * 访问用户留资（2023-2-28留资逻辑变更）
 * @export
 * @param {{product_id: string}} params - 查看swagger
 * @returns
 */
export async function getUserContact (params) {
  // return axios.post('/user/contact/info/v2', params)
  return axios.post('/user/contact/info/v2', params)
}

/**
 * 用户Profile保存（2023-2-28留资逻辑变更）
 * @export
 * @param {Record<string, any>} params - 查看swagger
 * @returns
 */
export async function userProfileSave (params) {
  // return axios.post('/user/contact/v2', params)
  return axios.post('/user/contact/v2', params)
}
// 独立表单
export async function userProfileSaveV3 (params) {
  // return axios.post('/user/contact/v2', params)
  return axios.post('/user/contact/v3', params)
}
/**
 * 用户头像上传
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userAvatar (params) {
  return axios.post('/user/avatar/v1', params)
}

/**
 * 获取用户Profile信息
 * @export
 * @param {{desens: boolean;}} params - 查看swagger
 * @returns
 */
export async function getUserProfile (params) {
  return axios.post('/user/profile/Info/v1', params)
}

/**
 * 用户登出
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userLogout (params) {
  return axios.post('/user/logout/v1', params)
}

/**
 * 获取用户留资
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userContactInfo (params) {
  return axios.post('/user/contact/info/v1', params)
}

/**
 * 验证登录
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function validLogin (params) {
  return axios.post('/user/validLogin/v1', params)
}

/**
 * 获取用户信息一次性code
 * @export
 * @param {Record<string, any>} params - 查看swagger
 * @returns {any}
 */
export async function getOnceCode (params) {
  return axios.post('/user/onece/code/v1', params)
}

/**
 * 用户搜索历史记录列表查询
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function getUserHistorySearchList (params) {
  return axios.post('/user/search/history/list', params)
}

/**
 * 用户搜索热词列表查询
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function getUserHotSearchList (params) {
  return axios.post('/user/hot/keyword/list', params)
}

/**
 * 清空用户搜索历史记录
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function clearUserHistorySearch (params) {
  return axios.post('/user/search/history/clear', params)
}

/**
 * 用户收藏列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userCollectionList (params) {
  return axios.post('/user/collection/get/list', params)
}

/**
 * 删除用户收藏(列表)
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function deleteUserCollection (params) {
  return axios.post('/user/collection/delete', params)
}

/**
 * 取消用户收藏(当前位置)
 * @export
 * @param {object} params - 查看swagger
 * @returns
 */
export async function deleteUserCurrentCollection (params) {
  return axios.post('/user/collection/cancel', params)
}

/**
 * 添加用户浏览历史
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function addUserHistory (params) {
  return axios.post('/user/history/post', params)
}

/**
 * 添加用户收藏
 * @export
 * @param {object} params - 查看swagger
 * @returns
 */
export async function createUserCollection (params) {
  return axios.post('/user/collection/post', params)
}

/**
 * 用户浏览记录列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function userHistoryList (params) {
  return axios.post('/user/history/get/list', params)
}

/**
 * 删除用户浏览记录
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function deleteUserHistory (params) {
  return axios.post('/user/history/delete', params)
}

/**
 * 清除所有用户浏览记录
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function clearAllUserHistory (params) {
  return axios.post('/user/history/clean', params)
}

/**
 * 获取当前登录状态及详情
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function checkUserInfo (params) {
  return axios.post('/user/info/v1', params)
}

// 获取短链接详情
export async function getShortLinkNewDetail(params) {
  return axios.get('/qrcode/realLink', params)
}

/**
 * 删除用户订阅
 * @export
 * @param {object} params
 * @returns
 */
export async function addUserSubscriptions (params) {
  return axios.post('/user/subscriptions/add', params)
}

/**
 * 取消用户订阅
 * @export
 * @param {Object} params
 * @returns
 */
export async function cancelUserSubscriptions (params) {
  return axios.post('/user/subscriptions/cancel', params)
}

/**
 * 获取用户订阅列表
 * @export
 * @param {JSON} params
 * @returns
 */
export async function getUserSubscriptionsList (params) {
  return axios.post('/user/subscriptions/list', params)
}
/**
 * 获取用户是否订阅
 * @export
 * @param {JSON} params
 * @returns
 */
export async function getUserSubscriptionsExists (params) {
  return axios.post('/user/subscriptions/get', params)
}

// 获取OSS Policy
export async function getOSSPolicy (params) {
  return axios.get('/oss/policy', params)
}

// 文件上传成功后传递路径
export async function setUserFileKey (params) {
  return axios.post('/user/activity/update/file', params)
}

// 获取手机验证码
export async function sendPhoneCode (params) {
  return axios.post('/user/send/auth/msg/v1', params)
}

// 获取邮箱验证码
export async function sendEmailCode (params) {
  return axios.post('/user/send/auth/mail/v1', params)
}

// 校验验证码
export async function checkCode (params) {
  return axios.post('/user/verify/auth/code/v1', params)
}

// 获取有效新生accessToken
export async function getShareAccessToken (params) {
  return axios.post('/user/shareAccessToken/v1', params)
}

// 获取试用列表
export async function getTrialList (params) {
  return axios.post('/user/trial/list', params)
}

// 注销账户
export async function userLogOff () {
  return axios.post('/user/cancellation/v1', { platform: 'web', anonymousId: window.gdp('getDeviceId') || '' })
}

// 获取有效新生Auto accessToken
export async function getAutoLoginUimAccessToken (params) {
  return axios.post('/user/getAutoLoginUimAccessToken/v1', params)
}

// 获取留资手机号验证码
export async function getAuthMsgCode (params) {
  return axios.post('/user/send/auth/msg/v1', params)
}

// 通过AccessToken、手机号、验证码，进行UIM登录
export async function uimAutoLogin (params) {
  return axios.post('/user/contact/with/auto/login/v1', params)
}

// 帮助文档
export async function getHelpDocument (params) {
  return axios.post('/help/document/get', params)
}

// 企业管理的用户管理列表
export async function getEnterpriseUserList (params) {
  return axios.post('/user/list', params)
}

// 企业管理的新增用户
export async function addEnterpriseUser (params) {
  return axios.post('/user/create', params)
}

// 企业管理的解绑用户
export async function unbindEnterpriseUser (params) {
  return axios.post('/user/unbind', params)
}

// 企业管理的更新用户
export async function updateEnterpriseUser (params) {
  return axios.post('/user/update', params)
}

// 企业管理的设置用户权限
export async function setEnterpriseUserPermission (params) {
  return axios.post('/user/permission', params)
}

// 个人中心获取菜单权限
export async function getUserMenu () {
  return axios.get('/user/menu')
}

// 企业管理的用户管理获取用户设置权限
export async function getUserPermission (params) {
  return axios.get('/user/permission', params)
}
