import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable, of } from 'rxjs'
import { isWeChatBrowser, urlJumpCompatibleMobile } from '@/utils/utils'
import { ContactTypeEnum } from '../model'
import { downloadFile } from '@/modules/common/utils'

/**
 * 默认的url跳转行为
 */
export class JumpToUrlDefaultMiddleware extends Middleware<LeaveContactContext> {
  handle(ctx: LeaveContactContext, _next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    if (ctx.params.key.contactType === ContactTypeEnum.content) {
      // 内容中心走文件下载逻辑
      downloadFile(ctx.params.targetUrl)
    } else {
      // 需要兼容微信
      urlJumpCompatibleMobile(
        encodeURI(
          ctx.params.targetUrl +
          (isWeChatBrowser() ? `&wechatToken=${localStorage.getItem('token')}` : '')
        ),
        '_blank'
      )
    }
    return of(ctx)
  }
}