import { useSyncState } from '@/modules/common'
import { Flex, Pagination as Paging } from 'ant-design-vue'
import { defineComponent } from 'vue'
import { PaginationProps, PaginationEvents } from './model'
import style from './index.module.scss'

export const Pagination = defineComponent<PaginationProps, PaginationEvents>(
  (props, ctx) => {
    const current = useSyncState(
      () => props.pageNum ?? 1,
      (v) => {
        ctx.emit('update:pageNum', v)
      }
    )
    const pageSize = useSyncState(
      () => props.pageSize,
      (v) => {
        if (v) {
          ctx.emit('update:pageSize', v)
        }
      }
    )

    function onChange(page: number, size: number) {
      ctx.emit('change', page, size)
    }
    return () => {
      return (
        <Flex justify='space-between' align='center' class={style.wrapper}>
          <span class='text-DeepBlue8 text-[18px] leading-[22px]'>共{props.total ?? 0}条记录</span>
          <Paging
            current={current.value}
            onUpdate:current={(v) => {
              current.value = v
            }}
            pageSize={pageSize.value}
            onUpdate:pageSize={(v) => {
              pageSize.value = v
            }}
            showSizeChanger={true}
            pageSizeOptions={props.pageSizeOptions}
            total={props.total ?? 0}
            onChange={onChange}
          />
        </Flex>
      )
    }
  },
  {
    props: ['pageNum', 'pageSize', 'total', 'pageSizeOptions'],
  }
)
