import { MD5 } from 'crypto-js'

function paramsHashCode(...args: unknown[]): string {
  const argsStr = JSON.stringify(args)
  return MD5(argsStr).toString()
}

/**
 * 合并异步执行函数
 * @param delayed 延迟执行时间
 */
export function mergeAsyncFn(delayed?: number) {
  return function <T extends (...args: any[]) => Promise<any>>(target: T): T {
    const _answer: Record<string, Promise<any> | undefined> = {}

    return function (...args: any[]) {
      const id = paramsHashCode(...args)
      if (_answer[id]) {
        return _answer[id]
      } else {
        if (delayed) {
          _answer[id] = new Promise((resolve, reject) => {
            setTimeout(
              () =>
                target(...args)
                  .then(resolve)
                  .catch(reject),
              delayed
            )
          })
        } else {
          _answer[id] = target(...args)
        }
        _answer[id]!.finally(() => {
          _answer[id] = undefined
        })
        return _answer[id]
      }
    } as T
  }
}
