export function getLang () {
  // if lang is not set in store, try to get lang from loaclStorage.
  let lang = localStorage.getItem('xLang')
  if (lang) {
    lang = lang.toLowerCase()
    // convert lang to our standard, lang may be set by third part application.
    lang = lang.toLowerCase() === ('zh' || 'zh-cn' || 'cn') ? 'zh' : 'en'
  } else {
    lang = 'zh'
    localStorage.setItem('xLang', lang)
  }
  return lang
}

export function setLang (value) {
  // const store = useStore(status)
  // store.commit('setLang', value)
  localStorage.setItem('xLang', value)
}
