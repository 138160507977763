import { FileInfo } from '@/utils/file'

/**
 * 下载文件
 * @param url
 */
export function downloadFile(url?: string) {
  let a: HTMLAnchorElement | null = document.createElement('a')
  a.href = new FileInfo(url).publicUrl
  a.target = '_blank'
  a.download = new FileInfo(url).name
  a.click()
  a.remove()
  a = null
}
