import { defineComponent, SlotsType } from 'vue'
import { PopoverProps, PopoverEvents } from './model'
import { Popover as AntPopover } from 'ant-design-vue'
import { JSX } from 'vue/jsx-runtime'
import './index.scss'
export const Popover = defineComponent<
  PopoverProps,
  PopoverEvents,
  'Popover',
  SlotsType<{ default?: () => JSX.Element; content?: () => JSX.Element }>
>(
  (props, ctx) => {
    return () => {
      return (
        <AntPopover
          overlayClassName='x-popover'
          overlayInnerStyle={{ backgroundColor: '#092949' }}
          placement={props.placement || 'top'}
          trigger={props.trigger}
        >
          {{
            default: ctx.slots.default,
            content: ctx.slots.content,
          }}
        </AntPopover>
      )
    }
  },
  {
    props: ['placement', 'trigger'],
  }
)
