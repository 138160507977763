import { Module } from 'vuex'
import seoConfig from '@/utils/seo/config/seo-meta-config.json'
import { getLang } from '@/utils/lang'
// 空meta信息
export const EMPTY_META_INFO = {
  title: '西门子Xcelerator',
  meta: [],
  link: [],
}

export interface SEOState {
  seoConfig: {
    [key: string]: {
      zh: {
        title: string
        meta: Array<{ name?: string; property?: string; content: string }>
        link: Array<{ rel: string; href: string }>
      }
      en: {
        title: string
        meta: Array<{ name?: string; property?: string; content: string }>
        link: Array<{ rel: string; href: string }>
      }
    }
  }
  currentLang: string
  currentMetaInfo: any
}

const seoModule: Module<SEOState, any> = {
  namespaced: true,

  state: {
    seoConfig: seoConfig || {},
    currentLang: 'zh',
    currentMetaInfo: null,
  },

  mutations: {
    SET_SEO_CONFIG(state, config) {
      state.seoConfig = config
    },
    SET_LANGUAGE(state, lang) {
      state.currentLang = lang
    },
    SET_CURRENT_META_INFO(state, metaInfo) {
      state.currentMetaInfo = metaInfo
    },
  },

  actions: {
    // 暂不使用 预留
    async initSEOConfig({ commit }) {
      try {
        const config = {}
        commit('SET_SEO_CONFIG', config)
      } catch (error) {
        console.error('Failed to initialize SEO config:', error)
      }
    },
    // 暂不使用 预留
    setLanguage({ commit, dispatch }, lang: string) {
      commit('SET_LANGUAGE', lang)
      dispatch('updateCurrentPageSEO')
    },

    updateCurrentPageSEO({ state, commit, rootState }, path?: string) {
      const currentPath = path || window.location.pathname
      const currentLang = (rootState.lang || getLang() || 'zh') as 'zh' | 'en'
      // 1. 先尝试获取当前路径的配置
      const pathConfig = state.seoConfig[currentPath]
      // 2. 如果没有当前路径配置，尝试使用默认配置
      const defaultConfig = state.seoConfig['default']
      // 3. 使用路径配置或默认配置
      const config = pathConfig || defaultConfig

      if (!config) {
        console.warn(`No SEO config and default config found for path: ${currentPath}`)
        commit('SET_CURRENT_META_INFO', EMPTY_META_INFO)
        return EMPTY_META_INFO
      }

      const langConfig = config[currentLang] || config['zh']

      // 构建 meta 信息，直接使用配置的 meta 和 link
      const metaInfo = {
        title: langConfig.title,
        meta: langConfig.meta.map((meta) => ({
          ...meta,
          vmid: meta.name, // 添加 vmid 用于唯一标识
        })),
        link: langConfig.link,
      }

      commit('SET_CURRENT_META_INFO', metaInfo)
      return metaInfo
    },
  },
}

export default seoModule
