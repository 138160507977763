import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable, of } from 'rxjs'
import { urlJumpCompatibleMobile } from '@/utils/utils'

/**
 * 跳转到目标页面
 */
export class JumpToTargetPageMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return !!ctx.params.needAuth
  }
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    const targetUrl = ctx.params.targetUrl
    if (encodeURI(targetUrl).includes('http')) {
      if (targetUrl.includes('activitycard/index')) {
        urlJumpCompatibleMobile(targetUrl, '_blank')
      } else {
        return next()
      }
    } else {
      if (targetUrl === 'default') {
        ctx.router.push({ name: 'solution-details', query: { id: ctx.params.params.id } })
      } else {
        window.location.href = encodeURI(targetUrl)
      }
    }
    return of(ctx)
  }
}
