import { isPromise } from './future'

/**
 * 异步串行执行
 */
export class AsyncSerialExecute<T = any, S = void> {
  private readonly tasks: ((arg: T) => S | Promise<S>)[] = []

  /**
   * 添加任务
   * @param task
   */
  add(task: (arg: T) => S | Promise<S>) {
    this.tasks.push(task)
  }
  /**
   * 删除任务
   * @param task
   */
  remove(task: (arg: T) => S | Promise<S>) {
    this.tasks.splice(this.tasks.indexOf(task), 1)
  }
  /**
   * 清空执行任务
   */
  clear() {
    this.tasks.length = 0
  }

  /**
   * 执行
   * @param arg
   */
  async execute(arg: T): Promise<S | undefined> {
    let res: S | undefined
    for (const task of this.tasks) {
      const r = task(arg)
      if (isPromise(r)) {
        res = await r
      } else {
        res = r as S
      }
    }
    return res
  }
}

/**
 * 异步流执行
 */
export class AsyncStreamExecute<T = any> {
  private readonly tasks: ((arg: T) => T | Promise<T>)[] = []

  /**
   * 添加任务
   * @param task
   */
  add(task: (arg: T) => T | Promise<T>) {
    this.tasks.push(task)
  }
  /**
   * 删除任务
   * @param task
   */
  remove(task: (arg: T) => T | Promise<T>) {
    this.tasks.splice(this.tasks.indexOf(task), 1)
  }
  /**
   * 清空执行任务
   */
  clear() {
    this.tasks.length = 0
  }

  /**
   * 执行
   * @param arg
   */
  async execute(arg: T): Promise<T> {
    for (const task of this.tasks) {
      let res = task(arg)
      if (isPromise(res)) {
        res = await res
      } else {
        arg = res as T
      }
    }
    return arg
  }
}

/**
 * 异步循环队列
 */
export class AsyncLoopExecute {
  private readonly tasks: (() => void | Promise<void>)[] = []

  /**
   * 添加任务
   * @param task
   */
  add(task: () => void | Promise<void>) {
    this.tasks.push(task)
    this.execute()
  }
  /**
   * 删除任务
   * @param task
   */
  remove(task: () => void | Promise<void>) {
    this.tasks.splice(this.tasks.indexOf(task), 1)
  }
  /**
   * 清空执行任务
   */
  clear() {
    this.tasks.length = 0
  }

  /**
   * 执行
   * @param arg
   */
  private async execute(): Promise<void> {
    while (this.tasks.length) {
      const task = this.tasks.shift()
      if (task) {
        try {
          const r = task()
          if (isPromise(r)) {
            await r
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
