import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable, of } from 'rxjs'
import { gotoUimLogin } from '@/utils/uimLogin'
import store from '@/store'

/**
 * 登录检查中间件
 */
export class LoginCheckMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    // 是否需要检查登录
    return !!ctx.params.needAuth
  }
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    if (!window.sessionStorage.getItem('userInfo')) {
      let strUrl = 'contact-us'
      let strParams = '?returnUrl=' + encodeURIComponent(encodeURIComponent(ctx.params.targetUrl))
      let queryParams = ''
      if (ctx.params.params) {
        for (const param in ctx.params.params) {
          queryParams += '&' + param + '=' + ctx.params.params[param]
        }
      }
      strParams = strParams + encodeURIComponent(queryParams)
      strUrl += encodeURIComponent(strParams)
      gotoUimLogin(strUrl, store.state.lang, { loginTypeForGio: ctx.params.loginTypeForGio })
      return of(ctx)
    }
    return next()
  }
}
