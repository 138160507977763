/**
 * 是否是一个链接
 *
 * @export
 */
export function isUrl(path: string) {
  return /^https?:\/\//g.test(path)
}

/**
 * 提供对象存储图片链接
 * @param {string | undefined} path - 图片路径
 */
export function provideOssImgUrl(path?: string) {
  if (!path) {
    return ''
  }
  if (isUrl(path)) {
    return path
  }
  return urlJoin([process.env.VUE_APP_OSS_BASE_URL, path])
}

/**
 * 拼接URLs
 */
export function urlJoin(...paths: any[]) {
  return paths.reduce<string[]>((l, r) => {
    if (r) {
      if (Array.isArray(r)) {
        return [...l, ...r]
      }
      return [...l, `${r}`]
    }
    return l
  }, []).join('/').replace(/(?<=([^:]))\/\//g, '/')
}

/**
 * 是否CMS文件
 * @param {string} path
 */
export function isCmsFile(path = '') {
  return /cmsfiles\//g.test(path)
}
