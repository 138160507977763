import { Observable, of, mergeMap } from 'rxjs'

export type NextMiddleware<T> = () => Observable<T>

// 定义中间件接口
export abstract class Middleware<T> {
  // @ts-ignore
  where(ctx: T) {
    return true
  }

  abstract handle(ctx: T, next: NextMiddleware<T>): Observable<T>;
}

// 中间件管理类
export class MiddlewareManager<T = Record<string, any>> {
  private middlewares: Middleware<T>[] = []
  constructor(items?: Middleware<T>[]) {
    if (items) {
      this.middlewares = items
    }
  }

  // 添加中间件
  use(middleware: Middleware<T>): this {
    this.middlewares.push(middleware)
    return this
  }

  // 执行中间件
  execute(context: T): Promise<T> {
    const dispatch = (index: number): Observable<T> => {
      if (index === this.middlewares.length) {
        return of(context)
      }
      const middleware = this.middlewares[index]
      return of(context).pipe(
        mergeMap(() => {
          // 执行 where 阶段
          if (middleware.where && !middleware.where(context)) {
            return dispatch(index + 1)
          }

          // 执行 handle 阶段
          return middleware.handle(context, () => dispatch(index + 1))
        })
      )
    }
    return new Promise((resolve, reject) => {
      dispatch(0).subscribe({
        next: (value) => {
          resolve(value)
        },
        error: (error) => {
          reject(error)
        },
      })
    })
  }
}
