import { createRouter, createWebHistory } from 'vue-router'
import { pointPage } from './api/commonApi'
import { getCookies } from '@/utils/utils'
import { checkUserInfo, UIMinfoByAccessToken, UIMinfo } from '@/api/userApi.js'
import store from './store'
import vm from './main'
import { pointWebEvent } from '@/api/commonApi'
import { GIOChannelPageviewEvent, getQRInfo } from '@/utils/gio'
import { getPageTypeData } from './api/productData'
import { gotoUimLogin } from '@/utils/uimLogin'
import { getLang } from '@/utils/lang.js'
import { checkAndRedirectDomain } from '@/utils/domain-redirect'
import contentCenterRoute from './router/contentCenter'

// 添加一个变量判断系统进入标识位，来控制弹窗是否已显示
let isFirstRoute = true

const router = createRouter({
  history: createWebHistory(),
  base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/home',
      name: 'home',
      meta: {
        needGio: true, // 需要gio埋点的导航菜单
      },
      component: () => import('./views/new_home_v2/index'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/home',
      name: 'event-home',
      meta: {
        needGio: true, // 需要gio埋点的导航菜单
      },
      component: () => import('./views/new_home_v2/index'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/topics/:type',
      name: 'topics',
      meta: {
        needGio: true,
      },
      component: () => import('./views/topics/index.vue'),
      props: true,
      // , beforeEnter: requireAuth
    },
    {
      path: '/topicsDetail/:id',
      name: 'topicsDetail',
      meta: {
        needGio: true,
      },
      component: () => import('./views/topics/page/detail/index.vue'),
      props: true,
    },
    {
      path: '/ind-mkt/topics/:code',
      name: 'indTopicsDetail',
      meta: {
        needGio: true,
      },
      component: () => import('./views/topics/page/detail/index.vue'),
      props: true,
    },
    {
      path: '/ind-mkt/vertical-markets/:code',
      name: 'indVerticalMarkets',
      meta: {
        needGio: true,
      },
      component: () => import('./views/topics/page/detail/index.vue'),
      props: true,
    },
    {
      path: '/vertical-markets-detail/:id',
      name: 'verticalMarketsDetail',
      meta: {
        needGio: true,
      },
      component: () => import('./views/topics/page/detail/index.vue'),
      props: true,
    },
    {
      path: '/vertical-markets/:type',
      name: 'vertical-markets',
      meta: {
        needGio: true,
      },
      component: () => import('./views/vertical_markets/index.vue'),
      props: true,
      // , beforeEnter: requireAuth
    },
    {
      path: '/community',
      name: 'community',
      meta: {
        needGio: true,
      },
      component: () => import('./views/community/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/partners',
      name: 'partners',
      meta: {
        needGio: true,
      },
      component: () => import('./views/partnersStar/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/developers',
      name: 'developers',
      meta: {
        needGio: true,
      },
      component: () => import('./views/developers/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/solution-details',
      name: 'solution-details',
      component: () => import('./views/solution_details/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('./views/contact_us/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/api-detail',
      name: 'api-detail',
      component: () => import('./views/api_detail/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/articles-details',
      name: 'articles-details',
      component: () => import('./views/articles_details/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/community-articles',
      name: 'community-articles',
      component: () => import('./views/community_articles/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('./views/contact_us/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/partner-details',
      name: 'partner-details',
      component: () => import('./views/partner_details/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('./views/search/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/study-center',
      name: 'study-center',
      meta: {
        needGio: true,
      },
      component: () => import('./views/study_center/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/study-center-details',
      name: 'study-center-details',
      component: () => import('./views/study_center_details/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/business-platform',
      name: 'business_platform',
      meta: {
        needGio: true,
      },
      component: () => import('./views/business_platform/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/user-center',
      name: 'user_center',
      component: () => import('./views/user_center/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/event-center',
      name: 'event-center',
      component: () => import('./views/event_center/home/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/event-detail',
      name: 'event-detail',
      component: () => import('./views/event_center/detail/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/event-booking',
      name: 'event-booking',
      component: () => import('./views/event_center/booking/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/old-home',
      name: 'old-home',
      component: () => import('./views/Home.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/laws-regulations',
      name: 'laws-regulations',
      component: () => import('./views/law/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/:path(.*)',
      name: 'not-found',
      component: () => import('./views/notFound.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/link/:key',
      name: 'link',
      component: () => import('./views/redirect/index.vue'),
      hidden: true,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/login-redirect/index.vue'),
    },
    // 商城
    {
      path: '/store',
      name: 'store',
      component: () => import('./views/store/index.tsx'),
    },
    // 店铺中心
    {
      path: '/store/shop-center',
      name: 'shop-center',
      component: () => import('./views/store/shop-center.tsx'),
    },
    // 店铺详情
    {
      path: '/store/shop/:id',
      name: 'shop',
      component: () => import('./views/store/shop/index'),
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('./views/store/orderConfirm.vue'),
    },
    {
      path: '/shoppingCart',
      name: 'shoppingCart',
      component: () => import('./views/store/shoppingCart.vue'),
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('./views/store/payment.vue'),
    },
    {
      path: '/live',
      name: 'live',
      component: () => import('./views/live/index.vue'),
    },
    {
      path: '/awardEnterprise',
      name: 'awardEnterprise',
      component: () => import('./views/event_center/awardEnterprise.vue'),
    },
    {
      path: '/wxLogin',
      redirect: '/login?type=wxLogin',
    },
    {
      path: '/independentForm',
      name: 'IndependentForm',
      component: () => import('./views/independentForm/index.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/offering/:id',
      name: 'OfferingDetail',
      component: () => import('./views/offering/index'),
    },
    {
      path: '/customerCases',
      name: 'CustomerCases',
      component: () => import('./views/customerCases/index.vue'),
    },
    {
      path: '/productsAndSolutions',
      name: 'ProductsAndSolutions',
      component: () => import('./views/productsAndSolutions/index.vue'),
    },
    {
      path: '/webview',
      name: 'webview',
      component: () => import('./views/webview/pdf.vue'),
      // , beforeEnter: requireAuth
    },
    {
      path: '/profession-list',
      name: 'professionList',
      component: () => import('./views/profession-list/index'),
    },
    {
      path: '/theme-list',
      name: 'themeList',
      component: () => import('./views/theme-list/index'),
    },
    {
      path: '/sso-authorization',
      name: 'ssoAuthorization',
      component: () => import('./views/sso-authorization/index'),
    },
    ...contentCenterRoute
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

const analyticsRequest = (to, from) => {
  // 只统计页面跳转数据，不统计当前页 query 不同的数据
  // 所以这里只使用了 path, 如果需要统计 query 的，可以使用 to.fullPath
  if (to.path !== from.path) {
    let OS = ''
    const u = navigator.userAgent
    if (u.indexOf('Windows', 0) !== -1) {
      OS = 'windows'
    }
    if (
      navigator.platform === 'Mac68K' ||
      navigator.platform === 'MacPPC' ||
      navigator.platform === 'Macintosh' ||
      navigator.platform === 'MacIntel'
    ) {
      OS = 'mac'
    }
    if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
      OS = 'Android'
    }
    if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      OS = 'iOS'
    }

    const param = {
      cookie: getCookies(),
      guest_name: '',
      guest_browser: navigator.userAgent,
      guest_os: OS,
      is_first_time: '',
      // referrer_host: location.host,
      referrer_url: from.path, // 上一个url
      url: location.origin + to.fullPath, // 当前url全路径
      url_path: to.path, // 路径（去掉http头和域名）
    }
    pointPage(param)
      .then((res) => {
        if (res.code === 200) {
          // console.log(res.data)
        } else {
          console.log('Error Code:', res.code + ':' + res.msg)
        }
      })
      .catch(function (error) {
        console.log('pointPage Error:', error)
      })
  }
}

router.beforeEach(async (to, from, next) => {
  console.log('router to', to)
  // 更新当前页面的 SEO 信息
  store.dispatch('seo/updateCurrentPageSEO', to.path)
  analyticsRequest(to, from)
  // 检查是否已经显示过弹窗
  if (isFirstRoute) {
    // 检查并跳转域名
    checkAndRedirectDomain({})
    // 标记弹窗已显示
    isFirstRoute = false
  }
  if (to.query.id && to.name === 'solution-details' && !to.query.whitePaper) {
    localStorage.setItem('offeringId', to.query.id)
    sessionStorage.setItem('offeringId', to.query.id)
    localStorage.setItem('isSolutePage', true)
  }
  // 兼容扫码，如url为：/contact-us?id=91&pathName=solution-details&source=solution-details?id=91&qrParam=10014&qrLink=aHR0cHM6Ly9kZXYubWFya2V0cGxhY2Uuc2llbWVucy5jb20uY24vbGluay81UHd6WVRCZnVtP3V0bV9zb3VyY2U9MTIzJnV0bV9tZWRpdW09MTIzJnV0bV9jYW1wYWlnbj0xMjMmdXRtX2NvbnRlbnQ9JnV0bV90ZXJtPQ==&accessToken=c4035533cae245ad9eae8b357d38e847
  if (to.query?.source && !to.query.whitePaper) {
    if (to.query.source.indexOf('solution-details') > -1) {
      localStorage.setItem('offeringId', to.query.id)
      sessionStorage.setItem('offeringId', to.query.id)
      localStorage.setItem('isSolutePage', true)
    }
  }
  // 兼容landingPage查看资料未登录状态下点击--》登陆后直接跳转至联系我们页面，导致seesion内没有正确offeringId问题：url：/contact-us?returnUrl=default&pathName=solution-details&id=25&accessToken=d4570f495a3545de9720a05e7ef1bb4b
  if (to.query?.pathName && !to.query.whitePaper) {
    if (to.query.pathName.indexOf('solution-details') > -1) {
      localStorage.setItem('offeringId', to.query.id)
      sessionStorage.setItem('offeringId', to.query.id)
      localStorage.setItem('isSolutePage', true)
    }
  }
  // 获取UIM用户信息
  const accessToken = to.query.accessToken
  const userInfoObj = JSON.parse(window.sessionStorage.getItem('userInfo')) // 用户信息
  // let contact = null
  // if (localStorage.getItem('contact')) {
  //   contact = JSON.parse(localStorage.getItem('contact'))
  // }
  // 计算viewsource
  let viewSource = ''
  const path = to.query.pathName ? `/${to.query.pathName}` : to.path
  if (to.query.id) {
    if (to.fullPath.includes('solution-details')) {
      if (to.query.whitePaper) {
        // 如果为白皮书，不记录offering信息
        viewSource = path + '?id='
      } else {
        viewSource = path + `?id=${sessionStorage.getItem('offeringId') || to.query.id}`
      }
    } else {
      viewSource = path + `?id=${to.query.id}`
    }
  } else if (to.query.eventId) {
    viewSource = path + `?eventId=${to.query.eventId}`
  } else {
    viewSource = path
  }
  const gdpDataObj = {
    anonymousId: window.gdp('getDeviceId') || '',
    viewSource,
    // viewSource: (to.query.pathName ? `/${to.query.pathName}` : to.path) + (to.query.id ? `?id=${to.fullPath.includes('solution-details') ? sessionStorage.getItem('offeringId') : to.query.id}` : ''),
    qrLink: getQRInfo(to).QRcode, // 扫二维码
    isWhitePaper: !!to.query.whitePaper,
    whitePaperId: to.query.whitePaper ? to.query.id : '',
  } // 埋点事件需要的数据
  const code = to.query.code
  const isContactById =
    to.query.id && to.path.includes('contact') && !location.search.includes('pathName')
  // TO-DO
  const userInfoForApi = await getUserInfo(to)
  if (code) {
    // UIM v1
    if (userInfoObj || userInfoForApi) {
      if (userInfoObj?.code === code || userInfoForApi) {
        store.commit('setLoginStatus', true)
      }
    } else {
      if (isContactById) {
        gdpDataObj.viewSource = to.path + `?id=${to.query.id}`
      }
      await getUIMV1UserInfo(to, code, gdpDataObj)
    }
  } else if (accessToken) {
    // UIM v2
    // session内存在用户信息时
    if (userInfoObj || userInfoForApi) {
      if (userInfoObj?.code === accessToken || userInfoForApi) {
        store.commit('setLoginStatus', true)
      }
    } else {
      await getUIMUserInfo(to, accessToken, gdpDataObj)
    }
  }
  if (store.state.routeNameData.length === 0) {
    await getRouteNameData() // 获取后端page type信息
  }
  next()
})

router.afterEach((to, form) => {
  // 扫码进入的/link不在router内拦截
  to.name !== 'link' &&
    setTimeout(() => {
      GIOChannelPageviewEvent(to)
    }, 100)
})
/**
 * 获取UIM内用户信息 v1
 * @param {*} to 路由信息
 * @param {*} code 登录code
 * @param {*} gdpDataObj gdp埋点数据
 */
const getUIMV1UserInfo = async (to, code, gdpDataObj) => {
  await UIMinfo({ code, ...gdpDataObj }).then((res) => {
    if (res.code === 200) {
      store.commit('setLoginStatus', true)
      const data = res.data
      data.code = code
      vm.config.globalProperties.resetSetItem('userInfo', JSON.stringify(data))
      if (res.data.userToken) {
        localStorage.setItem('token', res.data.userToken)
        document.cookie = 'Token' + '=' + res.data.userToken + '; '
      }
      if (res.data.firstSignFlag === '0') {
        setEvent('EVT0005')
      } else if (res.data.firstSignFlag === '1') {
        setEvent('EVT0007')
      }
    } else {
      window.sessionStorage.removeItem('userInfo')
    }
  })
}
/**
 * 获取UIM内用户信息 v2
 * @param {*} to 路由信息
 * @param {*} accessToken token
 * @param {*} gdpDataObj gdp埋点数据
 */
export const getUIMUserInfo = async (to, accessToken, gdpDataObj) => {
  // 没有用户信息需要调用获取UIM用户信息接口
  await UIMinfoByAccessToken({ accessToken, ...gdpDataObj }).then((res) => {
    // const res = {"msg":"SUCCESS","code":200,"data":{"id":1610171879122883104,"gid":"c57f6eb5bda94db0bcebde011f4b1807","internetAddress":null,"mobilePhone":"13521696276","userType":"2","userName":null,"companyName":null,"firstSignFlag":"0","userFrom":"uim","userToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJtYXJrZXRwbGFjZS5zaWVtZW5zLmNvbS5jbiJ9.z23W1pYXxbTES1X_VCLWS6LNa2ap4gMr_sNYcG3tN7k","completeFlag":true,"wechatOpenid":"oQ44E6roQsYPG2_7xFKpwsSF-2-A"}}
    if (res.code === 200) {
      store.commit('setLoginStatus', true)
      const data = res.data
      data.code = accessToken
      data.accessToken = accessToken
      // 将用户信息存储在seesion内
      vm.config.globalProperties.resetSetItem('userInfo', JSON.stringify(data))
      if (res.data.userToken) {
        localStorage.setItem('token', res.data.userToken)
        document.cookie = 'Token' + '=' + res.data.userToken + '; '
      }
      // 系统埋点
      if (res.data.firstSignFlag === '0') {
        setEvent('EVT0005', res, to)
      } else if (res.data.firstSignFlag === '1') {
        setEvent('EVT0007', res, to)
      }
      // GIO上报userId
      window.gdp('setUserId', res.data.userId)
    } else {
      window.sessionStorage.removeItem('userInfo')
    }
  })
}
// 获取用户信息
const getUserInfo = async (to) => {
  return new Promise((resolve, reject) => {
    checkUserInfo()
      .then(async (res) => {
        if (res.code === 200) {
          store.commit('setLoginStatus', true)
          vm.config.globalProperties.resetSetItem('userInfo', JSON.stringify(res.data))
          resolve(true)
        } else if (res.code === 402) {
          if (sessionStorage.getItem('userInfo')) {
            localStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            store.commit('setLoginStatus', false)
            if (!to.fullPath.includes('login')) {
              gotoUimLogin(to.fullPath.slice(1), getLang())
            }
          }
          resolve(false)
        } else {
          store.commit('setLoginStatus', false)
          window.sessionStorage.removeItem('userInfo')
          resolve(false)
        }
      })
      .catch(function (error) {
        console.log('get partner list error:', error)
        reject(error)
      })
  })
}
/**
 * 系统埋点
 * @param {*} eventCode code
 * @param {*} resData uim接口返回数据
 * @param {*} to route to
 */
const setEvent = (eventCode, resData, to) => {
  const detailObj = {}
  if (localStorage.getItem('resourceUrl')) {
    detailObj.resourceUrl = localStorage.getItem('resourceUrl')
  }
  if (localStorage.getItem('resourceName')) {
    detailObj.resourceName = localStorage.getItem('resourceName')
  }
  if (localStorage.getItem('offeringId')) {
    detailObj.offeringId = localStorage.getItem('offeringId')
  }
  if (localStorage.getItem('offeringName')) {
    detailObj.offeringName = localStorage.getItem('offeringName')
  }
  if (localStorage.getItem('resourceName')) {
    detailObj.resourceName = localStorage.getItem('resourceName')
  }
  if (localStorage.getItem('resourceName')) {
    detailObj.resourceName = localStorage.getItem('resourceName')
  }
  if (localStorage.getItem('sourceUrl')) {
    detailObj.sourceUrl = localStorage.getItem('sourceUrl')
  }
  if (localStorage.getItem('qrParam')) {
    detailObj.qrParam = localStorage.getItem('qrParam')
  }
  if (resData?.unionId) {
    detailObj.unionId = resData.unionId
  }
  detailObj.targetUrl = localStorage.getItem('targetUrl')
    ? localStorage.getItem('targetUrl')
    : to?.fullPath
  let OS = ''
  const u = navigator.userAgent
  if (u.indexOf('Windows', 0) !== -1) {
    OS = 'windows'
  }
  if (
    navigator.platform === 'Mac68K' ||
    navigator.platform === 'MacPPC' ||
    navigator.platform === 'Macintosh' ||
    navigator.platform === 'MacIntel'
  ) {
    OS = 'mac'
  }
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    OS = 'Android'
  }
  if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    OS = 'iOS'
  }
  if (u.indexOf('MicroMessenger') !== -1) {
    detailObj.weChatTag = true
  } else {
    detailObj.weChatTag = false
  }
  const detailJson = JSON.stringify(detailObj)
  const params = {
    cookie: getCookies(),
    event_code: eventCode,
    event_detail: detailJson,
    guest_browser: u,
    guest_os: OS,
    token: localStorage.getItem('token'),
    title: localStorage.getItem('title'),
    url: localStorage.getItem('sourceUrl'),
    url_path: localStorage.getItem('path'),
  }
  pointWebEvent(params)
    .then((res) => {
      if (res.code === 200) {
        localStorage.removeItem('resourceUrl')
        localStorage.removeItem('resourceName')
        localStorage.removeItem('offeringId')
        localStorage.removeItem('offeringName')
        localStorage.removeItem('sourceUrl')
        localStorage.removeItem('targetUrl')
        localStorage.removeItem('title')
        localStorage.removeItem('path')
        localStorage.removeItem('qrParam')
      } else {
        console.log('Error Code:', res.code + ':' + res.msg)
      }
    })
    .catch(function (error) {
      console.log('pointPage Error:', error)
    })
}

const getRouteNameData = async () => {
  await getPageTypeData({ page: 1, pageSize: 1000 })
    .then((res) => {
      if (res.code === 200) {
        store.commit('setRouteNameData', res.data?.items || [])
      }
    })
    .catch((err) => {
      console.log('getPageTypeData err: ', err)
    })
}
export default router
