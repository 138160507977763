import { defineComponent } from 'vue'
import { IconFont } from '..'
import style from './index.module.scss'
import './fix.scss'
import { TextEllipsisTooltip } from '@/modules/uiKit'

export interface DropdownMenuItem {
  value: string | number
  text: string
  onClick: () => void
}

/**
 * 下拉按钮
 */
export const DropdownButton = defineComponent<{
  defaultTitle?: string
  className?: string
  items: DropdownMenuItem[]
}>(
  (props) => {
    return () => {
      const title = props.defaultTitle ?? ''

      return (
        <div class={`${style.wrapper} ${props.className ?? ''}`}>
          <div
            class={style.btn}
            style={props.items.length > 1 ? { paddingRight: '16px' } : {}}
            onClick={() => {
              props.items?.length === 1 && props.items?.[0]?.onClick()
            }}
          >
            <TextEllipsisTooltip title={title || ''} line={1} class='max-w-[210px] text-[18px]'>
              { title }
            </TextEllipsisTooltip>
            {props.items.length > 1 ? (
              <IconFont style={{ paddingLeft: '8px' }} name={'icon-Arrowhead-down'} size='20px' />
            ) : undefined}
          </div>
          {props.items.length > 1 && (
            <div class={`${style.options}`}>
              {props.items.map((e) => (
                <div class={style.option} onClick={e.onClick}>
                  {e.text}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    }
  },
  {
    props: ['defaultTitle', 'className', 'items'],
  }
)
