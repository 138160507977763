import { ContactAuthStatusEnum } from '@/modules/common/constant'
import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import store from '@/store'
import { urlJumpCompatibleMobile } from '@/utils/utils'
import { Observable, of } from 'rxjs'
import { LeaveContactContext } from './context'
import { marketplaceUserUserShareAccessTokenV1Post } from '@/api/marketplaceUserApi'

/**
 * 处理demo跳转
 */
export class JumpToDemoMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return !!ctx.params.params.demoId && ctx.params.params.isEmpower === 'true'
  }
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    if (ctx.userContactInfo?.authStatus === ContactAuthStatusEnum.approval) {
      // 下载申请通过
      if (ctx.appId) {
        // 处理sso重定向
        return next()
      } else {
        // 展示demo下载相关信息弹窗
        marketplaceUserUserShareAccessTokenV1Post({ productId: ctx.params.solutionId }).then((res) => {
          const attrVal = ctx.params.targetUrl.includes('?') ? '&' : '?'
          store.commit('setDemoStatus', {
            show: true,
            type: 1,
            accountInfo: ctx.params.params?.showAccount
              ? {
                user: decodeURIComponent(decodeURIComponent(ctx.params.params.demoUser)),
                pwd: decodeURIComponent(decodeURIComponent(ctx.params.params.demoPwd)),
              }
              : null,
            fn: () => {
              urlJumpCompatibleMobile(
                encodeURI(
                  ctx.params.targetUrl + attrVal + 'accessToken=' + (res?.data?.accessToken || '')
                ),
                '_blank'
              )
            },
          })
        })
      }
    } else if (ctx.userContactInfo?.authStatus === ContactAuthStatusEnum.default) {
      // 下载申请
      store.commit('setDemoStatus', {
        show: true,
        type: 0,
        email: decodeURIComponent(decodeURIComponent(ctx.params.params.email)),
      })
    } else if (ctx.userContactInfo?.authStatus === ContactAuthStatusEnum.rejection) {
      // 下载申被拒绝
      store.commit('setDemoStatus', {
        show: true,
        type: 2,
        email: decodeURIComponent(decodeURIComponent(ctx.params.params.email)),
        reject: ctx.userContactInfo?.demoApplyRejectionReason,
      })
    }
    return of(ctx)
  }
}