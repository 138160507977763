import { UploadApi } from '@/api/upload'
import { FileInfo } from '@/utils/file'
import { urlJoin } from '@/utils/url'

/**
 * 文件上传
 * @returns
 */
export function useUploadFile() {
  const uploadApi = new UploadApi()
  return {
    /**
     * 上传私有文件
     * @param file
     * @returns
     */
    async uploadPrivateFile(file: File, dirPath?: string) {
      const policyRes = await uploadApi.getPrivatePolicy()
      policyRes.dir = urlJoin(policyRes.dir, 'private', dirPath)
      const res = await uploadApi.uploadFile(file, policyRes)
      return new FileInfo(res).privateUrl
    },
    /**
     * 上传公共文件
     * @param file
     * @returns
     */
    async uploadPublicFile(file: File, dirPath?: string) {
      const policyRes = await uploadApi.genPublicPolicy()
      policyRes.dir = urlJoin(policyRes.dir, 'public', dirPath)
      const res = await uploadApi.uploadFile(file, policyRes)
      return new FileInfo(res).publicUrl
    },
  }
}
