import { defineComponent, ref, watch } from 'vue'
import { RadioGroupProps, RadioGroupEvents } from './model'
import style from './index.module.scss'
import { Flex } from 'ant-design-vue'
export const RadioGroup = defineComponent<RadioGroupProps, RadioGroupEvents>(
  (props, ctx) => {
    const typeValue = ref(props.value || props.options[0]?.value)
    watch(
      () => props.value,
      (val) => {
        typeValue.value = val || ''
      }
    )
    function buildItem(label: string, value: string) {
      return (
        <div
          class={[style.item, typeValue.value === value ? style.active : '']}
          onClick={() => {
            typeValue.value = value
            ctx.emit('update:value', value)
            ctx.emit('change', value)
          }}
        >
          {label}
        </div>
      )
    }
    return () => {
      return (
        <Flex class={style.wrapper}>
          {props.options.map((item) => buildItem(item.label, item.value))}
        </Flex>
      )
    }
  },
  {
    props: ['options', 'value'],
  }
)
