import awardEnterprise from '@/assets/json/awardEnterprise/zh'
import region from '@/assets/json/city'
import content from '@/assets/json/content'
import country from '@/assets/json/country'
import eventSuccessCase from '@/assets/json/eventSuccessCase/zh'
import languageSource from '@/assets/json/language'
import positionList from '@/assets/json/position'
import storeOld from '@/assets/json/store/storeZh'
import menu from '../../assets/json/menu'
import partnerCategory from '../../assets/json/partnerCategory'
import resource from '../../components/pageFooter/resource'
import common from '../json/common.json'
import domainRedirect from '../json/domain-redirect.json'
import ecUserManage from '../json/ec-user-manage.json'
import store from '../json/store.json'
import zhConfig from '../json/zh'

export default {
  // 域名下线弹窗提示
  domainRedirect: domainRedirect,
  // 公共
  common: common,
  // 商城
  store: store,
  // 用户管理
  ecUserManage: ecUserManage,
  currency: content.currency,
  language: content.language,
  home: content.home,
  solutionDetail: content.solutionDetail,
  topics: content.topics,
  Cybersecurity: content.Cybersecurity,
  Efficiency: content.Efficiency,
  Experience: content.Experience,
  Flexibility: content.Flexibility,
  Resilience: content.Resilience,
  Sustainability: content.Sustainability,
  SupplierSupport: content.SupplierSupport,
  MachineTools: content.MachineTools,
  PA1: content.PA1,
  Semiconductors: content.Semiconductors,
  verticalMarkets: content.verticalMarkets,
  DataCenter: content.DataCenter,
  CommercialBuildings: content.CommercialBuildings,
  HigherEducation: content.HigherEducation,
  FoodAndBeverage: content.FoodAndBeverage,
  Healthcare: content.Healthcare,
  PharmaceuticalLifeScienceIndustries: content.PharmaceuticalLifeScienceIndustries,
  PowerUtilities: content.PowerUtilities,
  Transportation: content.Transportation,
  Intralogistics: content.Intralogistics,
  partners: content.partners,
  developers: content.developers,
  apiDetail: content.apiDetail,
  studyCenter: content.studyCenter,
  footer: resource.cn_zh,
  menu: menu.cn_zh.menuData,
  partnerCategory: partnerCategory.partnerCategory_zh,
  partnerDetail: content.partnerDetail,
  province: region.province_list,
  city: region.city_list,
  county: region.county_list,
  country: country.country_list,
  languageSource: languageSource.language_list,
  userCenter: content.userCenter,
  eventCenterDetail: content.eventCenterDetail,
  eventBooking: content.eventBooking,
  positionList: positionList.position_list,
  cookie: content.cookie,
  message: content.message,
  messageConfig: content.messageConfig,
  SiGreenLandingPage: content.SiGreenLandingPage,
  '1847LandingPage': content['1847LandingPage'],
  ECXLandingPage: content.ECXLandingPage,
  OneBCLandingPage: content.OneBCLandingPage,
  newHome: content.newHome,
  newLandingPage: content.newLandingPage,
  DigitalTalent: content.DigitalTalent,
  Battery: content.Battery,
  newPartnerPage: content.newPartnerPage,
  storeProductList: storeOld.productList,
  storeShoppingCart: storeOld.shoppingCart,
  searchPlaceholder:storeOld.searchPlaceholder,
  sortPlaceholder:storeOld.sortPlaceholder,
  orderConfirm: storeOld.orderConfirm,
  eventSuccessCase: eventSuccessCase,
  awardEnterprise: awardEnterprise,
  Automobile: content.Automobile,
  selfFlagTag:storeOld.selfFlagTag,
  unSelfFlagTag:storeOld.unSelfFlagTag,
  sortType:storeOld.sortType,
  /**
   * 中文配置
   */
  ...zhConfig,
}
