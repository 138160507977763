import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { Observable, of } from 'rxjs'
import { getOnceCode } from '@/api/userApi'
import { urlJumpCompatibleMobile } from '@/utils/utils'

/**
 * 问卷调查跳转逻辑
 */
export class WJxMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return ctx.params.targetUrl.includes('wjx.com')
  }

  handle(ctx: LeaveContactContext, _next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    getOnceCode({ token: localStorage.getItem('token') }).then((res: any) => {
      if (res.code === 200) {
        urlJumpCompatibleMobile(
          `${process.env.VUE_APP_WJX_BASE_URL}${process.env.VUE_APP_WJX_PATH}&code=${res.data.code}`,
          '_blank'
        )
      } else {
        alert(res.msg)
      }
    })
    return of(ctx)
  }
}