/**
 * 通过数组实现的栈
 */
export class Stack<T> {
  constructor(private items: T[] = []) { }
  /**
   * 添加元素到栈顶
   * @param item 
   */
  push(item: T) {
    this.items.push(item)
  }
  /**
   * 移除并返回栈顶元素
   */
  pop() {
    return this.items.pop()
  }
  /**
   * 返回栈顶元素
   */
  peek() {
    if (!this.isEmpty())
      return this.items[this.items.length - 1]
  }
  /**
   * 是否为空
   */
  isEmpty() {
    return this.items.length === 0
  }
  /**
   * 清空栈
   */
  clear() {
    this.items.length = 0
  }
  /**
   * 栈内元素个数
   */
  size() {
    return this.items.length
  }
}
