export type Dispose = (() => void) | [string, () => void]

export interface IDisposeManager {
  add(dispose?: Dispose | Dispose[]): void
  execute(scope?: string): void
}

export class DisposeManager implements IDisposeManager {
  private items: Dispose[] = []
  add(dispose?: Dispose | Dispose[]) {
    if (dispose) {
      if (Array.isArray(dispose)) {
        if (typeof dispose[0] === 'string') {
          this.items.push(dispose as [string, () => void])
        } else {
          Array.prototype.push.call(this.items, dispose)
        }
      } else {
        this.items.push(dispose)
      }
    }
    return this
  }
  /**
   * 执行清理逻辑
   * @param scope 作用域,为空触发所有销毁函数
   */
  execute(scope?: string) {
    const executeFn = (fn: Dispose) => {
      if (typeof fn === 'function') {
        fn()
      } else if (Array.isArray(fn) && typeof fn[1] === 'function') {
        fn[1]()
      }
    }
    const newItems: Dispose[] = []
    while (this.items.length) {
      const fn = this.items.pop()
      if (fn) {
        if (scope) {
          if (Array.isArray(fn) && fn[0] === scope) {
            executeFn(fn)
          } else {
            newItems.push(fn)
          }
        } else {
          executeFn(fn)
        }
      }
    }
    if (newItems.length) {
      this.items = newItems
    }
  }
}
