import { useSyncState } from '@/modules/common'
import { computed, defineComponent, SlotsType, StyleValue } from 'vue'
import { JSX } from 'vue/jsx-runtime'
import style from './index.module.scss'
import { CheckboxEvents, CheckboxProps } from './model'

/**
 * 复选框
 */
export const Checkbox = defineComponent<
  CheckboxProps,
  CheckboxEvents,
  'Checkbox',
  SlotsType<{
    default?: () => JSX.Element
  }>
>(
  (props, ctx) => {
    const checked = useSyncState(
      () => props.checked,
      (v) => {
        ctx.emit('update:checked', !!v)
        ctx.emit('check', !!v)
      }
    )

    /**
     * 样式
     */
    const wrapperStylesProvider = computed<StyleValue>(() => {
      return {
        justifyContent: props.justify,
      }
    })

    function onClick() {
      checked.value = !checked.value
    }

    function buildBody() {
      if (checked.value) {
        return <img class={style.box} src={require('@/assets/svg/box-check.svg')} />
      }
      if (props.halfChecked) {
        return <img class={style.box} src={require('@/assets/svg/box-half-check.svg')} />
      }
      return <div class={[style.box, style.default]}></div>
    }

    return () => {
      return (
        <div class={style.wrapper} style={wrapperStylesProvider.value} onClick={onClick}>
          {buildBody()}
          {ctx.slots.default?.()}
        </div>
      )
    }
  },
  {
    props: ['checked', 'halfChecked', 'justify'],
  }
)
