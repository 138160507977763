/**
 * 产品分类
 */
export enum ProductCategoriesEnum {
  /**
   * 行业
   */
  VERTICALS_MARKETS = 'Verticals / Markets',
  /**
   * 主题
   */
  TOPICS = 'Topics'
}

/**
 * 内容中心-内容类型
 */
export enum CenterType {
  all = 'all',
  article = 'article',
  video = 'video',
  file = 'file',
}

/**
 * 联系我们留资-认证状态
 */
export enum ContactAuthStatusEnum {
  /**
   * 待提交申请
   */
  default = '0',
  /**
   * 审批通过
   */
  approval = '1',
  /**
   * 驳回
   */
  rejection = '2'
}
/*
 * 下单付款方式
 */
export enum PaymentTypeEnum {
  /**
   * 一次性付款
   */
  onetime = 'onetime',
  /**
   * 分阶段付款
   */
  stage = 'stage'
}
