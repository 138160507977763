import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "msgWrap" }
const _hoisted_2 = { class: "msg" }

import { IconFont } from '@/modules/uiKit'

type Event = { (e: 'close'): void }


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorMessage',
  props: {
    msg: { default: '' },
    icon: { default: 'icon-Error' },
    showClose: { type: Boolean, default: true }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit

const handleClose = () => {
  emits('close')
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(IconFont), {
      name: _ctx.icon,
      size: "16px",
      style: {"margin-right":"8px"}
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.msg), 1),
    (_ctx.showClose)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "closeBtn",
          onClick: handleClose
        }, [
          _createVNode(_unref(IconFont), {
            name: "icon-Close-big",
            size: "16px",
            style: {"margin-left":"8px","color":"#9999a9"}
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})