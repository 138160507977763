
import { mergeAsyncFn } from '@/utils/decorator'
import request from '@/utils/request.js'


import type {
  ActivityRegistrationVO,
  PermissionCreateVO,
  PermissionStatusVO,
  PermissionUpdateVO,
  R,
  ResponseEntity,
  RoleCreateVO,
  RolePageQueryVO,
  RolePermissionBindVO,
  RoleUpdateVO,
  RoleVO,
  SellerQueryVo,
  SellerStatusVo,
  SellerUserBindRoleVo,
  SellerUserCreateVo,
  SellerUserListVo,
  SellerUserUpdateVo
} from '@/models/mkpInternalCms'


import type {
  AbsIdCarOcrResponse,
  AppCreateVO,
  AppVO,
  AuthInfoSearchRequest,
  AuthOfIdCardOcrRequest,
  AuthOfMobileVerifyRequest,
  AuthOfMobileVerifyResponse,
  BISRequest,
  BISResponse,
  BangZhuWenDangDeQingQiuTi,
  BangZhuWenDangDeXiangYingTi,
  ComplianceInspectionCheckVO,
  ContactInfo,
  ContactWithAutoLoginRequest,
  DuanXinCanShu,
  EmailTemplate,
  EnterpriseAuthenticationUserCallbackVO,
  FileDownloadEmailRequest,
  GengXinapp,
  GetContactUsReq,
  GetUimAccessTokenRequest,
  HuoDongChaXunTiaoJian,
  HuoQuYongHuLiuZiRequest,
  HuoQuYongHuSuoShuQiYeDeQingQiuTi,
  NotificationConfigVO,
  ProcessDefinitionDeleteVO,
  ProcessDefinitionHistoryRespVO,
  ProcessDefinitionPageVO,
  ProcessDefinitionRespVO,
  ProcessDefinitionSaveVO,
  ProcessDefinitionUsageScopeRespVO,
  ProcessInstanceFrontPageVO,
  ProcessInstanceRespVO,
  ProcessInstanceSaveVO,
  ProcessNodeSaveVO,
  ProcessResultVO,
  ProcessTaskClaimReqVO,
  ProcessTaskPageVO,
  ProcessTaskRespVO,
  ProcessTaskResultReqVO,
  ProcessTaskUpdateAssigneeReqVO,
  RolePermissionDuiXiang,
  RolePermissionDuiXiang0,
  SapCustomerSearchVO,
  SapCustomerVO,
  SellerTenantSimpleInfoDTO,
  SellerUserInfoDTO,
  SellerUserSimpleInfoDTO,
  ShareAccessTokenModel,
  TaskResultReqVO,
  TenantInfoDTO,
  TenantNameChangeReq,
  TenantQueryVo,
  TenantStageChangeReq,
  TenantStatusChangeReq,
  ThirdPartyResponseBase,
  UIMAccessToken,
  UimAccessTokenLoginRequest,
  UiminfoR,
  UiminfoRequestBody,
  UserActivityCancelReq,
  UserAuthCodeRequest,
  UserCancellationRequest,
  UserCollection,
  UserCollectionCancelRequest,
  UserCollectionDeleteRequest,
  UserCollectionGetDetailRequest,
  UserCollectionGetDetailResponse,
  UserCollectionGetListResponse,
  UserCreateVo,
  UserExistsResponse,
  UserHistory,
  UserHistoryDeleteRequest,
  UserHistoryGetDetailRequest,
  UserHistoryGetDetailResponse,
  UserHistoryGetListResponse,
  UserImportRequest,
  UserInfoCodeRequest,
  UserListQueryVo,
  UserNotificationVO,
  UserPermissionVo,
  UserProfile,
  UserProfileInfoRequest,
  UserProfileSearchVO,
  UserProfileVO,
  UserRoleBindVO,
  UserRoleDuiXiang,
  UserRoleDuiXiang0,
  UserRoleDuiXiang1,
  UserRoleQueryVO,
  UserSearchHistoryListResponse,
  UserSubscriptionVO,
  UserTenantBindVo,
  UserTenantInfoItem,
  UserTenantRespVo,
  UserTenantSearchVo,
  UserUnBindVo,
  UserUpdateVo,
  UserValidateRequest,
  WoDeDingYueCaoZuoQingQiu,
  WoDeDingYueChaXunTiaoJian,
  WorkOrderDetailVO,
  WorkOrderRejectReqVO,
  XiaoXiChaXunTiaoJian
} from '@/models/marketplaceUser'


import type {
  ComplianceInspectionQueryVO,
  ComplianceInspectionResultVO,
  Page,
  PageResult
} from '@/models/product'


import type {
  ProductVO,
  UserActivityVO
} from '@/models/marketplaceProduct'


/**
 * 下载App
 *
 */
export const marketplaceUserAppStoreDownloadGet = mergeAsyncFn()((params: {

  /**
   * key
   */
  key: string


  /**
   * token
   */
  token: string

}) => {
  return request.get<void>('/marketplace-user/app/store/download', { params }) as unknown as Promise<void>
})


/**
 * 是否在白名单中
 *
 */
export const marketplaceUserAppStoreIsInWhitelistGet = mergeAsyncFn()(() => {
  return request.get<R<boolean>>('/marketplace-user/app/store/is/in/whitelist',) as unknown as Promise<R<boolean>>
})


/**
 * 审批流类型枚举
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionApprovalObjectEnumGet = mergeAsyncFn()(() => {
  return request.get<R<Record<string, string>[]>>('/marketplace-user/approvalProcess/process-definition/approvalObjectEnum',) as unknown as Promise<R<Record<string, string>[]>>
})


/**
 * 审批流程是否可以禁用
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionCanDisableGet = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.get<R<boolean>>('/marketplace-user/approvalProcess/process-definition/can-disable', { params }) as unknown as Promise<R<boolean>>
})


/**
 * 新建审批流程
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionCreatePost = mergeAsyncFn()((data?: ProcessDefinitionSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-definition/create', data) as unknown as Promise<R<string>>
})


/**
 * 删除审批流程
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionDeletePost = mergeAsyncFn()((data?: ProcessDefinitionDeleteVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-definition/delete', data) as unknown as Promise<R<boolean>>
})


/**
 * 审批流程详情
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionDetailGet = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.get<R<ProcessDefinitionRespVO>>('/marketplace-user/approvalProcess/process-definition/detail', { params }) as unknown as Promise<R<ProcessDefinitionRespVO>>
})


/**
 * 审批流程禁用
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionDisableGet = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.get<R<boolean>>('/marketplace-user/approvalProcess/process-definition/disable', { params }) as unknown as Promise<R<boolean>>
})


/**
 * 审批历史
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionHistoryGet = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.get<R<ProcessDefinitionHistoryRespVO[]>>('/marketplace-user/approvalProcess/process-definition/history', { params }) as unknown as Promise<R<ProcessDefinitionHistoryRespVO[]>>
})


/**
 * 审批流程分页
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionPagePost = mergeAsyncFn()((data?: ProcessDefinitionPageVO) => {
  return request.post<R<PageResult<ProcessDefinitionRespVO>>>('/marketplace-user/approvalProcess/process-definition/page', data) as unknown as Promise<R<PageResult<ProcessDefinitionRespVO>>>
})


/**
 * 审批流程启用
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionStartGet = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.get<R<boolean>>('/marketplace-user/approvalProcess/process-definition/start', { params }) as unknown as Promise<R<boolean>>
})


/**
 * 更新审批流程
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionUpdatePost = mergeAsyncFn()((data?: ProcessDefinitionSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-definition/update', data) as unknown as Promise<R<string>>
})


/**
 * 审批流程适用范围
 *
 */
export const marketplaceUserApprovalProcessProcessDefinitionUsageScopesGet = mergeAsyncFn()((params: {

  /**
   * approvalObjectType
   */
  approvalObjectType: string

}) => {
  return request.get<R<ProcessDefinitionUsageScopeRespVO[]>>('/marketplace-user/approvalProcess/process-definition/usageScopes', { params }) as unknown as Promise<R<ProcessDefinitionUsageScopeRespVO[]>>
})


/**
 * 全部审批单分页
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceAllPost = mergeAsyncFn()((data?: ProcessInstanceFrontPageVO) => {
  return request.post<R<PageResult<ProcessInstanceRespVO>>>('/marketplace-user/approvalProcess/process-instance/all', data) as unknown as Promise<R<PageResult<ProcessInstanceRespVO>>>
})


/**
 * 取消流程实例
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceCancelDelete = mergeAsyncFn()((params: {

  /**
   * processInstanceId
   */
  processInstanceId: string

}) => {
  return request.delete<R<boolean>>(`/marketplace-user/approvalProcess/process-instance/cancel?processInstanceId=${params.processInstanceId}`, { params }) as unknown as Promise<R<boolean>>
})


/**
 * 新建审批流程单
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceCreatePost = mergeAsyncFn()((data?: ProcessInstanceSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-instance/create', data) as unknown as Promise<R<string>>
})


/**
 * 删除审批流程单
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceDeleteDelete = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.delete<R<string>>(`/marketplace-user/approvalProcess/process-instance/delete?processId=${params.processId}`, { params }) as unknown as Promise<R<string>>
})


/**
 * 审批流程单详情
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceDetailGet = mergeAsyncFn()((params: {

  /**
   * workOrderId
   */
  workOrderId: string

}) => {
  return request.get<R<WorkOrderDetailVO>>('/marketplace-user/approvalProcess/process-instance/detail', { params }) as unknown as Promise<R<WorkOrderDetailVO>>
})


/**
 * 我的提交分页
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceMySubmitPagePost = mergeAsyncFn()((data?: ProcessInstanceFrontPageVO) => {
  return request.post<R<PageResult<ProcessInstanceRespVO>>>('/marketplace-user/approvalProcess/process-instance/mySubmit/page', data) as unknown as Promise<R<PageResult<ProcessInstanceRespVO>>>
})


/**
 * 我的审批分页
 *
 */
export const marketplaceUserApprovalProcessProcessInstancePagePost = mergeAsyncFn()((data?: ProcessInstanceFrontPageVO) => {
  return request.post<R<PageResult<ProcessInstanceRespVO>>>('/marketplace-user/approvalProcess/process-instance/page', data) as unknown as Promise<R<PageResult<ProcessInstanceRespVO>>>
})


/**
 * 一键驳回流程实例
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceRejectPost = mergeAsyncFn()((data?: WorkOrderRejectReqVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-instance/reject', data) as unknown as Promise<R<boolean>>
})


/**
 * syncHistory
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceSyncHistoryGet = mergeAsyncFn()((params?: {

  /**
   * id
   */
  id?: string


  /**
   * syncToken
   */
  syncToken?: string

}) => {
  return request.get<R<string>>('/marketplace-user/approvalProcess/process-instance/syncHistory', { params }) as unknown as Promise<R<string>>
})


/**
 * 更新审批流程单
 *
 */
export const marketplaceUserApprovalProcessProcessInstanceUpdatePost = mergeAsyncFn()((data?: ProcessInstanceSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-instance/update', data) as unknown as Promise<R<string>>
})


/**
 * 新建流程节点
 *
 */
export const marketplaceUserApprovalProcessProcessNodeCreatePost = mergeAsyncFn()((data?: ProcessNodeSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-node/create', data) as unknown as Promise<R<string>>
})


/**
 * 删除流程节点
 *
 */
export const marketplaceUserApprovalProcessProcessNodeDeleteDelete = mergeAsyncFn()((params: {

  /**
   * processId
   */
  processId: string

}) => {
  return request.delete<R<string>>(`/marketplace-user/approvalProcess/process-node/delete?processId=${params.processId}`, { params }) as unknown as Promise<R<string>>
})


/**
 * 流程节点详情
 *
 */
export const marketplaceUserApprovalProcessProcessNodeGetGet = mergeAsyncFn()((params: {

  /**
   * processNodeId
   */
  processNodeId: string

}) => {
  return request.get<R<ProcessDefinitionRespVO>>('/marketplace-user/approvalProcess/process-node/get', { params }) as unknown as Promise<R<ProcessDefinitionRespVO>>
})


/**
 * 更新流程节点
 *
 */
export const marketplaceUserApprovalProcessProcessNodeUpdatePost = mergeAsyncFn()((data?: ProcessNodeSaveVO) => {
  return request.post<R<string>>('/marketplace-user/approvalProcess/process-node/update', data) as unknown as Promise<R<string>>
})


/**
 * 通过审批
 *
 */
export const marketplaceUserApprovalProcessProcessTaskApprovePost = mergeAsyncFn()((data?: ProcessTaskResultReqVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-task/approve', data) as unknown as Promise<R<boolean>>
})


/**
 * 根据工作流ID号查询审批流程节点处理进度
 *
 */
export const marketplaceUserApprovalProcessProcessTaskBusinessOrderIdPost = mergeAsyncFn()((params: {

  /**
   * 审批对象类型
   */
  approvalObjectType: string


  /**
   * 业务单号id
   */
  businessOrderNumber?: string


  /**
   * 工作流ID 和业务单号二选一
   */
  workOrderId?: string

}) => {
  return request.post<R<ProcessResultVO>>('/marketplace-user/approvalProcess/process-task/businessOrderId',) as unknown as Promise<R<ProcessResultVO>>
})


/**
 * 根据业务单号查询审批流程节点处理进度
 *
 */
export const marketplaceUserApprovalProcessProcessTaskBusinessOrderNumberPost = mergeAsyncFn()((data?: TaskResultReqVO) => {
  return request.post<R<ProcessResultVO>>('/marketplace-user/approvalProcess/process-task/businessOrderNumber', data) as unknown as Promise<R<ProcessResultVO>>
})


/**
 * 认领工单
 *
 */
export const marketplaceUserApprovalProcessProcessTaskClaimPost = mergeAsyncFn()((data?: ProcessTaskClaimReqVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-task/claim', data) as unknown as Promise<R<boolean>>
})


/**
 * 获取 Done 已办任务分页
 *
 */
export const marketplaceUserApprovalProcessProcessTaskDonePagePost = mergeAsyncFn()((data?: ProcessTaskPageVO) => {
  return request.post<R<PageResult<ProcessTaskRespVO>>>('/marketplace-user/approvalProcess/process-task/done-page', data) as unknown as Promise<R<PageResult<ProcessTaskRespVO>>>
})


/**
 * 获得指定流程实例的任务列表
 *
 */
export const marketplaceUserApprovalProcessProcessTaskListByProcessInstanceIdGet = mergeAsyncFn()((params: {

  /**
   * processInstanceId
   */
  processInstanceId: string

}) => {
  return request.get<R<ProcessTaskRespVO[]>>('/marketplace-user/approvalProcess/process-task/list-by-process-instance-id', { params }) as unknown as Promise<R<ProcessTaskRespVO[]>>
})


/**
 * 审批流程任务工单分页
 *
 */
export const marketplaceUserApprovalProcessProcessTaskPagePost = mergeAsyncFn()((data?: ProcessTaskPageVO) => {
  return request.post<R<PageResult<ProcessInstanceRespVO>>>('/marketplace-user/approvalProcess/process-task/page', data) as unknown as Promise<R<PageResult<ProcessInstanceRespVO>>>
})


/**
 * 驳回审批
 *
 */
export const marketplaceUserApprovalProcessProcessTaskRejectPost = mergeAsyncFn()((data?: ProcessTaskResultReqVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-task/reject', data) as unknown as Promise<R<boolean>>
})


/**
 * sendSMS
 *
 */
export const marketplaceUserApprovalProcessProcessTaskSendSMSPost = mergeAsyncFn()((data?: DuanXinCanShu) => {
  return request.post<any>('/marketplace-user/approvalProcess/process-task/sendSMS', data) as unknown as Promise<any>
})


/**
 * testSitValue
 *
 */
export const marketplaceUserApprovalProcessProcessTaskTestSitValueGet = mergeAsyncFn()((params?: {

  /**
   * key
   */
  key?: string

}) => {
  return request.get<any>('/marketplace-user/approvalProcess/process-task/testSitValue', { params }) as unknown as Promise<any>
})


/**
 * 获取 Todo 待办任务分页
 *
 */
export const marketplaceUserApprovalProcessProcessTaskTodoPagePost = mergeAsyncFn()((data?: ProcessTaskPageVO) => {
  return request.post<R<PageResult<ProcessTaskRespVO>>>('/marketplace-user/approvalProcess/process-task/todo-page', data) as unknown as Promise<R<PageResult<ProcessTaskRespVO>>>
})


/**
 * 更新任务的负责人
 *
 */
export const marketplaceUserApprovalProcessProcessTaskUpdateAssigneePost = mergeAsyncFn()((data?: ProcessTaskUpdateAssigneeReqVO) => {
  return request.post<R<boolean>>('/marketplace-user/approvalProcess/process-task/update-assignee', data) as unknown as Promise<R<boolean>>
})


/**
 * callBack
 *
 */
export const marketplaceUserApprovalProcessWorkCallbackGet = mergeAsyncFn()((params?: {

  /**
   * workId
   */
  workId?: string

}) => {
  return request.get<R<any>>('/marketplace-user/approvalProcess/workCallback', { params }) as unknown as Promise<R<any>>
})


/**
 * 获取Tokenuser
 *
 */
export const marketplaceUserAuthTokenUserV1Get = mergeAsyncFn()((params?: {

  /**
   * token
   */
  token?: string


  /**
   * userId
   */
  userId?: string

}) => {
  return request.get<ResponseEntity>('/marketplace-user/auth/token/user/v1', { params }) as unknown as Promise<ResponseEntity>
})


/**
 * 查询合规检查结果详情
 *
 */
export const marketplaceUserComplianceInspectionCheckResultInfoDetailV1Get = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: number

}) => {
  return request.get<R<ComplianceInspectionResultVO>>('/marketplace-user/compliance/inspection/check/result/info/detail/v1', { params }) as unknown as Promise<R<ComplianceInspectionResultVO>>
})


/**
 * 查询合规检查结果列表
 *
 */
export const marketplaceUserComplianceInspectionCheckResultSearchV1Post = mergeAsyncFn()((data?: ComplianceInspectionQueryVO) => {
  return request.post<ComplianceInspectionResultVO[]>('/marketplace-user/compliance/inspection/check/result/search/v1', data) as unknown as Promise<ComplianceInspectionResultVO[]>
})


/**
 * 查询tiger合规检查结果列表
 *
 */
export const marketplaceUserComplianceInspectionCheckResultTigerSearchV1Post = mergeAsyncFn()(() => {
  return request.post<ComplianceInspectionResultVO[]>('/marketplace-user/compliance/inspection/check/result/tiger/search/v1',) as unknown as Promise<ComplianceInspectionResultVO[]>
})


/**
 * getEmailsByRoleIds
 *
 */
export const marketplaceUserFrontGetEmailsByRoleIdsGet = mergeAsyncFn()((params?: {

  /**
   * nodeRules
   */
  nodeRules?: string

}) => {
  return request.get<any[]>('/marketplace-user/front/getEmailsByRoleIds', { params }) as unknown as Promise<any[]>
})


/**
 * getEmailsByUserId
 *
 */
export const marketplaceUserFrontGetEmailsByUserIdGet = mergeAsyncFn()((params?: {

  /**
   * nodeRule
   */
  nodeRule?: string

}) => {
  return request.get<R<any>>('/marketplace-user/front/getEmailsByUserId', { params }) as unknown as Promise<R<any>>
})


/**
 * sedEmails
 *
 */
export const marketplaceUserFrontSedEmailsGet = mergeAsyncFn()((params?: {

  /**
   * emails
   */
  emails?: string

}) => {
  return request.get<any>('/marketplace-user/front/sedEmails', { params }) as unknown as Promise<any>
})


/**
 * idCardOcr
 *
 */
export const marketplaceUserFrontUserAuthIdcardOcrPost = mergeAsyncFn()((data?: AuthOfIdCardOcrRequest) => {
  return request.post<R<AbsIdCarOcrResponse>>('/marketplace-user/front/user/auth/idcard/ocr', data) as unknown as Promise<R<AbsIdCarOcrResponse>>
})


/**
 * mobileVerify
 *
 */
export const marketplaceUserFrontUserAuthMobileVerifyPost = mergeAsyncFn()((data?: AuthOfMobileVerifyRequest) => {
  return request.post<R<AuthOfMobileVerifyResponse>>('/marketplace-user/front/user/auth/mobile/verify', data) as unknown as Promise<R<AuthOfMobileVerifyResponse>>
})


/**
 * mobileVerifyV1
 *
 */
export const marketplaceUserFrontUserAuthMobileVerifyV1Post = mergeAsyncFn()((data?: AuthOfMobileVerifyRequest) => {
  return request.post<R<AuthOfMobileVerifyResponse>>('/marketplace-user/front/user/auth/mobile/verify/v1', data) as unknown as Promise<R<AuthOfMobileVerifyResponse>>
})


/**
 * getUserAuthStatus
 *
 */
export const marketplaceUserFrontUserAuthResultGetGet = mergeAsyncFn()(() => {
  return request.get<R<AuthOfMobileVerifyResponse>>('/marketplace-user/front/user/auth/result/get',) as unknown as Promise<R<AuthOfMobileVerifyResponse>>
})


/**
 * 获取用户留资
 *
 */
export const marketplaceUserFrontUserContactInfoV3Post = mergeAsyncFn()((data?: GetContactUsReq, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/front/user/contact/info/v3', data) as unknown as Promise<R<any>>
})


/**
 * 获取企业认证说明文档
 *
 */
export const marketplaceUserHelpDocumentGetPost = mergeAsyncFn()((data?: BangZhuWenDangDeQingQiuTi) => {
  return request.post<R<BangZhuWenDangDeXiangYingTi>>('/marketplace-user/help/document/get', data) as unknown as Promise<R<BangZhuWenDangDeXiangYingTi>>
})


/**
 * queryOneByCode
 *
 */
export const marketplaceUserInternalEmailTemplateQueryByCodePost = mergeAsyncFn()((data?: string) => {
  return request.post<EmailTemplate>('/marketplace-user/internal/emailTemplate/queryByCode', data) as unknown as Promise<EmailTemplate>
})


/**
 * query2
 *
 */
export const marketplaceUserInternalQueryPost = mergeAsyncFn()((data?: BISRequest) => {
  return request.post<BISResponse>('/marketplace-user/internal/query', data) as unknown as Promise<BISResponse>
})


/**
 * getTenantAdmin
 *
 */
export const marketplaceUserInternalTenantAdminGet = mergeAsyncFn()((params: {

  /**
   * tenantId
   */
  tenantId: string


  /**
   * appId
   */
  appId: number

}, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.get<UserTenantInfoItem>('/marketplace-user/internal/tenant/admin', { params, headers }) as unknown as Promise<UserTenantInfoItem>
})


/**
 * getUserProfileById
 *
 */
export const marketplaceUserInternalUserProfileDetailPost = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}) => {
  return request.post<UserProfile>('/marketplace-user/internal/user/profile/detail',) as unknown as Promise<UserProfile>
})


/**
 * getUserProfileByIds
 *
 */
export const marketplaceUserInternalUserProfileListPost = mergeAsyncFn()((data?: any[], headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<UserProfile[]>('/marketplace-user/internal/user/profile/list', data) as unknown as Promise<UserProfile[]>
})


/**
 * getUserProfileBySearch
 *
 */
export const marketplaceUserInternalUserProfileSearchPost = mergeAsyncFn()((data?: UserProfileSearchVO, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<PageResult<UserProfileVO>>('/marketplace-user/internal/user/profile/search', data) as unknown as Promise<PageResult<UserProfileVO>>
})


/**
 * bindUserTenantInfo
 *
 */
export const marketplaceUserInternalUserTenantBindPost = mergeAsyncFn()((data?: UserTenantBindVo, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<void>('/marketplace-user/internal/user/tenant/bind', data) as unknown as Promise<void>
})


/**
 * changeUserTenantInfo
 *
 */
export const marketplaceUserInternalUserTenantChangePost = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string


  /**
   * tenantId
   */
  tenantId: string

}, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<void>('/marketplace-user/internal/user/tenant/change',) as unknown as Promise<void>
})


/**
 * ensureTenantInfo
 *
 */
export const marketplaceUserInternalUserTenantEnsurePost = mergeAsyncFn()((params: {

  /**
   * tenantName
   */
  tenantName: string


  /**
   * tenantId
   */
  tenantId: string

}, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<void>('/marketplace-user/internal/user/tenant/ensure',) as unknown as Promise<void>
})


/**
 * initTenantUserInfo
 *
 */
export const marketplaceUserInternalUserTenantInitGet = mergeAsyncFn()((params: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.get<R<any>>('/marketplace-user/internal/user-tenant/init', { params }) as unknown as Promise<R<any>>
})


/**
 * getUserTenantBySearch
 *
 */
export const marketplaceUserInternalUserTenantSearchPost = mergeAsyncFn()((data?: UserTenantSearchVo, headers?: {

  /**
   * internalKey
   */
  internalKey?: string

}) => {
  return request.post<UserTenantRespVo>('/marketplace-user/internal/user/tenant/search', data) as unknown as Promise<UserTenantRespVo>
})


/**
 * 消息列表
 *
 */
export const marketplaceUserListUserNotificationV1Post = mergeAsyncFn()((data?: XiaoXiChaXunTiaoJian, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<PageResult<UserNotificationVO>>>('/marketplace-user/list/user/notification/v1', data) as unknown as Promise<R<PageResult<UserNotificationVO>>>
})


/**
 * getSapCustomerListByName
 *
 */
export const marketplaceUserMasterdataSapCustomerListByNameV1Post = mergeAsyncFn()((data?: SapCustomerSearchVO) => {
  return request.post<SapCustomerVO[]>('/marketplace-user/masterdata/sap/customer/listByName/v1', data) as unknown as Promise<SapCustomerVO[]>
})


/**
 * getSapCustomerListByCustomerNo
 *
 */
export const marketplaceUserMasterdataSapCustomerListByNoV1Post = mergeAsyncFn()((data?: SapCustomerSearchVO) => {
  return request.post<SapCustomerVO[]>('/marketplace-user/masterdata/sap/customer/listByNo/v1', data) as unknown as Promise<SapCustomerVO[]>
})


/**
 * getSapCustomerListByCNOC
 *
 */
export const marketplaceUserMasterdataSapCustomerListV1Post = mergeAsyncFn()((data?: SapCustomerSearchVO) => {
  return request.post<SapCustomerVO[]>('/marketplace-user/masterdata/sap/customer/list/v1', data) as unknown as Promise<SapCustomerVO[]>
})


/**
 * 获取消息配置
 *
 */
export const marketplaceUserNotificationConfigGet = mergeAsyncFn()(() => {
  return request.get<R<NotificationConfigVO[]>>('/marketplace-user/notification/config',) as unknown as Promise<R<NotificationConfigVO[]>>
})


/**
 * 保存消息配置
 *
 */
export const marketplaceUserNotificationConfigPost = mergeAsyncFn()((data?: NotificationConfigVO[]) => {
  return request.post<R<any>>('/marketplace-user/notification/config', data) as unknown as Promise<R<any>>
})


/**
 * 获取私有OSS服务端签名
 *
 */
export const marketplaceUserOssPolicyGet = mergeAsyncFn()(() => {
  return request.get<R<Record<string, string>>>('/marketplace-user/oss/policy',) as unknown as Promise<R<Record<string, string>>>
})


/**
 * 获取私有文件
 *
 */
export const marketplaceUserOssPrivateGetGet = mergeAsyncFn()((params: {

  /**
   * token
   */
  token?: string


  /**
   * 文件key
   */
  ossKey: string


  /**
   * wechatToken
   */
  wechatToken?: string


  /**
   * ossToken
   */
  ossToken?: string

}) => {
  return request.get<void>('/marketplace-user/oss/private/get', { params }) as unknown as Promise<void>
})


/**
 * 获取短链接详情
 *
 */
export const marketplaceUserQrcodeRealLinkGet = mergeAsyncFn()((params: {

  /**
   * 短链接Key
   */
  shortLinkKey: string

}, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string

}) => {
  return request.get<R<Record<string, string>>>('/marketplace-user/qrcode/realLink', { params, headers }) as unknown as Promise<R<Record<string, string>>>
})


/**
 * 根据租户阶段查询
 *
 */
export const marketplaceUserTenantSyncListByStageGet = mergeAsyncFn()((params: {

  /**
   * tenantStage
   */
  tenantStage: string

}, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.get<R<SellerTenantSimpleInfoDTO[]>>('/marketplace-user/tenant/sync/list-by-stage', { params, headers }) as unknown as Promise<R<SellerTenantSimpleInfoDTO[]>>
})


/**
 * 租户用户详情
 *
 */
export const marketplaceUserTenantSyncUserDetailGet = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.get<R<SellerUserSimpleInfoDTO>>('/marketplace-user/tenant/sync/userDetail', { params, headers }) as unknown as Promise<R<SellerUserSimpleInfoDTO>>
})


/**
 * getByUser
 *
 */
export const marketplaceUserThirdPartyEnterpriseGetByUserPost = mergeAsyncFn()((data?: HuoQuYongHuSuoShuQiYeDeQingQiuTi) => {
  return request.post<R<ThirdPartyResponseBase>>('/marketplace-user/third/party/enterprise/get/by/user', data) as unknown as Promise<R<ThirdPartyResponseBase>>
})


/**
 * 上传主体维度数据
 *
 */
export const marketplaceUserUploadSubjectDimensionDataPost = mergeAsyncFn()(() => {
  return request.post<R<any>>('/marketplace-user/uploadSubjectDimensionData',) as unknown as Promise<R<any>>
})


/**
 * 删除用户活动
 *
 */
export const marketplaceUserUserActivityCancelPost = mergeAsyncFn()((data?: UserActivityCancelReq, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/cancel', data) as unknown as Promise<R<any>>
})


/**
 * 创建用户活动
 *
 */
export const marketplaceUserUserActivityCreatePost = mergeAsyncFn()((data?: UserActivityVO, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/create', data) as unknown as Promise<R<any>>
})


/**
 * 用户通过邮件获取附件
 *
 */
export const marketplaceUserUserActivityFileSendEmailPost = mergeAsyncFn()((data?: UserActivityVO, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/file/send/email', data) as unknown as Promise<R<any>>
})


/**
 * 用户热门活动取消收藏
 *
 */
export const marketplaceUserUserActivityHotCollectionCancelPost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/hot/collection/cancel',) as unknown as Promise<R<any>>
})


/**
 * 用户热门活动收藏
 *
 */
export const marketplaceUserUserActivityHotCollectionCreatePost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/hot/collection/create',) as unknown as Promise<R<any>>
})


/**
 * 用户热门活动是否收藏
 *
 */
export const marketplaceUserUserActivityHotCollectionExistsPost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/hot/collection/exists',) as unknown as Promise<R<any>>
})


/**
 * 用户活动列表
 *
 */
export const marketplaceUserUserActivityListPost = mergeAsyncFn()((data?: HuoDongChaXunTiaoJian, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<PageResult<UserActivityVO>>>('/marketplace-user/user/activity/list', data) as unknown as Promise<R<PageResult<UserActivityVO>>>
})


/**
 * 用户活动报名信息带出
 *
 */
export const marketplaceUserUserActivityRegistrationInfoGet = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.get<R<ActivityRegistrationVO>>('/marketplace-user/user/activity/registration/info', { headers }) as unknown as Promise<R<ActivityRegistrationVO>>
})


/**
 * 更新用户上传文件
 *
 */
export const marketplaceUserUserActivityUpdateFilePost = mergeAsyncFn()((data?: UserActivityVO, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/update/file', data) as unknown as Promise<R<any>>
})


/**
 * 用户活动上传文件
 *
 */
export const marketplaceUserUserActivityUploadPost = mergeAsyncFn()((data?: any, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/activity/upload', data) as unknown as Promise<R<any>>
})


/**
 * 用户活动微吼平台生成key
 *
 */
export const marketplaceUserUserActivityVhallGenerateKeyByActivityIdGet = mergeAsyncFn()((activityId: string | number, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string

}) => {
  return request.get<R<string>>(`/marketplace-user/user/activity/vhall/generateKey/${activityId}`, { headers }) as unknown as Promise<R<string>>
})


/**
 * 用户活动微吼平台验证key
 *
 */
export const marketplaceUserUserActivityVhallValidationKeyPost = mergeAsyncFn()((data?: any) => {
  return request.post<string>('/marketplace-user/user/activity/vhall/validationKey', data) as unknown as Promise<string>
})


/**
 * edsComplianceInspectionApplication
 *
 */
export const marketplaceUserUserAuthResultEdsPost = mergeAsyncFn()((data?: ComplianceInspectionCheckVO) => {
  return request.post<R<ComplianceInspectionResultVO>>('/marketplace-user/user/auth/result/eds', data) as unknown as Promise<R<ComplianceInspectionResultVO>>
})


/**
 * getUserAuthStatusSearch
 *
 */
export const marketplaceUserUserAuthResultSearchPost = mergeAsyncFn()((data?: AuthInfoSearchRequest) => {
  return request.post<R<AuthOfMobileVerifyResponse[]>>('/marketplace-user/user/auth/result/search', data) as unknown as Promise<R<AuthOfMobileVerifyResponse[]>>
})


/**
 * spsComplianceInspectionApplication
 *
 */
export const marketplaceUserUserAuthResultSpsPost = mergeAsyncFn()((data?: ComplianceInspectionCheckVO) => {
  return request.post<R<ComplianceInspectionResultVO>>('/marketplace-user/user/auth/result/sps', data) as unknown as Promise<R<ComplianceInspectionResultVO>>
})


/**
 * tigerComplianceInspectionApplication
 *
 */
export const marketplaceUserUserAuthResultTigerPost = mergeAsyncFn()((data?: ComplianceInspectionCheckVO) => {
  return request.post<R<ComplianceInspectionResultVO>>('/marketplace-user/user/auth/result/tiger', data) as unknown as Promise<R<ComplianceInspectionResultVO>>
})


/**
 * 用户头像上传
 *
 */
export const marketplaceUserUserAvatarV1Post = mergeAsyncFn()((data?: any, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/avatar/v1', data) as unknown as Promise<R<any>>
})


/**
 * 用户注销
 *
 */
export const marketplaceUserUserCancellationV1Post = mergeAsyncFn()((data?: UserCancellationRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/cancellation/v1', data) as unknown as Promise<any>
})


/**
 * 取消用户收藏
 *
 */
export const marketplaceUserUserCollectionCancelPost = mergeAsyncFn()((data?: UserCollectionCancelRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/collection/cancel', data) as unknown as Promise<R<any>>
})


/**
 * 删除用户收藏
 *
 */
export const marketplaceUserUserCollectionDeletePost = mergeAsyncFn()((data?: UserCollectionDeleteRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/collection/delete', data) as unknown as Promise<R<any>>
})


/**
 * 用户收藏详情
 *
 */
export const marketplaceUserUserCollectionGetDetailPost = mergeAsyncFn()((data?: UserCollectionGetDetailRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<UserCollectionGetDetailResponse>('/marketplace-user/user/collection/get/detail', data) as unknown as Promise<UserCollectionGetDetailResponse>
})


/**
 * 用户收藏列表
 *
 */
export const marketplaceUserUserCollectionGetListPost = mergeAsyncFn()((data?: Page<any>, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<UserCollectionGetListResponse>('/marketplace-user/user/collection/get/list', data) as unknown as Promise<UserCollectionGetListResponse>
})


/**
 * 创建用户收藏
 *
 */
export const marketplaceUserUserCollectionPostPost = mergeAsyncFn()((data?: UserCollection, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/collection/post', data) as unknown as Promise<R<any>>
})


/**
 * 通用通过邮件获取附件
 *
 */
export const marketplaceUserUserCommonFileSendEmailPost = mergeAsyncFn()((data?: FileDownloadEmailRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/common/file/send/email', data) as unknown as Promise<R<any>>
})


/**
 * 获取用户留资
 *
 */
export const marketplaceUserUserContactInfoV1Post = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/contact/info/v1',) as unknown as Promise<R<any>>
})


/**
 * 获取用户留资
 *
 */
export const marketplaceUserUserContactInfoV2Post = mergeAsyncFn()((data?: HuoQuYongHuLiuZiRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/contact/info/v2', data) as unknown as Promise<R<any>>
})


/**
 * 获取用户留资
 *
 */
export const marketplaceUserUserContactInfoV3Post = mergeAsyncFn()((data?: GetContactUsReq, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/contact/info/v3', data) as unknown as Promise<R<any>>
})


/**
 * 访问用户留资
 *
 */
export const marketplaceUserUserContactV1Post = mergeAsyncFn()((data?: ContactInfo, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/contact/v1', data) as unknown as Promise<any>
})


/**
 * 访问用户留资
 *
 */
export const marketplaceUserUserContactV2Post = mergeAsyncFn()((data?: ContactInfo, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string


  /**
   * terminalType
   */
  terminalType?: string

}) => {
  return request.post<any>('/marketplace-user/user/contact/v2', data) as unknown as Promise<any>
})


/**
 * 独立表单报名
 *
 */
export const marketplaceUserUserContactV3Post = mergeAsyncFn()((data?: ContactInfo, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/contact/v3', data) as unknown as Promise<any>
})


/**
 * 留资自动登录
 *
 */
export const marketplaceUserUserContactWithAutoLoginV1Post = mergeAsyncFn()((data?: ContactWithAutoLoginRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/contact/with/auto/login/v1', data) as unknown as Promise<R<any>>
})


/**
 * 新增用户
 *
 */
export const marketplaceUserUserCreatePost = mergeAsyncFn()((data?: UserCreateVo) => {
  return request.post<R<any>>('/marketplace-user/user/create', data) as unknown as Promise<R<any>>
})


/**
 * approveInternalApplicationCallback
 *
 */
export const marketplaceUserUserEnterpriseAuthApproveCallbackV1Post = mergeAsyncFn()((data?: EnterpriseAuthenticationUserCallbackVO) => {
  return request.post<ResponseEntity>('/marketplace-user/user/enterpriseAuth/approve/callback/v1', data) as unknown as Promise<ResponseEntity>
})


/**
 * 用户是否存在
 *
 */
export const marketplaceUserUserExistsPost = mergeAsyncFn()((data?: UserValidateRequest) => {
  return request.post<UserExistsResponse>('/marketplace-user/user/exists', data) as unknown as Promise<UserExistsResponse>
})


/**
 * 获取UIM自动登陆访问AccessToken
 *
 */
export const marketplaceUserUserGetAutoLoginUimAccessTokenV1Post = mergeAsyncFn()((data?: GetUimAccessTokenRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<R<UIMAccessToken>>('/marketplace-user/user/getAutoLoginUimAccessToken/v1', data) as unknown as Promise<R<UIMAccessToken>>
})


/**
 * 获取UIM访问AccessToken
 *
 */
export const marketplaceUserUserGetUimAccessTokenV1Post = mergeAsyncFn()((data?: GetUimAccessTokenRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<R<UIMAccessToken>>('/marketplace-user/user/getUimAccessToken/v1', data) as unknown as Promise<R<UIMAccessToken>>
})


/**
 * wechat获取UIM访问AccessToken
 *
 */
export const marketplaceUserUserGetUimAccessTokenV2Post = mergeAsyncFn()((data?: GetUimAccessTokenRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<R<UIMAccessToken>>('/marketplace-user/user/getUimAccessToken/v2', data) as unknown as Promise<R<UIMAccessToken>>
})


/**
 * 清空用户浏览记录
 *
 */
export const marketplaceUserUserHistoryCleanPost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/history/clean',) as unknown as Promise<R<any>>
})


/**
 * 删除用户浏览记录
 *
 */
export const marketplaceUserUserHistoryDeletePost = mergeAsyncFn()((data?: UserHistoryDeleteRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/history/delete', data) as unknown as Promise<R<any>>
})


/**
 * 用户浏览记录详情 
 *
 */
export const marketplaceUserUserHistoryGetDetailPost = mergeAsyncFn()((data?: UserHistoryGetDetailRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<UserHistoryGetDetailResponse>('/marketplace-user/user/history/get/detail', data) as unknown as Promise<UserHistoryGetDetailResponse>
})


/**
 * 用户浏览记录列表
 *
 */
export const marketplaceUserUserHistoryGetListPost = mergeAsyncFn()((data?: Page<any>, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<UserHistoryGetListResponse>('/marketplace-user/user/history/get/list', data) as unknown as Promise<UserHistoryGetListResponse>
})


/**
 * 创建用户浏览记录
 *
 */
export const marketplaceUserUserHistoryPostPost = mergeAsyncFn()((data?: UserHistory, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/history/post', data) as unknown as Promise<R<any>>
})


/**
 * 搜索热词列表
 *
 */
export const marketplaceUserUserHotKeywordListPost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/hot/keyword/list',) as unknown as Promise<R<any>>
})


/**
 * 用户新增
 *
 */
export const marketplaceUserUserImportPost = mergeAsyncFn()((data?: UserImportRequest) => {
  return request.post<boolean>('/marketplace-user/user/import', data) as unknown as Promise<boolean>
})


/**
 * 一次性CODE获取用户信息（用于ADVTool对接）
 *
 */
export const marketplaceUserUserInfoCodeV1Post = mergeAsyncFn()((data?: UserInfoCodeRequest, headers?: {

  /**
   * 请求Token
   */
  token: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/info/code/v1', data) as unknown as Promise<R<any>>
})


/**
 * 用户信息
 *
 */
export const marketplaceUserUserInfoV1Post = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/info/v1',) as unknown as Promise<any>
})


/**
 * 用户列表查询
 *
 */
export const marketplaceUserUserListPost = mergeAsyncFn()((data?: UserListQueryVo) => {
  return request.post<R<any>>('/marketplace-user/user/list', data) as unknown as Promise<R<any>>
})


/**
 * 用Uim AccessToken登录
 *
 */
export const marketplaceUserUserLoginByUimAccessTokenV1Post = mergeAsyncFn()((data?: UimAccessTokenLoginRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<UiminfoR>('/marketplace-user/user/loginByUimAccessToken/v1', data) as unknown as Promise<UiminfoR>
})


/**
 * 用户登出
 *
 */
export const marketplaceUserUserLogoutV1Post = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/logout/v1',) as unknown as Promise<any>
})


/**
 * 获取用户菜单
 *
 */
export const marketplaceUserUserMenuGet = mergeAsyncFn()(() => {
  return request.get<R<any>>('/marketplace-user/user/menu',) as unknown as Promise<R<any>>
})


/**
 * 删除用户消息
 *
 */
export const marketplaceUserUserNotificationDeletePost = mergeAsyncFn()((data?: any[], headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/notification/delete', data) as unknown as Promise<R<any>>
})


/**
 * 标记用户消息已读
 *
 */
export const marketplaceUserUserNotificationReadPost = mergeAsyncFn()((data?: any[], headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/notification/read', data) as unknown as Promise<R<any>>
})


/**
 * 用户信息一次性CODE（用于ADVTool对接）
 *
 */
export const marketplaceUserUserOneceCodeV1Post = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/onece/code/v1',) as unknown as Promise<R<any>>
})


/**
 * 获取用户数据权限
 *
 */
export const marketplaceUserUserPermissionDataGet = mergeAsyncFn()((params: {

  /**
   * 用户id
   */
  userId: string


  /**
   * 应用id
   */
  appId: number


  /**
   * 租户id
   */
  tenantId: string

}) => {
  return request.get<R<any>>('/marketplace-user/user/permission/data', { params }) as unknown as Promise<R<any>>
})


/**
 * 获取用户的权限
 *
 */
export const marketplaceUserUserPermissionGet = mergeAsyncFn()((params: {

  /**
   * userId
   */
  userId: string

}) => {
  return request.get<R<any>>('/marketplace-user/user/permission', { params }) as unknown as Promise<R<any>>
})


/**
 * 获取用户权限列表
 *
 */
export const marketplaceUserUserPermissionListGet = mergeAsyncFn()((params: {

  /**
   * 用户id
   */
  userId: string


  /**
   * 应用id
   */
  appId: number


  /**
   * 租户id
   */
  tenantId: string


  /**
   * 权限类型
   */
  type?: number

}) => {
  return request.get<R<any>>('/marketplace-user/user/permission/list', { params }) as unknown as Promise<R<any>>
})


/**
 * 获取用户菜单
 *
 */
export const marketplaceUserUserPermissionMenuGet = mergeAsyncFn()((params: {

  /**
   * 用户id
   */
  userId: string


  /**
   * 应用id
   */
  appId: number


  /**
   * 租户id
   */
  tenantId: string

}) => {
  return request.get<R<any>>('/marketplace-user/user/permission/menu', { params }) as unknown as Promise<R<any>>
})


/**
 * 设置用户的权限
 *
 */
export const marketplaceUserUserPermissionPost = mergeAsyncFn()((data?: UserPermissionVo) => {
  return request.post<R<any>>('/marketplace-user/user/permission', data) as unknown as Promise<R<any>>
})


/**
 * 用户Profile信息
 *
 */
export const marketplaceUserUserProfileInfoV1Post = mergeAsyncFn()((data?: UserProfileInfoRequest, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/profile/Info/v1', data) as unknown as Promise<R<any>>
})


/**
 * 用户Profile保存
 *
 */
export const marketplaceUserUserProfileV1Post = mergeAsyncFn()((data?: UserProfile, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/profile/v1', data) as unknown as Promise<any>
})


/**
 * 清空用户搜索历史记录
 *
 */
export const marketplaceUserUserSearchHistoryClearPost = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<R<any>>('/marketplace-user/user/search/history/clear',) as unknown as Promise<R<any>>
})


/**
 * 用户搜索历史记录列表
 *
 */
export const marketplaceUserUserSearchHistoryListPost = mergeAsyncFn()((data?: Page<any>, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<UserSearchHistoryListResponse>('/marketplace-user/user/search/history/list', data) as unknown as Promise<UserSearchHistoryListResponse>
})


/**
 * 发送验证码邮件
 *
 */
export const marketplaceUserUserSendAuthMailV1Post = mergeAsyncFn()((data?: UserAuthCodeRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<UiminfoR>('/marketplace-user/user/send/auth/mail/v1', data) as unknown as Promise<UiminfoR>
})


/**
 * 发送验证码短信
 *
 */
export const marketplaceUserUserSendAuthMsgV1Post = mergeAsyncFn()((data?: UserAuthCodeRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<UiminfoR>('/marketplace-user/user/send/auth/msg/v1', data) as unknown as Promise<UiminfoR>
})


/**
 * 分享accessToken
 *
 */
export const marketplaceUserUserShareAccessTokenV1Post = mergeAsyncFn()((data?: ShareAccessTokenModel, params?: {

  /**
   * 产品ID
   */
  productId?: string

}, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<R<UIMAccessToken>>('/marketplace-user/user/shareAccessToken/v1', data) as unknown as Promise<R<UIMAccessToken>>
})


/**
 * 订阅
 *
 */
export const marketplaceUserUserSubscriptionsAddPost = mergeAsyncFn()((data?: UserSubscriptionVO) => {
  return request.post<R<string>>('/marketplace-user/user/subscriptions/add', data) as unknown as Promise<R<string>>
})


/**
 * 取消订阅
 *
 */
export const marketplaceUserUserSubscriptionsCancelPost = mergeAsyncFn()((data?: WoDeDingYueCaoZuoQingQiu) => {
  return request.post<R<boolean>>('/marketplace-user/user/subscriptions/cancel', data) as unknown as Promise<R<boolean>>
})


/**
 * 获取用户是否订阅
 *
 */
export const marketplaceUserUserSubscriptionsGetPost = mergeAsyncFn()((data?: WoDeDingYueCaoZuoQingQiu) => {
  return request.post<R<UserSubscriptionVO>>('/marketplace-user/user/subscriptions/get', data) as unknown as Promise<R<UserSubscriptionVO>>
})


/**
 * 获取用户订阅列表
 *
 */
export const marketplaceUserUserSubscriptionsListPost = mergeAsyncFn()((data?: WoDeDingYueChaXunTiaoJian) => {
  return request.post<R<PageResult<UserSubscriptionVO>>>('/marketplace-user/user/subscriptions/list', data) as unknown as Promise<R<PageResult<UserSubscriptionVO>>>
})


/**
 * 查询用户租户
 *
 */
export const marketplaceUserUserTenantInfoGet = mergeAsyncFn()((params?: {

  /**
   * userId
   */
  userId?: string


  /**
   * appId
   */
  appId?: number

}) => {
  return request.get<R<any>>('/marketplace-user/user/tenant-info', { params }) as unknown as Promise<R<any>>
})


/**
 * 用户试用列表
 *
 */
export const marketplaceUserUserTrialListPost = mergeAsyncFn()((data?: ProductVO, headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/trial/list', data) as unknown as Promise<any>
})


/**
 * UIM用户信息
 *
 */
export const marketplaceUserUserUiminfoV1Post = mergeAsyncFn()((data?: UiminfoRequestBody, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<UiminfoR>('/marketplace-user/user/uiminfo/v1', data) as unknown as Promise<UiminfoR>
})


/**
 * 用户解绑
 *
 */
export const marketplaceUserUserUnbindPost = mergeAsyncFn()((data?: UserUnBindVo) => {
  return request.post<R<any>>('/marketplace-user/user/unbind', data) as unknown as Promise<R<any>>
})


/**
 * 用户信息修改
 *
 */
export const marketplaceUserUserUpdatePost = mergeAsyncFn()((data?: UserUpdateVo) => {
  return request.post<R<any>>('/marketplace-user/user/update', data) as unknown as Promise<R<any>>
})


/**
 * 验证登录
 *
 */
export const marketplaceUserUserValidLoginV1Post = mergeAsyncFn()((headers?: {

  /**
   * 请求Token
   */
  token: string


  /**
   * 显示语言
   */
  language?: string


  /**
   * 请求参数签名
   */
  sign?: string

}) => {
  return request.post<any>('/marketplace-user/user/validLogin/v1',) as unknown as Promise<any>
})


/**
 * 校验验证码
 *
 */
export const marketplaceUserUserVerifyAuthCodeV1Post = mergeAsyncFn()((data?: UserAuthCodeRequest, headers?: {

  /**
   * 认证Token
   */
  token: string


  /**
   * 系统语言
   */
  language?: string

}) => {
  return request.post<UiminfoR>('/marketplace-user/user/verify/auth/code/v1', data) as unknown as Promise<UiminfoR>
})


/**
 * 查询应用
 *
 */
export const marketplaceUser_internalAppByIdGet = mergeAsyncFn()((id: string | number) => {
  return request.get<R<AppVO>>(`/marketplace-user/_internal/app/${id}`,) as unknown as Promise<R<AppVO>>
})


/**
 * 删除应用
 *
 */
export const marketplaceUser_internalAppDeletePost = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: number

}) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/app/delete',) as unknown as Promise<R<boolean>>
})


/**
 * 应用-分页查询
 *
 */
export const marketplaceUser_internalAppPageGet = mergeAsyncFn()((params: {

  /**
   * 页码
   */
  page: number


  /**
   * 分页大小
   */
  pageSize: number

}) => {
  return request.get<R<PageResult<AppVO>>>('/marketplace-user/_internal/app/page', { params }) as unknown as Promise<R<PageResult<AppVO>>>
})


/**
 * 创建应用
 *
 */
export const marketplaceUser_internalAppPost = mergeAsyncFn()((data?: AppCreateVO) => {
  return request.post<R<number>>('/marketplace-user/_internal/app', data) as unknown as Promise<R<number>>
})


/**
 * 修改应用
 *
 */
export const marketplaceUser_internalAppUpdatePost = mergeAsyncFn()((data?: GengXinapp) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/app/update', data) as unknown as Promise<R<boolean>>
})


/**
 * 删除权限项
 *
 */
export const marketplaceUser_internalPermissionByIdDeletePost = mergeAsyncFn()((id: string | number) => {
  return request.post<R<any>>(`/marketplace-user/_internal/permission/${id}/delete`,) as unknown as Promise<R<any>>
})


/**
 * 新增 菜单/API
 *
 */
export const marketplaceUser_internalPermissionPost = mergeAsyncFn()((data?: PermissionCreateVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/permission', data) as unknown as Promise<R<any>>
})


/**
 * 更新权限配置状态
 *
 */
export const marketplaceUser_internalPermissionStatusPost = mergeAsyncFn()((data?: PermissionStatusVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/permission/status', data) as unknown as Promise<R<any>>
})


/**
 * 获取权限树（包括菜单、api）
 *
 */
export const marketplaceUser_internalPermissionTreeGet = mergeAsyncFn()((params: {

  /**
   * 应用id，每个系统id不同
   */
  appId: number

}) => {
  return request.get<R<any>>('/marketplace-user/_internal/permission/tree', { params }) as unknown as Promise<R<any>>
})


/**
 * 更新权限
 *
 */
export const marketplaceUser_internalPermissionUpdatePost = mergeAsyncFn()((data?: PermissionUpdateVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/permission/update', data) as unknown as Promise<R<any>>
})


/**
 * 删除角色
 *
 */
export const marketplaceUser_internalRoleByIdDeletePost = mergeAsyncFn()((id: string | number) => {
  return request.post<R<any>>(`/marketplace-user/_internal/role/${id}/delete`,) as unknown as Promise<R<any>>
})


/**
 * 查询角色详情
 *
 */
export const marketplaceUser_internalRoleByIdGet = mergeAsyncFn()((id: string | number) => {
  return request.get<R<any>>(`/marketplace-user/_internal/role/${id}`,) as unknown as Promise<R<any>>
})


/**
 * 角色分页查询
 *
 */
export const marketplaceUser_internalRolePagePost = mergeAsyncFn()((data?: RolePageQueryVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/role/page', data) as unknown as Promise<R<any>>
})


/**
 * get
 *
 */
export const marketplaceUser_internalRolePermissionByIdGet = mergeAsyncFn()((id: string | number) => {
  return request.get<R<RolePermissionDuiXiang>>(`/marketplace-user/_internal/rolePermission/${id}`,) as unknown as Promise<R<RolePermissionDuiXiang>>
})


/**
 * delete
 *
 */
export const marketplaceUser_internalRolePermissionDeletePost = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: number

}) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/rolePermission/delete',) as unknown as Promise<R<boolean>>
})


/**
 * 查询角色绑定权限
 *
 */
export const marketplaceUser_internalRolePermissionGet = mergeAsyncFn()((params: {

  /**
   * appId
   */
  appId: number


  /**
   * roleId
   */
  roleId: number

}) => {
  return request.get<R<any>>('/marketplace-user/_internal/role-permission', { params }) as unknown as Promise<R<any>>
})


/**
 * page
 *
 */
export const marketplaceUser_internalRolePermissionPageGet = mergeAsyncFn()((params: {

  /**
   * 页码
   */
  page: number


  /**
   * 分页大小
   */
  pageSize: number

}) => {
  return request.get<R<PageResult<RolePermissionDuiXiang>>>('/marketplace-user/_internal/rolePermission/page', { params }) as unknown as Promise<R<PageResult<RolePermissionDuiXiang>>>
})


/**
 * create
 *
 */
export const marketplaceUser_internalRolePermissionPost = mergeAsyncFn()((data?: RolePermissionDuiXiang0) => {
  return request.post<R<number>>('/marketplace-user/_internal/rolePermission', data) as unknown as Promise<R<number>>
})


/**
 * update
 *
 */
export const marketplaceUser_internalRolePermissionUpdatePost = mergeAsyncFn()((data?: RolePermissionDuiXiang) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/rolePermission/update', data) as unknown as Promise<R<boolean>>
})


/**
 * 新增角色
 *
 */
export const marketplaceUser_internalRolePost = mergeAsyncFn()((data?: RoleCreateVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/role', data) as unknown as Promise<R<any>>
})


/**
 * 更新角色
 *
 */
export const marketplaceUser_internalRoleUpdatePost = mergeAsyncFn()((data?: RoleUpdateVO) => {
  return request.post<R<any>>('/marketplace-user/_internal/role/update', data) as unknown as Promise<R<any>>
})


/**
 * admin-seller用户创建
 *
 */
export const marketplaceUser_internalSellerUserBindPost = mergeAsyncFn()((data?: SellerUserCreateVo) => {
  return request.post<R<any>>('/marketplace-user/_internal/seller-user/bind', data) as unknown as Promise<R<any>>
})


/**
 * seller用户详情
 *
 */
export const marketplaceUser_internalSellerUserDetailGet = mergeAsyncFn()((params: {

  /**
   * sellerId
   */
  sellerId: number

}) => {
  return request.get<R<any>>('/marketplace-user/_internal/seller-user/detail', { params }) as unknown as Promise<R<any>>
})


/**
 * seller用户查询接口
 *
 */
export const marketplaceUser_internalSellerUserQueryPost = mergeAsyncFn()((data?: SellerQueryVo) => {
  return request.post<R<PageResult<SellerUserListVo>>>('/marketplace-user/_internal/seller-user/query', data) as unknown as Promise<R<PageResult<SellerUserListVo>>>
})


/**
 * admin-seller角色查询
 *
 */
export const marketplaceUser_internalSellerUserRoleGet = mergeAsyncFn()((params?: {

  /**
   * sellerId
   */
  sellerId?: number

}) => {
  return request.get<R<any>>('/marketplace-user/_internal/seller-user/role', { params }) as unknown as Promise<R<any>>
})


/**
 * admin-seller绑定角色
 *
 */
export const marketplaceUser_internalSellerUserRolePost = mergeAsyncFn()((data?: SellerUserBindRoleVo) => {
  return request.post<R<any>>('/marketplace-user/_internal/seller-user/role', data) as unknown as Promise<R<any>>
})


/**
 * admin-seller修改状态
 *
 */
export const marketplaceUser_internalSellerUserStatusPost = mergeAsyncFn()((data?: SellerStatusVo) => {
  return request.post<R<any>>('/marketplace-user/_internal/seller-user/status', data) as unknown as Promise<R<any>>
})


/**
 * admin-seller用户信息修改
 *
 */
export const marketplaceUser_internalSellerUserUpdatePost = mergeAsyncFn()((data?: SellerUserUpdateVo) => {
  return request.post<R<any>>('/marketplace-user/_internal/seller-user/update', data) as unknown as Promise<R<any>>
})


/**
 * 租户列表查询
 *
 */
export const marketplaceUser_internalTenantListPost = mergeAsyncFn()((data?: TenantQueryVo) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/list', data) as unknown as Promise<R<any>>
})


/**
 * 修改租户stage
 *
 */
export const marketplaceUser_internalTenantSyncStagePost = mergeAsyncFn()((data?: TenantStageChangeReq, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/sync/stage', data) as unknown as Promise<R<any>>
})


/**
 * 修改租户状态
 *
 */
export const marketplaceUser_internalTenantSyncStatusPost = mergeAsyncFn()((data?: TenantStatusChangeReq, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/sync/status', data) as unknown as Promise<R<any>>
})


/**
 * 修改租户名称
 *
 */
export const marketplaceUser_internalTenantSyncTenantNameChangePost = mergeAsyncFn()((data?: TenantNameChangeReq, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/sync/tenantNameChange', data) as unknown as Promise<R<any>>
})


/**
 * 创建租户
 *
 */
export const marketplaceUser_internalTenantSyncTenantPost = mergeAsyncFn()((data?: TenantInfoDTO, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/sync/tenant', data) as unknown as Promise<R<any>>
})


/**
 * 创建用户
 *
 */
export const marketplaceUser_internalTenantSyncUserPost = mergeAsyncFn()((data?: SellerUserInfoDTO, headers?: {

  /**
   * appKey
   */
  appKey: string

}) => {
  return request.post<R<any>>('/marketplace-user/_internal/tenant/sync/user', data) as unknown as Promise<R<any>>
})


/**
 * userBind
 *
 */
export const marketplaceUser_internalUserRoleBindPost = mergeAsyncFn()((data?: UserRoleBindVO) => {
  return request.post<R<string>>('/marketplace-user/_internal/userRole/bind', data) as unknown as Promise<R<string>>
})


/**
 * get
 *
 */
export const marketplaceUser_internalUserRoleByIdGet = mergeAsyncFn()((id: string | number) => {
  return request.get<R<UserRoleDuiXiang>>(`/marketplace-user/_internal/userRole/${id}`,) as unknown as Promise<R<UserRoleDuiXiang>>
})


/**
 * delete
 *
 */
export const marketplaceUser_internalUserRoleDeletePost = mergeAsyncFn()((params: {

  /**
   * id
   */
  id: number

}) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/userRole/delete',) as unknown as Promise<R<boolean>>
})


/**
 * page
 *
 */
export const marketplaceUser_internalUserRolePageGet = mergeAsyncFn()((params: {

  /**
   * 页码
   */
  page: number


  /**
   * 分页大小
   */
  pageSize: number

}) => {
  return request.get<R<PageResult<UserRoleDuiXiang>>>('/marketplace-user/_internal/userRole/page', { params }) as unknown as Promise<R<PageResult<UserRoleDuiXiang>>>
})


/**
 * create
 *
 */
export const marketplaceUser_internalUserRolePost = mergeAsyncFn()((data?: UserRoleDuiXiang0) => {
  return request.post<R<number>>('/marketplace-user/_internal/userRole', data) as unknown as Promise<R<number>>
})


/**
 * getUserRoles
 *
 */
export const marketplaceUser_internalUserRoleRolesPost = mergeAsyncFn()((data?: UserRoleQueryVO) => {
  return request.post<R<Record<string, RoleVO[]>>>('/marketplace-user/_internal/userRole/roles', data) as unknown as Promise<R<Record<string, RoleVO[]>>>
})


/**
 * update
 *
 */
export const marketplaceUser_internalUserRoleUpdatePost = mergeAsyncFn()((data?: UserRoleDuiXiang1) => {
  return request.post<R<boolean>>('/marketplace-user/_internal/userRole/update', data) as unknown as Promise<R<boolean>>
})
