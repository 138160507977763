import { defineComponent, HTMLAttributes, onMounted, SlotsType } from 'vue'
import { DialogProps, DialogEvents } from './model'
import { Modal } from 'ant-design-vue'
import { useDialogContext } from './context'
import { useDialogInner } from './useDialog'
import { JSX } from 'vue/jsx-runtime'
/**
 * 对话框组件
 */
export const Dialog = defineComponent<
  DialogProps & HTMLAttributes,
  DialogEvents,
  'Dialog',
  SlotsType<{
    default?: () => JSX.Element
    cancelText?: () => JSX.Element
    closeIcon?: () => JSX.Element
    footer?: () => JSX.Element
    okText?: () => JSX.Element
    title?: () => JSX.Element
  }>
>(
  (props, context) => {
    const ctx = useDialogContext() || useDialogInner()
    onMounted(() => {
      context.emit('register', ctx)
      ctx.hook.show.on(() => {
        ctx.open.value = true
      })
    })

    async function onClose() {
      ctx.open.value = false
      await ctx.hook.close.execute(false)
    }

    async function onConfirm() {
      await ctx.hook.confirm.execute(true)
      ctx.open.value = false
    }
    return () => {
      return (
        <Modal
          {...context.attrs}
          width={props.width}
          footer={props.footer}
          title={props.title || ctx.title.value}
          open={ctx.open.value}
          cancelText={props.cancelText || ctx.cancelText.value}
          okText={props.okText || ctx.okText.value}
          onUpdate:open={(v) => {
            ctx.open.value = v
          }}
          onCancel={onClose}
          onOk={onConfirm}
        >
          {{
            ...context.slots,
          }}
        </Modal>
      )
    }
  },
  {
    props: ['bodyStyle', 'cancelText', 'footer', 'okText', 'title', 'width', 'wrapClassName'],
  }
)
