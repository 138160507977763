import { defineComponent, ref, SlotsType, watch } from 'vue'
import { SpinProgressIndicatorProps, SpinProgressIndicatorEvents } from './model'
import { Spin } from 'ant-design-vue'
import { JSX } from 'vue/jsx-runtime'
import { createSpinProgressIndicatorContext } from './context'
/**
 * 加载中进度
 */
export const SpinProgressIndicator = defineComponent<
  SpinProgressIndicatorProps,
  SpinProgressIndicatorEvents,
  'SpinProgressIndicator',
  SlotsType<{
    default?: () => any
    indicator?: () => JSX.Element
    tip?: () => JSX.Element
  }>
>(
  (props, ctx) => {
    const spinning = ref(!!props.spinning)
    watch(
      () => props.spinning,
      (val) => {
        spinning.value = !!val
      }
    )

    createSpinProgressIndicatorContext({
      setSpinning(value) {
        spinning.value = !!value
      },
    })

    return () => {
      return (
        <Spin
          size={props.size}
          delay={props.delay}
          indicator={props.indicator}
          spinning={spinning.value}
          wrapperClassName={props.wrapperClassName}
        >
          {{
            ...ctx.slots,
          }}
        </Spin>
      )
    }
  },
  {
    props: ['delay', 'indicator', 'size', 'spinning', 'wrapperClassName'],
  }
)
