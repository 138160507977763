export function isPromise(value: any) {
  return Promise.resolve(value) === value && typeof value.then === 'function'
}

/**
 * promise 封装，api以及功能参考dart sdk Future
 */
export class Future {
  static delayed<T>(duration: number, computation?: () => T | Promise<T>) {
    return new Promise<T>((resolve, reject) => {
      setTimeout(() => {
        if (computation) {
          try {
            const res = computation()
            if (isPromise(res))
              (res as Promise<T>).then(resolve).catch(reject)
            else
              resolve(res as T)
          } catch (e) {
            reject(e)
          }
        } else {
          resolve(undefined as T)
        }
      }, duration)
    })
  }

  static sync<T>(computation: () => T | Promise<T>) {
    return new Promise<T>((resolve, reject) => {
      try {
        const res = computation()
        if (isPromise(res))
          (res as Promise<T>).then(resolve).catch(reject)
        else
          resolve(res as T)
      } catch (e) {
        reject(e)
      }
    })
  }

  static doWhile(action: () => boolean | Promise<boolean>) {
    return new Promise<void>((resolve, reject) => {
      const loop = async () => {
        try {
          let res = action()
          if (isPromise(res))
            res = await res
          if (res)
            loop()
          else
            resolve()
        } catch (e) {
          reject(e)
        }
      }
      loop()
    })
  }

  static forEach<T>(elements: T[], action: (element: T) => Promise<void> | undefined) {
    const iterator = elements.entries()
    return this.doWhile(() => {
      const current = iterator.next()
      if (current.done) return false
      const result = action(current.value[1])
      if (isPromise(result)) return (result as Promise<void>).then(this._kTrue)
      return true
    })
  }

  private static _kTrue() {
    return true
  }
}
