import { marketplaceUserUserContactInfoV3Post } from '@/api/marketplaceUserApi'
import { ContactUsVO } from '@/models'
import { useRouter } from 'vue-router'
import { MiddlewareManager } from '../../middleware'
import { useHttpResUnpack } from '../http'
import {
  ContactParamsMiddleware,
  JumpToContactUsMiddleware,
  JumpToDemoMiddleware,
  JumpToTargetPageMiddleware,
  JumpToUnneedAuthMiddleware,
  JumpToUrlDefaultMiddleware,
  LeaveContactContext,
  LoginCheckMiddleware,
  ParseUrlMiddleware,
  SmesipMiddleware,
  SSORedirectMiddleware,
  SyncCompleteFlagMiddleware,
  WJxMiddleware,
  UserContactInfoMiddleware,
} from './middlewares'
import { LeaveContactParams, QueryUserContactInfoParams } from './model'

/**
 * 留资跳转
 * @returns
 */
export function useLeaveContact() {
  // 留资跳转执行流
  const leaveContactStream = new MiddlewareManager<LeaveContactContext>([
    new ParseUrlMiddleware(),
    new ContactParamsMiddleware(),
    new JumpToUnneedAuthMiddleware(),
    new LoginCheckMiddleware(),
    new UserContactInfoMiddleware(),
    new SyncCompleteFlagMiddleware(),
    new JumpToContactUsMiddleware(),
    new WJxMiddleware(),
    new JumpToTargetPageMiddleware(),
    new JumpToDemoMiddleware(),
    new SmesipMiddleware(),
    new SSORedirectMiddleware(),
    new JumpToUrlDefaultMiddleware(),
  ])
  const router = useRouter()

  /**
   * 去留资
   */
  async function leaveContact(params: LeaveContactParams) {
    await leaveContactStream.execute(new LeaveContactContext(params, router))
  }

  /**
   * 查询留资信息
   */
  function queryUserContactInfo(params: QueryUserContactInfoParams) {
    return useHttpResUnpack<ContactUsVO>(marketplaceUserUserContactInfoV3Post(params))
  }

  return {
    leaveContact,
    queryUserContactInfo
  }
}
