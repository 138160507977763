import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'

/**
 * 处理跳转到留资页面后，留资页面所需要的相关参数，这些参数通过localStorage共享传递
 */
export class ContactParamsMiddleware extends Middleware<LeaveContactContext> {
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>) {
    if (localStorage.getItem('contact')) {
      localStorage.removeItem('contact')
    }
    if (ctx.params.description && ctx.userContactInfo && ctx.params.solutionId) {
      const contactIds = {
        solutionId: ctx.params.solutionId || '',
        uuid: ctx.userContactInfo?.uuid || '',
        description: ctx.params.description || {},
      }
      localStorage.setItem('contact', JSON.stringify(contactIds))
    }
    return next()
  }
}
