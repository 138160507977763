import { Observable } from 'rxjs'

/**
 * 将 observable 转换为 promise
 * @param value
 * @returns
 */
export function observableToPromise<T>(value: Observable<T>) {
  return new Promise<T>((resolve, reject) => {
    value.subscribe({
      next: (v) => resolve(v),
      error: (e) => reject(e),
    })
  })
}