/**
 * 响应拆包
 * @param req
 * @returns
 */
export async function useHttpResUnpack<T = any>(req: Promise<{ code?: number; data?: T; msg?: string }>) {
  const res = await req
  if (`${res.code}` !== '200') {
    throw new Error(`${res.msg || res.data || '请求异常'}`)
  }
  return res.data
}
