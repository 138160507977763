
import { inject, provide } from 'vue'
import { DialogContext } from './model'

/**
 * 获取上下文
 */
export function useDialogContext() {
  return inject<DialogContext>('Dialog')
}

/**
 * 创建上下文
 * @param ctx
 */
export function createDialogContext<T = void, S = boolean>(ctx: DialogContext<T, S>) {
  provide('Dialog', ctx)
  return ctx
}
