import { createDialogContext } from './context'
import { ref } from 'vue'
import { HookManagerImpl } from '@/modules/manager/hookManager'
import { DialogContext } from './model'
import { DisposeManager } from '@/modules/common'

export function useDialog<T = void, S = boolean>() {
  let ctx: DialogContext<T, S> | undefined
  function register(context: DialogContext<T, any>) {
    ctx = context
  }
  function show(params: T) {
    ctx?.hook.show.execute(params)
    return new Promise<S>((resolve, _reject) => {
      const dispose = new DisposeManager()
      dispose.add(ctx?.hook.confirm.on((params: S) => {
        resolve(params)
        dispose.execute()
      })).add(ctx?.hook.close.on((params: S) => {
        resolve(params)
        dispose.execute()
      }))
    })
  }
  return {
    register,
    show
  }
}

export function useDialogInner<T = void, S = boolean>(onShow?: (params: T) => void) {
  const ctx = createDialogContext<T, S>({
    open: ref(false),
    title: ref(),
    hook: {
      show: new HookManagerImpl(),
      cancel: new HookManagerImpl(),
      close: new HookManagerImpl(),
      confirm: new HookManagerImpl(),
    },
    okText: ref(),
    footer: ref(),
    cancelText: ref(),
    dispose() {
      ctx.hook.cancel.dispose()
      ctx.hook.close.dispose()
      ctx.hook.confirm.dispose()
      ctx.hook.show.dispose()
    }
  })
  if (onShow) {
    ctx.hook.show.on(onShow)
  }
  return ctx
}