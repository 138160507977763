import { ContactUsVO } from '@/models'
import { LeaveContactParams } from '../model'
import { Router } from 'vue-router'

export class LeaveContactContext {
  router: Router
  /**
   * 外部传入参数
   */
  params: LeaveContactParams
  /**
   * 重定向的url地址
   */
  replaceUrl?: string
  /**
   * 用户留资信息
   */
  userContactInfo?: ContactUsVO
  /**
   * sso的appid方式
   */
  get appId() {
    return this.params.params.app_id
  }

  constructor(params: LeaveContactParams, router: Router, userContactInfo?: ContactUsVO) {
    this.params = params
    this.userContactInfo = userContactInfo
    this.router = router
  }
}