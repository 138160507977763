import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { LeaveContactContext } from './context'
import { ContentObjectTypeEnum } from '../model'

/**
 * 解析参数中的路径和参数,用于后续重定向跳转
 */
export class ParseUrlMiddleware extends Middleware<LeaveContactContext> {
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>) {
    let replaceUrl = ''
    let replaceUrl1 = ''
    let replaceUrl2 = ''
    // TODO: offring留资强制加上contentObjectType=product, 但如果有contentObjectType参数，不覆盖
    ctx.params.params.contentObjectType ??= ContentObjectTypeEnum.product
    for (const key in ctx.params.params) {
      if (key === 'pathName') {
        replaceUrl1 = replaceUrl1 + '/' + ctx.params.params[key]
      } else {
        replaceUrl2 = replaceUrl2 + key + '=' + ctx.params.params[key] + '&'
      }
    }
    replaceUrl = replaceUrl1 + '?' + replaceUrl2
    const lastCode = replaceUrl.slice(replaceUrl.length - 1)
    if (lastCode === '?' || lastCode === '&') {
      replaceUrl = replaceUrl.slice(0, replaceUrl.length - 1)
    }
    ctx.replaceUrl = replaceUrl

    return next()
  }
}
