import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { urlJumpCompatibleMobile } from '@/utils/utils'
import { Observable, of } from 'rxjs'
import { LeaveContactContext } from './context'

/**
 * 无需认证的跳转逻辑
 */
export class JumpToUnneedAuthMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return !ctx.params.needAuth
  }
  handle(ctx: LeaveContactContext, _next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    // history.replaceState({ page: 111 }, 'lastPage', ctx.replaceUrl)
    // 如无需登录留资或已登录留资，直接跳到目标页面
    if (encodeURI(ctx.params.targetUrl).includes('http')) {
      urlJumpCompatibleMobile(encodeURI(ctx.params.targetUrl), '_blank')
    } else {
      window.location.href = encodeURI(ctx.params.targetUrl)
    }
    return of(ctx)
  }
}