import { marketplaceUserUserContactInfoV3Post } from '@/api/marketplaceUserApi'
import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { from, mergeMap, Observable, of } from 'rxjs'
import { LeaveContactContext } from './context'

/**
 * 查询留资信息
 */
export class UserContactInfoMiddleware extends Middleware<LeaveContactContext> {
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    if (ctx.userContactInfo) {
      return of(ctx)
    }
    return from(marketplaceUserUserContactInfoV3Post(ctx.params.key).then((res) => {
      if (`${res.code}` === '200') {
        ctx.userContactInfo = res.data
      }
      return ctx
    })).pipe(mergeMap(next))
  }
}