import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { handleSSORedirect } from '@/utils/utils'
import { Observable, of } from 'rxjs'
import { LeaveContactContext } from './context'

/**
 * 处理sso重定向
 */
export class SSORedirectMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return !!ctx.params.needAuth
  }
  handle(ctx: LeaveContactContext, next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    // 仅存在appid时需要处理sso重定向
    if (ctx.appId) {
      handleSSORedirect(ctx.params.params, ctx.appId)
      return of(ctx)
    }
    return next()
  }
}
