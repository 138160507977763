
import { inject, provide } from 'vue'

export interface SpinProgressIndicatorContext {
  /**
   * 设置状态
   * @param value
   */
  setSpinning(value: boolean): void
}

/**
 * 获取上下文
 */
export function useSpinProgressIndicatorContext() {
  return inject<SpinProgressIndicatorContext>('SpinProgressIndicator')
}

/**
 * 创建上下文
 * @param ctx
 */
export function createSpinProgressIndicatorContext(ctx: SpinProgressIndicatorContext) {
  provide('SpinProgressIndicator', ctx)
  return ctx
}
