import { ref } from 'vue'

export type RequestPagingExecuteProps = {
  /**
   * 当前页
   */
  pageNo?: number
  /**
   * 每页条数
   */
  pageSize?: number
}

export type RequestPagingExecute<T, S extends RequestPagingExecuteProps> = (params: S) => Promise<{
  list: T[]
  total: number
}>

/**
 * 请求分页
 * @param execute 执行函数
 * @returns
 */
export function useRequestPaging<
  T,
  S extends RequestPagingExecuteProps = RequestPagingExecuteProps
>(execute: RequestPagingExecute<T, S>) {
  // 当前页码，初始为 1
  const pageNum = ref(1)
  // 每页条数，初始为 10
  const pageSize = ref(12)
  // 数据总条数，初始为 0
  const total = ref(0)
  // 数据列表，初始为空
  const list = ref<T[]>([])
  // 加载状态，初始为未加载
  const loading = ref(false)
  /**
   * 执行请求函数的唯一标识, 用于判断是否重复请求
   */
  let executeId: string = ''
  const queryParams = ref<RequestPagingExecuteProps>({
    pageNo: pageNum.value,
    pageSize: pageSize.value,
  })

  function genExecuteId(params: S) {
    const keys = Object.keys(params).sort()
    return keys.reduce((pre, cur) => {
      return `${pre}${cur}${params[cur as keyof S]}`
    }, '')
  }

  async function refresh(options?: S) {
    const id = genExecuteId(options || {} as any)
    if (loading.value && executeId === id) return
    executeId = id
    loading.value = true
    pageNum.value = options?.pageNo || pageNum.value
    pageSize.value = options?.pageSize || pageSize.value
    queryParams.value = {
      ...queryParams.value,
      pageNo: pageNum.value,
      pageSize: pageSize.value,
      ...options,
    }
    try {
      const res = await execute({
        ...queryParams.value,
        ...options,
      } as S)
      list.value = res.list
      total.value = res.total
    } catch (error) {
      console.error(error)
    }
    loading.value = false
  }
  return {
    list,
    total,
    pageNum,
    pageSize,
    loading,
    refresh,
  }
}
