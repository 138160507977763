<template>
  <config-provider :theme="{
    hashed: false,
    autoInsertSpaceInButton: false,
    token: { fontFamily: '', colorPrimary: '#00cccc', wireframe: false },
    components: {
      Button: {
        colorPrimary: '#00cccc',
        colorInfo: '#000028',
        controlHeightLG: 42,
        borderRadius: 0,
        borderRadiusLG: 0,
        borderRadiusSM: 0,
        fontSizeLG: 14,
        fontSize: 14,
        fontSizeSM: 12
      },
      Input: {
        controlHeightLG: 48
      }
    }
  }">
    <div id='app' :class='{ "store-lang-en": $store.state.lang === "en" }'>
      <!--      <Header></Header>-->
      <!-- 跨页面的全局加载中效果 -->
      <SpinProgressIndicator>
        <router-view></router-view>
      </SpinProgressIndicator>
      <!--      <page-footer></page-footer>-->
      <dialog-bar></dialog-bar>
    </div>
  </config-provider>
</template>

<script>
import dialogBar from '@/components/dialogBar.vue'
import { ConfigProvider } from 'ant-design-vue'
import gdp from 'gio-webjs-sdk-cdp/gdp-full'
import { SpinProgressIndicator } from '@/modules/uiKit'
import { useHead } from '@unhead/vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
    dialogBar,
    ConfigProvider,
  },
  setup() {
    const store = useStore()
    const currentMetaInfo = computed(() => store.state.seo.currentMetaInfo)
    useHead(() => ({ ...(currentMetaInfo.value || {}) }))
  },
  mounted() {
    /**
     * GrowingIO Analytics WebJS SDK version 3.8
     * Copyright 2015-2022 GrowingIO, Inc. More info available at http://www.growingio.com
     */
    window._gr_ignore_local_rule = true
    gdp(
      'init',
      process.env.NODE_ENV === 'production' ? 'ae9b69db1f046e0b' : 'a3dee2dd6527cc7e',
      process.env.NODE_ENV === 'production' ? '8207d72622b5d4ab' : 'b929dec10d679901',
      {
        host: process.env.VUE_APP_GROWING_HOST,
        version: '1.0',
        debug: process.env.NODE_ENV !== 'production',
      }
    )
    // 上报设备ID
    gdp('setUserAttributes', { deviceID: gdp('getDeviceId') || '' })
  },
}
</script>

<style lang="scss">
@import './assets/style/common.css';
@import './assets/style/fonts.scss';
@import './assets/style/global.scss';
@import './assets/style/icon.scss';

body {
  margin: 0;
  background-color: rgb(0, 0, 40);
  overflow-x: hidden;
}

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
div {
  box-sizing: border-box;
}

input {
  outline: none;
}
</style>
