import { getAutoLoginUimAccessToken } from '@/api/userApi'
import { Middleware, NextMiddleware } from '@/modules/common/middleware'
import { urlJumpCompatibleMobile } from '@/utils/utils'
import { Observable, of } from 'rxjs'
import { LeaveContactContext } from './context'

export class SmesipMiddleware extends Middleware<LeaveContactContext> {
  where(ctx: LeaveContactContext): boolean {
    return ctx.params.targetUrl.includes('smesip.com')
  }
  handle(ctx: LeaveContactContext, _next: NextMiddleware<LeaveContactContext>): Observable<LeaveContactContext> {
    getAutoLoginUimAccessToken({
      redirectPath: 'https://www.smesip.com/user/loginsso',
    }).then((res) => {
      const attrVal = ctx.params.targetUrl.includes('?') ? '&' : '?'
      urlJumpCompatibleMobile(
        encodeURI(
          ctx.params.targetUrl + attrVal + 'accessToken=' + (res?.data?.accessToken || '')
        ),
        '_blank'
      )
    })

    return of(ctx)
  }
}