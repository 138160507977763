import { Dispose } from '../common'
import { AsyncSerialExecute } from '../common/utils/execute'

export type HookTask<T, S> = (arg: T) => S | Promise<S>

export interface HookManager<T = void, S = void> {
  /**
   * 订阅
   * @param fn
   */
  on(fn: HookTask<T, S>): Dispose
  /**
   * 移除订阅
   * @param fn
   */
  off(fn: HookTask<T, S>): void
  /**
   * 销毁
   */
  dispose(): void
  /**
   * 执行
   * @param data
   */
  execute(data: T): Promise<S | undefined>
}

/**
 * TODO：考虑添加单播、多播的概念与支持
 */
export class HookManagerImpl<T = void, S = void> implements HookManager<T, S> {
  private readonly tasks = new AsyncSerialExecute<T, S>()
  on(fn: HookTask<T, S>) {
    this.tasks.add(fn)
    return () => {
      this.tasks.remove(fn)
    }
  }
  off(fn: HookTask<T, S>) {
    this.tasks.remove(fn)
  }
  dispose(): void {
    this.tasks.clear()
  }

  async execute(data: T) {
    return this.tasks.execute(data)
  }
}
