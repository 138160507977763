import { HTMLAttributes, SetupContext, StyleValue } from 'vue'
import '@/assets/icons/style.css'

export type IconKind =
  | 'icon-auth'
  | 'icon-not-auth'
  | 'icon-edit'
  | 'icon-drag-card'
  | 'icon-Acknowledge-events'
  | 'icon-Add-workorder'
  | 'icon-Apps'
  | 'icon-Arrow-double-left'
  | 'icon-Arrow-double-right'
  | 'icon-Arrow-down'
  | 'icon-Arrowhead-down'
  | 'icon-Arrowhead-left'
  | 'icon-Arrowhead-right'
  | 'icon-Arrowhead-up'
  | 'icon-Arrow-left'
  | 'icon-Arrow-right'
  | 'icon-Arrow-up'
  | 'icon-Attach'
  | 'icon-Audiodescription-1'
  | 'icon-Audiodescription-2'
  | 'icon-Back'
  | 'icon-Bar-chart'
  | 'icon-Bilibili'
  | 'icon-Bookmark'
  | 'icon-Browse-all'
  | 'icon-Buildings'
  | 'icon-Calendar'
  | 'icon-Capacity'
  | 'icon-Chat-1'
  | 'icon-Chat-2'
  | 'icon-Check'
  | 'icon-Check-checkbox'
  | 'icon-Check-small'
  | 'icon-Close'
  | 'icon-Close-big'
  | 'icon-Close-small'
  | 'icon-Configuration'
  | 'icon-Configure'
  | 'icon-Connected-radio-waves'
  | 'icon-Connected-WLAN-high'
  | 'icon-Connected-WLAN-low'
  | 'icon-Connected-WLAN-medium'
  | 'icon-Copy'
  | 'icon-Cut'
  | 'icon-Disconnected-radio-waves'
  | 'icon-Disconnected-WLAN'
  | 'icon-Document'
  | 'icon-Documentation'
  | 'icon-Document-DOC'
  | 'icon-Document-MP'
  | 'icon-Document-PDF'
  | 'icon-Document-PPT'
  | 'icon-Document-success'
  | 'icon-Document-TXT'
  | 'icon-Document-XLS'
  | 'icon-Document-ZIP'
  | 'icon-Download'
  | 'icon-Drag-drop'
  | 'icon-Drop-down'
  | 'icon-Edit'
  | 'icon-Email'
  | 'icon-Error'
  | 'icon-Explore'
  | 'icon-Facebook'
  | 'icon-Favorite'
  | 'icon-Favorite-marked'
  | 'icon-Filter'
  | 'icon-Folder'
  | 'icon-Folder-new'
  | 'icon-Folder-open'
  | 'icon-Full-screen'
  | 'icon-Full-screen-exit'
  | 'icon-Group-people'
  | 'icon-Help'
  | 'icon-Hide'
  | 'icon-History'
  | 'icon-Home'
  | 'icon-Home-small'
  | 'icon-Hourglass'
  | 'icon-Image'
  | 'icon-Information'
  | 'icon-Instagram'
  | 'icon-Interaction-click'
  | 'icon-Interaction-touch'
  | 'icon-Language'
  | 'icon-Like'
  | 'icon-Like-marked'
  | 'icon-Line-chart'
  | 'icon-Link'
  | 'icon-Linkedin'
  | 'icon-Link-extern'
  | 'icon-Location'
  | 'icon-Locked'
  | 'icon-Login'
  | 'icon-Logout'
  | 'icon-Maximize'
  | 'icon-Menu'
  | 'icon-Microphone'
  | 'icon-Minimize'
  | 'icon-Minus'
  | 'icon-Minus-checkbox'
  | 'icon-Monitor'
  | 'icon-Navigation-left'
  | 'icon-Navigation-right'
  | 'icon-Notification'
  | 'icon-Optimize'
  | 'icon-Options'
  | 'icon-Options-horizontal'
  | 'icon-Paste'
  | 'icon-Pause'
  | 'icon-Phone'
  | 'icon-Pin'
  | 'icon-Pinned'
  | 'icon-Plant'
  | 'icon-Play'
  | 'icon-Plus'
  | 'icon-Plus-small'
  | 'icon-Point-Study'
  | 'icon-Print'
  | 'icon-QR-code'
  | 'icon-Reading-time'
  | 'icon-Redo'
  | 'icon-Refresh'
  | 'icon-Reset'
  | 'icon-Resize'
  | 'icon-Save'
  | 'icon-Search'
  | 'icon-Search-small'
  | 'icon-Settings'
  | 'icon-Share'
  | 'icon-Shopping-cart'
  | 'icon-Show'
  | 'icon-Sign-language'
  | 'icon-Skip'
  | 'icon-Skip-back'
  | 'icon-Social-feed'
  | 'icon-Sort'
  | 'icon-Sort-ascending'
  | 'icon-Sort-descending'
  | 'icon-Sort-down'
  | 'icon-Sort-up'
  | 'icon-Sort-up-down'
  | 'icon-Source-code'
  | 'icon-Stop'
  | 'icon-Success'
  | 'icon-Support'
  | 'icon-Telegram'
  | 'icon-Terms-and-conditions'
  | 'icon-TikTok'
  | 'icon-Trash'
  | 'icon-Undo'
  | 'icon-Unlocked'
  | 'icon-Upload'
  | 'icon-User'
  | 'icon-Utilization'
  | 'icon-VK'
  | 'icon-Volume-loud'
  | 'icon-Volume-mute'
  | 'icon-Volume-quiet'
  | 'icon-Warning'
  | 'icon-WeChat'
  | 'icon-Weibo'
  | 'icon-X_formerly-twitter'
  | 'icon-Yammer'
  | 'icon-YouTube'
  | 'icon-Zoom-in'
  | 'icon-Zoom-out'
  | 'icon-design-layout-grid'
  | 'icon-design-layout-row'
  | 'icon-design-layout-card'
  | 'icon-design-layout-column'
  | 'icon-design-layout-leftImg-rightText'
  | 'icon-design-layout-leftText-rightImg'
  | 'icon-design-layout-topImg-bottomText'

export function IconFont(
  props: {
    name: IconKind
    size?: string
  } & HTMLAttributes,
  _ctx: SetupContext
) {
  const styleProps: StyleValue = {}
  if (props.size) {
    styleProps.fontSize = props.size
  }
  return <i class={props.name} style={styleProps}></i>
}

/**
 * 图片图标
 * @param props
 * @param ctx
 * @returns
 */
export function IconImg(
  props: {
    path: string
    width?: string
    height?: string
    size?: string
    style: any
  } & HTMLAttributes,
  _ctx: SetupContext
) {
  const styleProps: StyleValue = {
    width: props.width || props.size,
    height: props.height || props.size,
    display: 'inline-block',
    ...props.style,
  }
  return <img src={props.path} style={styleProps} />
}
