import { Tooltip } from 'ant-design-vue'
import { defineComponent, ref, SlotsType, CSSProperties, computed } from 'vue'
import { JSX } from 'vue/jsx-runtime'
import { TextEllipsisTooltipEvents, TextEllipsisTooltipProps } from './model'
import styles from './index.module.scss'

/**
 * 文本省略提示组件
 */
export const TextEllipsisTooltip = defineComponent<
  TextEllipsisTooltipProps,
  TextEllipsisTooltipEvents,
  'TextEllipsisTooltip',
  SlotsType<{ default?: () => JSX.Element }>
>(
  (props, ctx) => {
    const open = ref(false)
    const textRef = ref<HTMLSpanElement>()

    function setOpen(v: boolean) {
      open.value = v
    }

    const provideStyle = computed(() => {
      const style: CSSProperties = {
        ...(props.style || {}),
      }
      if (props.line) {
        style.lineClamp = props.line
        style.WebkitLineClamp = props.line
      }
      return style
    })

    function onMouseenter() {
      if (textRef.value) {
        if (textRef.value.scrollHeight > textRef.value.clientHeight) {
          setOpen(true)
          return
        }
        setOpen(false)
      }
    }
    function onMouseleave() {
      setOpen(false)
    }
    function renderTooltipContent() {
      return (
        <div class='max-h-[200px] overflow-y-auto overflow-x-hidden'>{props.title}</div>
      )
    }

    return () => {
      if (!ctx.slots.default || !props.title) {
        return ctx.slots.default?.()
      }
      return (
        <Tooltip title={renderTooltipContent()} open={open.value} onUpdate:open={setOpen} trigger={open.value ? 'hover' : []}>
          <span
            onMouseenter={onMouseenter}
            ref={textRef}
            class={[styles.text_multiline_line, props.class]}
            style={provideStyle.value}
          >
            {ctx.slots.default?.()}
          </span>
        </Tooltip>
      )
    }
  },
  {
    props: ['title', 'line'],
  }
)
