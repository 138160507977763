import { Stack } from '@/utils/stack'
import { NativeType } from '@/utils/types'
import { computed, ComputedRef, ref, watch } from 'vue'
type InferDefault<P, T> = ((props: P) => T & {}) | (T extends NativeType ? T : never)
type InferDefaults<T> = {
  [K in keyof T]?: InferDefault<T, T[K]>
}

/**
 * 状态同步
 * @param read
 * @param write
 * @returns
 */
export function useSyncState<T = never>(read: () => T, write: (v: T) => void, equal?: (a: T, b: T) => boolean) {
  const source = ref<T>(read())
  const history = new Stack<T>([read()])
  equal ??= (a, b) => a === b
  const value = computed<T>({
    get() {
      return source.value
    },
    set(v) {
      if (!equal?.(history.peek()!, v)) {
        history.push(v)
        source.value = v
        write(v as T)
      }
    }
  })

  watch(read, (v) => {
    if (!equal?.(history.peek()!, v)) {
      history.push(v)
      source.value = v as never
    }
  })
  return value
}

/**
 * 设置默认值
 * @param props
 * @param defaults
 * @returns
 */
export function useDefaults<T, Defaults extends InferDefaults<T>>(
  props: T,
  defaults: Defaults
): ComputedRef<Omit<T, keyof Defaults> & {
  [K in keyof Defaults]-?: K extends keyof T
  ? Defaults[K] extends undefined
  ? T[K]
  : Defaults[K]
  : Defaults[K]
}> {
  return computed(() => Object.assign(defaults, props) as any)
}
