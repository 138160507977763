import { BaseResponse } from '@/models'
import { urlJoin } from '@/utils/url'
import axios from 'axios'
import { baseFileGeneratePrivatePostPolicyGet, baseFileGeneratePublicPostPolicyGet } from './productApi'

export interface PolicyInfo {
  accessid: string
  dir: string
  /**
   * 策略过期时间
   */
  expire: string
  /**
   * 上传文件的主机地址
   */
  host: string
  /**
   * 上传策略
   */
  policy: string
  /**
   * 签名信息
   */
  signature: string
}

/**
 * 文件上传
 */
export class UploadApi {
  async getPrivatePolicy() {
    const res = await baseFileGeneratePrivatePostPolicyGet() as BaseResponse<PolicyInfo>
    if (res.code === 200) {
      return res.data
    }
    throw new Error(res.msg)
  }

  async genPublicPolicy() {
    const res = await baseFileGeneratePublicPostPolicyGet() as BaseResponse<PolicyInfo>
    if (res.code === 200) {
      return res.data
    }
    throw new Error(res.msg)
  }

  uploadFile(file: File, policyInfo: PolicyInfo) {
    const { accessid, host, policy, signature } = policyInfo
    const formData = new FormData()
    const filePath = urlJoin([policyInfo.dir, new Date().getTime().toString(), file.name])
    formData.append('key', filePath)
    formData.append('policy', policy)
    formData.append('OSSAccessKeyId', accessid)
    // 向 FormData 中添加签名信息
    formData.append('signature', signature)
    // 向 FormData 中添加成功状态码
    formData.append('success_action_status', '200')
    // 向 FormData 中添加要上传的文件
    formData.append('file', file)
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return axios.post(host, formData, config).then((res) => {
      if (res.status === 200) {
        return filePath
      }
      throw new Error('上传失败')
    })
  }
}